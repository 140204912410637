<template>
	<div class="main_news">
		<div class="heading">Спортивные новости</div>
		
		<div class="main_news-item" v-for="newsItem in items" :key="newsItem.id">
			<div class="main_news-flex">
				<div class="main_news_logo" v-if="newsItem.channel === 'sports'">
					<img src="/img/sport-logos/sports.webp" alt="">
					<span>Sports.ru</span>
				</div>
				<div class="main_news_logo" v-else-if="newsItem.channel === 'express'">
					<img src="/img/sport-logos/sport-express.webp" alt="">
					<span>Спорт-Экспресс</span>
				</div>
				<div class="main_news_logo" v-else-if="newsItem.channel === 'sport'">
					<img src="/img/sport-logos/sport.webp" alt="">
					<span>sport.ru</span>
				</div>
				<div class="main_news_logo" v-else-if="newsItem.channel === 'rbc'">
					<img src="/img/sport-logos/rbc.webp" alt="">
					<span>РБК Спорт</span>
				</div>
				
				<div class="main_news-date">{{ getTime(newsItem.pubDate) }}</div>
			</div>
			
			<a :href="newsItem.link" target="_blank" class="main_news-image" v-if="newsItem['media:content']">
				<img :src="newsItem['media:content'].url" alt="">
			</a>
			
			<a :href="newsItem.link" target="_blank">
				{{ newsItem.title }}
			</a>
		</div>
	</div>
</template>

<script>
	import moment from "moment";

	export default {
		props: ['news'],
		
		computed: {
			items() {
				let news = [];
				
				for (let item in this.news) {
					let filteredNews = this.news[item].rss.channel.item.filter((itemChannel) => {
						itemChannel.channel = item;
						
						return !itemChannel['media:content']
					});
					
					let data = filteredNews.slice(0, 3);
					
					news = news.concat(data);
				}
				
				return news.sort((a, b) => {
					let dateA = moment(a.pubDate);
					let dateB = moment(b.pubDate);
					
					return dateA > dateB ? -1 : 1;
				});
			}
		},
		
		methods: {
			getTime(date) {
				return moment(date).locale('ru').format('D MMMM Y, HH:mm');
			},
		}
	}
</script>

<style scoped>

</style>