<template>
	<div class="match">
		<div class="wrapper" v-if="match || matchEvent">
			<div class="match_row">
				<div class="match_right">
					<div class="tab">
						<div v-if="matchEvent" class="live-command">
							<div class="live-command_info">
								<div>{{ getDateTime(matchEvent.date, matchEvent.time) }}</div>
								<div class="__bold">
									<div class="live-command_res">
										<div class="__red" v-if="matchEvent.timer">{{ matchEvent.timer }}'</div>
										<div class="__red" v-else-if="matchEvent.status === 'HT' || matchEvent.status === 'Half-time'">Перерыв</div>
										<div v-else-if="matchEvent.status === matchEvent.time && dayDiff == 0">
											<div class="live-command_timer">
												<vue-countdown-timer
												:start-time="unixTime"
												:end-time="unixDateTime"
												:interval="1000"
												label-position="begin"
												:hour-txt="':'"
												:end-text="'LIVE'"
												:minutes-txt="':'"
												:seconds-txt="''">
													<template slot="countdown" slot-scope="scope">
														<span>{{scope.props.hours}}</span><i>{{scope.props.hourTxt}}</i>
														<span>{{scope.props.minutes}}</span><i>{{scope.props.minutesTxt}}</i>
														<span>{{scope.props.seconds}}</span><i>{{scope.props.secondsTxt}}</i>
													</template>
												</vue-countdown-timer>
											</div>
											
											<div>Не начался</div>
										</div>
										<div v-else>{{ getStatus(matchEvent.status) }}</div>
									</div>
								</div>
							</div>
							
							<div class="live-command_row">
								<router-link :to="{name: 'team', params: {id: matchEvent.home_id, sport: 'soccer'}}" class="live-command_image">
									<div class="live-command_img">
										<img :src="$hostname + '/goalserve/soccer/team_image/' + matchEvent.home_id" alt="">
									</div>
									<span>{{ matchEvent.home_name }}</span>
								</router-link>
								
								<div class="live-command_content">
									<div class="live-command_score" v-if="matchEvent.home_score !== '?'">
										{{ matchEvent.home_score }} : {{ matchEvent.away_score }}
									</div>
								</div>
								
								<router-link :to="{name: 'team', params: {id: matchEvent.away_id, sport: 'soccer'}}" class="live-command_image">
									<div class="live-command_img">
										<img :src="$hostname + '/goalserve/soccer/team_image/' + matchEvent.away_id" alt="">
									</div>
									<span>{{ matchEvent.away_name }}</span>
								</router-link>
							</div>
							
							<div class="live-command_goals">
								<div class="live-command_goals-row" v-for="(goal, key) in goals" :key="key">
									<div class="live-command_goals-col" :class="{__hidden: goal.team === 'visitorteam'}">{{ goal.player }}</div>
									<div class="live-command_goals-col">{{ goal.minute }}'</div>
									<div class="live-command_goals-col" :class="{__hidden: goal.team === 'localteam'}">{{ goal.player }}</div>
								</div>
							</div>
						</div>
						<div v-else class="live-command">
							<div class="live-command_info">
								<div>{{ getDateTime(match.date, match.time) }}</div>
								
								<div class="__bold">
									<div class="live-command_res">
										<div v-if="match.status === 'Full-time'">Завершен</div>
										<div v-else-if="match.status === 'Not Started'">Матч не начался</div>
										<div v-else-if="match.status === 'Cancl.'">Отменен</div>
										<div v-else-if="match.status === 'Postp.'">Отложен</div>
									</div>
								</div>
							</div>
							
							<div class="live-command_row">
								<router-link :to="{name: 'team', params: {id: home.id, sport: 'soccer'}}" class="live-command_image">
									<div class="live-command_img">
										<img :src="$hostname + '/goalserve/soccer/team_image/' + home.id" alt="">
									</div>
									<span>{{ home.name }}</span>
								</router-link>
								
								<div class="live-command_content">
									<div class="live-command_score">
										{{ home.goals }} : {{ away.goals }}
									</div>
								</div>
								
								<router-link :to="{name: 'team', params: {id: away.id, sport: 'soccer'}}" class="live-command_image">
									<div class="live-command_img">
										<img :src="$hostname + '/goalserve/soccer/team_image/' + away.id" alt="">
									</div>
									<span>{{ away.name }}</span>
								</router-link>
							</div>
							
							<div class="live-command_goals">
								<div class="live-command_goals-row" v-for="(goal, key) in goals" :key="key" v-show="parseInt(goal.minute)">
									<div class="live-command_goals-col" :class="{__hidden: goal.team === 'localteam'}">{{ goal.name }}</div>
									<div class="live-command_goals-col">{{ goal.minute }}'</div>
									<div class="live-command_goals-col" :class="{__hidden: goal.team === 'visitorteam'}">{{ goal.name }}</div>
								</div>
							</div>
						</div>
						
						<div class="match-block" v-if="windowWidth > 860">
							<div class="match-block_row">
								<div class="match-block_left">
									<div class="match-block_item">
										<div class="heading">Трансляция матча</div>
										<timeline v-if="matchEvent || match" :match="match" :matchEvent="matchEvent" :videos="videos"></timeline>
										<div v-if="matchEvent">
											<div class="info __mb-20" v-if="matchEvent && !matchEvent.game_events.length">
												<div v-if="matchEvent.status === 'HT'">Перерыв</div>
												<div v-else-if="matchEvent.timer">Матч начался</div>
												<div v-else>Трансляция еще не началась</div>
											</div>
										</div>
										<div v-else-if="!matchEvent && match">
											<div class="info __mb-20" v-if="!match.summary.length">Данных нет</div>
										</div>
									</div>
									<div class="match-block_item" v-if="match && match.teams">
										<div class="match-block_item">
											<div class="heading">Составы</div>
											<lineups v-if="match && match.teams" :match="match"></lineups>
											<div class="info" v-else>Нет данных</div>
										</div>
									</div>
									<div class="info-text" v-else-if="matchLoading"></div>
									
									<div class="match-block_item" v-if="match">
										<div class="heading">Статистика</div>
										<statistic v-if="match && match.stats" :data="match.stats"></statistic>
										<div class="info" v-else>Нет данных</div>
									</div>
									<div class="info-text" v-else-if="matchLoading"></div>
									
									<div class="match-block_item" v-if="h2h">
										<match-form v-if="h2h && h2h.last5_home" :results="h2h" :match="match" :matchEvent="matchEvent"></match-form>
									</div>
									<div class="info-text" v-else-if="h2hLoading"></div>
									
									<div class="match-block_item" v-if="h2h">
										<last-results v-if="h2h.last5_home" :match="match ? match : matchEvent" :results="h2h"></last-results>
									</div>
									<div class="info-text" v-else-if="h2hLoading"></div>
								</div>
								<div class="match-block_right">
									<div class="match-block_item" v-if="match">
										<match-info :match="match" :competition="competition"></match-info>
									</div>
									<div class="info-text" v-else-if="matchLoading"></div>
									
									<div class="match-block_item" v-if="h2h && Array.isArray(h2h.top50.match)">
										<div class="heading">Личные встречи</div>
										<total-results v-if="h2h.top50" :h2h="h2h" :match="match" :match-event="matchEvent"></total-results>
									</div>
									<div class="info-text" v-else-if="h2hLoading"></div>
									
									<div class="match-block_item" v-if="match">
										<standings :league="leagueId" v-if="leaguesArr.includes(leagueId)" :standings="standings" @update="initStandings" :leagues="leagues"></standings>
									</div>
									<div class="info-text" v-else-if="matchLoading"></div>
									
									<div class="match-block_item" v-if="injuries && matchEvent">
										<injuries :matchEvent="matchEvent" :data="injuries"></injuries>
									</div>
									<div class="info-text" v-else-if="matchEventLoading"></div>
								</div>
							</div>
						</div>
						
						<tab v-else :headings="{timeline: 'Обзор', stat: 'Статистика', lineups: 'Составы', h2h: 'Последние матчи'}" :active-key="getActiveTab()" :class-names="['__center']">
							<template v-slot:timeline>
								<timeline v-if="matchEvent || match" :match="match" :matchEvent="matchEvent" :videos="videos"></timeline>
								<div v-if="matchEvent">
									<div class="info __mb-20" v-if="matchEvent && !matchEvent.game_events.length">
										<div v-if="matchEvent.status === 'HT'">Перерыв</div>
										<div v-else-if="matchEvent.timer">Матч начался</div>
										<div v-else>Трансляция еще не началась</div>
									</div>
								</div>
								<div v-else-if="!matchEvent && match">
									<div class="info __mb-20" v-if="!match.summary.length">Данных нет</div>
								</div>
								
								<div v-if="match && match.teams">
									<match-info :match="match" :competition="competition"></match-info>
								</div>
								
								<match-form v-if="h2h && h2h.last5_home" :results="h2h"></match-form>
							</template>
							<template v-slot:stat>
								<statistic v-if="match && match.stats" :data="match.stats"></statistic>
								<div class="info" v-else>Нет данных</div>
							</template>
							<template v-slot:lineups>
								<div v-if="(match && match.teams) || (injuries && matchEvent)">
									<lineups v-if="match && match.teams" :match="match"></lineups>
									
									<injuries v-if="injuries && matchEvent" :matchEvent="matchEvent" :data="injuries"></injuries>
								</div>
								<div v-else>
									<div class="info __mb20">Нет данных</div>
								</div>
							</template>
							<template v-slot:text>
								<match-text v-if="matchEvent || match" :match="match" :matchEvent="matchEvent"></match-text>
								<div class="info" v-else>Нет данных</div>
							</template>
							<template v-slot:h2h>
								<div v-if="h2h">
									<total-results v-if="h2h.top50" :h2h="h2h" :match="match ? match : matchEvent"></total-results>
									
									<br>
									
									<last-results v-if="h2h.last5_home" :match="match ? match : matchEvent" :results="h2h"></last-results>
								</div>
								<div v-else-if="loading" class="info-text"></div>
								<div class="info" v-else>Нет данных</div>
							</template>
						</tab>
					</div>
				</div>
			</div>
		</div>
		
		<div v-else-if="loading" class="info-text"></div>
		
		<div class="wrapper" v-if="!match && !loading && !matchEvent">
			<div class="info __pt0">Информация обновляется</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment'
	import Tab from "../../../components/Tab/Tab";
	import Statistic from "../../../components/Sports/Soccer/Components/Match/Statistic";
	import Lineups from "../../../components/Sports/Soccer/Components/Match/Lineups";
	import Timeline from "../../../components/Sports/Soccer/Components/Match/Timeline";
	import LastResults from "../../../components/Sports/Soccer/Components/Match/LastResults";
	import TotalResults from "../../../components/Sports/Soccer/Components/Match/TotalResults";
	import MatchText from "../../../components/Sports/Soccer/Components/Match/Text";
	import MatchInfo from "../../../components/Sports/Soccer/Components/Match/MatchInfo";
	import MatchForm from "../../../components/Sports/Soccer/Components/Match/MatchForm";
	import Injuries from "../../../components/Sports/Soccer/Components/Match/Injuries";
	import Standings from "../../../components/Home/Standings";
	import $ from "jquery";

	export default {
		components: {
			Tab,
			Statistic,
			Lineups,
			Timeline,
			LastResults,
			TotalResults,
			MatchText,
			MatchInfo,
			MatchForm,
			Injuries,
			Standings
		},

		data() {
			return {
				match: null,
				matchEvent: null,
				id: this.$route.params.id,
				leagueId: parseInt(this.$route.params.league),
				sport: this.$route.params.sport,
				dayDiff: this.$route.query.day_diff,
				h2h: null,
				loading: true,
				videos: null,
				competition: null,
				standings: null,
				leagues: null,
				injuries: null,
				windowWidth: $(window).width(),
				leaguesArr: [1457, 1204, 1229, 1399, 1269, 1221, 1428, 1051],
				matchLoading: true,
				matchEventLoading: true,
				h2hLoading: true
			}
		},

		created() {
			if (this.dayDiff || this.dayDiff == 0) {
				this.initMatchEvent();
			} else {
				this.initMatch();
			}

			this.initInjury();

			this.initLeagues();

			$(window).on('resize', () => {
				this.windowWidth = $(window).width()
			});
		},

		computed: {
			home() {
				if (!this.match) {
					return null;
				}
				return this.match.localteam;
			},
			away() {
				if (!this.match) {
					return null;
				}
				return this.match.visitorteam;
			},
			unixTime() {
				return moment().unix();
			},
			unixDateTime() {
				return this.getDateTime(this.matchEvent.date, this.matchEvent.time, false);
			},
			goals() {
				if (this.matchEvent) {
					return this.matchEvent.game_events.filter((event) => {
						return event.type === 'goal';
					});
				}
				if (this.match) {
					return this.match.summary.filter((event) => {
						return event.type === 'goals';
					});
				}

				return null;
			}
		},

		methods: {
			initStandings(id, callback) {
				this.axios.get('/goalserve/soccer/league/standings?league_id=' + id)
				.then(({data}) => {
					this.standings = data.res ? data.res : null;

					callback();
				});
			},
			initLeagues() {
				this.axios.get('/goalserve/soccer/league')
				.then(({data}) => {
					this.leagues = data.res.filter(league => this.leaguesArr.includes(league.id));
				});
			},
			getDateTime(date, time, format = true) {
				let method = getTimeZoneNum() >= 0 ? 'add' : 'subtract';

				date = date.split('.');
				date = date[2] + '-' + date[1] + '-'+ date[0];

				let datetime = moment(date + 'T' + time)[method](getTimeZoneNum(), 'hours');

				return format ? datetime.locale('ru').format('DD MMMM Y, dddd HH:mm') : datetime;
			},
			getDay() {
				let date = this.match.date.split('.');
				date = date[2] + '-' + date[1] + '-'+ date[0];

				return moment(date).diff(moment(), 'days');
			},
			initMatchEvent() {
				this.axios.get('/goalserve/soccer/league/events?static_id=' + this.id + '&league_id=' + this.leagueId + '&day_diff=' + this.dayDiff)
				.then(({data}) => {
					this.matchEventLoading = false;
					
					this.matchEvent = data.res ? data.res[0].events[0] : null;
					
					this.matchEvent.game_events = this.matchEvent.game_events.filter(item => item.type !== 'var')

					if (this.matchEvent) {
						this.initH2h(this.matchEvent.home_id, this.matchEvent.away_id);

						this.initMatch();
					} else {
						this.loading = false;
					}
				});

				setTimeout(() => {
					this.initMatchEvent();
				}, 1000 * 60);
			},
			initMatch() {
				this.axios.get('/goalserve/soccer/league/event/comments?static_id=' + this.id + '&league_id=' + this.leagueId)
				.then(({data}) => {
					this.matchLoading = false;
					
					this.match = data.res ? data.res.tournament.match : null;
					this.loading = false;

					if (!this.match) {
						return;
					}

					this.competition = data.res.tournament.name;

					if (!this.h2h) {
						this.initH2h(this.match.localteam.id, this.match.visitorteam.id);
					}

					this.initVideos();
				})
				.catch(() => {
					this.loading = false;
				});
			},
			initH2h(home_id, away_id) {
				this.axios.get('/goalserve/soccer/h2h?team1=' + home_id + '&team2=' + away_id)
				.then(({data}) => {
					this.h2hLoading = false;
					this.h2h = data.res;
				});
			},
			initVideos() {
				this.axios.get('/goalserve/soccer/soccerhighlights?day_diff=' + this.getDay())
				.then(({data}) => {
					this.videos = data.res;
				});
			},
			getStatus(status) {
				let data = {
					'FT': 'Завершен',
					'Pen.': 'После с.п.',
					'AET': 'После д.в.',
					'Postp.': 'Перенесен',
					'TBA': 'Будет позднее',
					'Int.': 'Прерван',
				};

				return data[status] ? data[status] : '';
			},
			getActiveTab() {
				if (this.matchEvent && this.matchEvent.game_events.length) {
					return 'timeline';
				} else if (this.matchEvent) {
					return 'h2h';
				}

				return 'timeline';
			},
			initInjury() {
				this.axios.get('/goalserve/soccer/injuries?static_id=' + this.id + '&league_id=' + this.leagueId).then(({data}) => {
					this.injuries = data.res[0] ? data.res[0] : null;
				});
			}
		}
	}
</script>