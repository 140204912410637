<template>
	<div>
		<div class="res-tab_actions">
			<a href="" class="res-tab_actions-arrow __left" :class="{__disable: day_diff && day_diff <= -7}" @click.prevent="goToDay(-1)"></a>
			<a href="" class="res-tab_actions-arrow __right" :class="{__disable: day_diff && day_diff >= 7}" @click.prevent="goToDay(1)"></a>
			
			<div class="res-tab_header-item">
				<a href="" @click.prevent="toggleCalendar" class="res-tab_header-calendar">{{ activeDay.humansValue }}</a>
				
				<div class="res-tab_header-dropdown" v-if="activeDateDropdown">
					<a :class="{active: day.key == activeDay.key}" @click.prevent="update(day.key)" href="" v-for="day in days" v-text="day.value" :key="day.key"></a>
				</div>
			</div>
		</div>
		
		<tab v-if="!loading" :headings="{all: 'Все', live: 'LIVE', finished: 'Завершено'}" active-key="all" :base="true">
			<template v-slot:all>
				<links v-if="matches && matches.length" :data="matches"></links>
				<div class="info" v-else>Нет матчей</div>
			</template>
			<template v-slot:live>
				<links v-if="liveMatches && liveMatches.length" :data="liveMatches"></links>
				<div class="info" v-else>Нет матчей в прямом эфире</div>
			</template>
			<template v-slot:finished>
				<links v-if="finishedMatches && finishedMatches.length" :data="finishedMatches"></links>
				<div class="info" v-else>Нет завершенных матчей</div>
			</template>
		</tab>
		<div class="info-text" v-else style="padding-top: 20px;">Загрузка...</div>
	</div>
</template>

<script>
	import moment from 'moment';
	
	import Tab from "../../../../Tab/Tab";
	import Links from "./Links";
	
	export default {
		components: {
			Tab,
			Links
		},
		
		props: ['data', 'loading'],
		
		data() {
			return {
				day: this.$route.query.day_diff || 0,
				activeDateDropdown: false,
				day_diff: this.$route.query.day_diff
			}
		},
		created() {
			events.$emit('activeDay', this.activeDay);
		},
		computed: {
			matches() {
				if (!this.data) return null;
				
				return this.data;
			},
			liveMatches() {
				if (!this.data) return null;

				var arr = JSON.parse(JSON.stringify(this.data));
				
				return arr.filter((league) => {
					league.events = league.events.filter((match) => {
						return match.status !== 'Finished' && match.status !== 'Not Started' && match.status != '1';
					});
					return league.events.length;
				});
			},
			finishedMatches() {
				if (!this.data) return null;

				var arr = JSON.parse(JSON.stringify(this.data));

				return arr.filter((league) => {
					league.events = league.events.filter((match) => {
						return match.status === 'Finished';
					});
					return league.events.length;
				});
			},
			days() {
				let data = [
					{
						key: '0',
						value: 'Сегодня',
						humansValue: 'Сегодня',
						defaultValue: 'сегодня'
					}
				];
				
				for (let i = 1; i <= 7; i++) {
					data.unshift({
						key: '-' + i,
						value: moment().subtract(i, 'days').locale('ru').format('DD.MM ddd'),
						defaultValue: moment().subtract(i, 'days').locale('ru').format('DD MMMM Y'),
						humansValue: moment().subtract(i, 'days').locale('ru').format('D MMMM, dddd'),
					});
					data.push({
						key: i,
						value: moment().add(i, 'days').locale('ru').format('DD.MM ddd'),
						defaultValue: moment().add(i, 'days').locale('ru').format('DD MMMM Y'),
						humansValue: moment().add(i, 'days').locale('ru').format('D MMMM, dddd')
					});
				}
				
				return data;
			},
			activeDay() {
				let day =  this.days.filter((day) => {
					return day.key == this.day;
				})[0];
				
				return day;
			}
		},
		methods: {
			update(day) {
				this.day = day;
				
				this.$router.push({
					name: 'live',
					params: {
						sport: 'tennis'
					},
					query: {
						day_diff: day
					}
				});
			},
			goToDay(day) {
				let day_diff = parseInt(this.$route.query.day_diff);

				if (!day_diff) day_diff = 0;

				if (!isNaN(day_diff) && day_diff >= -7 && day_diff <= 7) {
					day_diff = day_diff + day;
				}

				this.update(day_diff);
			},
			toggleCalendar() {
				this.activeDateDropdown = !this.activeDateDropdown
			}
		}
	}
</script>

<style scoped>

</style>