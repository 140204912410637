<template>
	<div class="player_row">
		<div class="player_col">
			{{ player.number }}
		</div>
		<div class="player_col">
			<router-link :to="{name: 'player', params: {id: player.id}}"  class="player_command">
				<!--<img :src="$hostname + '/img/country/' +  player.country_code" alt="">-->
				<span>{{ player.name }}</span>
			</router-link>
		</div>
		<div class="player_col">
			{{ player.age }}
		</div>
		<div class="player_col">
			{{ player.appearences }}
		</div>
		<div class="player_col">
			{{ player.goals }}
		</div>
		<div class="player_col">
			{{ player.assists }}
		</div>
		<div class="player_col">
			{{ player.minutes }}
		</div>
	</div>
</template>

<script>
	export default {
		props: ['player'],
		
		created() {
			
		}
	}
</script>

<style scoped>

</style>