<template>
	<div class="articles" v-if="articles">
		<div class="heading">Главные новости</div>
		
		<div>
			<div v-for="article in articles" :key="article.id" class="articles_item __col-2" :class="classes">
				<router-link :to="{name: 'article', params: {id: article.id}}" class="articles_item __min">
					<img :src="article.image_ref" alt="">
					
					<div class="articles_content">
						<div>
							<div class="articles_title">
								<p>{{ article.title }}</p>
							</div>
						</div>
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['articles', 'classes']
	}
</script>

<style scoped>

</style>