<template>
	<div>
		<div class="videos_row">
			<div class="videos_col" v-for="video in youTubeVideos" :key="video.id" @click="openVideo(video)">
				<div class="videos_item">
					<div class="videos_image">
						<img :src="video.thumbnail" alt="">
						<div class="videos_icon">
							<svg style="display: block; width: 60px; height: 60px;" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 60 60"><defs></defs><g filter="url(#filter0_d)"><path fill="#fff" fill-rule="evenodd" d="M30 48c11.046 0 20-8.954 20-20S41.046 8 30 8s-20 8.954-20 20 8.954 20 20 20z" clip-rule="evenodd"></path></g><path fill="#222" fill-rule="evenodd" d="M24 36V21l15 7.5L24 36z" clip-rule="evenodd"></path><defs><filter id="filter0_d" width="60" height="60" x="0" y="0" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix><feOffset dy="2"></feOffset><feGaussianBlur stdDeviation="5"></feGaussianBlur><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"></feColorMatrix><feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend><feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend></filter></defs></svg>
						</div>
					</div>
					
					<div class="videos_title" v-if="video.title" v-html="video.title"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				youTubeVideos: [
					
					{
						id: 'rseBHnSn4fc',
						thumbnail: '/img/rseBHnSn4fc.webp',
						title: 'Турция — Уэльс — 0:2. Евро-2020. Обзор матча, все голы и лучшие моменты',
						embed: '<iframe width="560" height="315" src="https://www.youtube.com/embed/rseBHnSn4fc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
					},
					{
						id: 'AKf6HmjLZtI',
						thumbnail: '/img/AKf6HmjLZtI.webp',
						title: 'Финляндия — Россия — 0:1. Евро-2020. ПОБЕДНЫЙ ГОЛ АЛЕКСЕЯ МИРАНЧУКА, обзор матча , лучшие моменты',
						embed: '<iframe width="560" height="315" src="https://www.youtube.com/embed/AKf6HmjLZtI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
					},
					{
						id: 'Pv_nCX-rr0I',
						thumbnail: '/img/Pv_nCX-rr0I.jpg',
						title: 'Франция — Германия — 1:0. Евро-2020. Обзор матча, автогол Хуммельса и все моменты',
						embed: '<iframe width="560" height="315" src="https://www.youtube.com/embed/Pv_nCX-rr0I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
					},
					{
						id: 'OQp_RuxiUio',
						thumbnail: '/img/OQp_RuxiUio.jpg',
						title: 'Венгрия — Португалия — 0:3. Евро-2020. Рекорд и дубль Криштиану Роналду, обзор матча, все голы',
						embed: '<iframe width="560" height="315" src="https://www.youtube.com/embed/OQp_RuxiUio" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
					},
					{
						id: 'gbPZh6h_dIE',
						thumbnail: '/img/gbPZh6h_dIE.jpg',
						title: 'Испания — Швеция — 0:0. Евро-2020. Обзор матча и лучшие моменты',
						embed: '<iframe width="560" height="315" src="https://www.youtube.com/embed/gbPZh6h_dIE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
					},
					{
						id: 'w9BdU1UCxkk',
						thumbnail: '/img/w9BdU1UCxkk.jpg',
						title: 'Польша — Словакия — 1:2. Евро-2020. Первое удаление на турнире, обзор матча, голы и лучшие моменты',
						embed: '<iframe width="560" height="315" src="https://www.youtube.com/embed/w9BdU1UCxkk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
					},
					{
						id: 'bC4L9-6gUUI',
						thumbnail: '/img/bC4L9-6gUUI.jpg',
						title: 'Шотландия — Чехия — 0:2. Евро-2020. Шик забил с центра поля! Обзор матча, все голы и лучшие моменты',
						embed: '<iframe width="560" height="315" src="https://www.youtube.com/embed/bC4L9-6gUUI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
					},
					{
						id: 'AWw6KBb24zM',
						thumbnail: '/img/AWw6KBb24zM.jpg',
						title: 'Австрия — Северная Македония — 3:1. Евро-2020. Обзор матча, голы и лучшие моменты',
						embed: '<iframe width="560" height="315" src="https://www.youtube.com/embed/AWw6KBb24zM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
					},
					{
						id: 'fB2t1IRVgpI',
						thumbnail: '/img/fB2t1IRVgpI.jpg',
						title: 'Нидерланды — Украина — 3:2. Евро-2020. Обзор суперматча, голы и лучшие моменты',
						embed: '<iframe width="560" height="315" src="https://www.youtube.com/embed/fB2t1IRVgpI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
					},
					{
						id: '8zbOzhf9xCU',
						thumbnail: '/img/8zbOzhf9xCU.jpg',
						title: 'Турция — Италия — 0:3. Евро-2020. Обзор матча, голов и лучших моментов',
						embed: '<iframe width="560" height="315" src="https://www.youtube.com/embed/8zbOzhf9xCU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
					},
					{
						id: '65khGZRQNWg',
						thumbnail: '/img/65khGZRQNWg.jpg',
						title: 'Уэльс — Швейцария — 1:1. Евро-2020. Обзор матча, голов и лучших моментов',
						embed: '<iframe width="560" height="315" src="https://www.youtube.com/embed/65khGZRQNWg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
					},
					{
						id: 'uSUjeWuvMxA',
						thumbnail: '/img/uSUjeWuvMxA.jpg',
						title: 'Бельгия — Россия — 3:0. Евро-2020. Обзор матча, голы и лучшие моменты',
						embed: '<iframe width="560" height="315" src="https://www.youtube.com/embed/uSUjeWuvMxA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
					},
					{
						id: 'L-iXYhWQR_k',
						thumbnail: '/img/L-iXYhWQR_k.jpg',
						title: 'Бельгия — Россия — 3:0. Евро-2020. Обзор матча, голы и лучшие моменты',
						embed: '<iframe width="560" height="315" src="https://www.youtube.com/embed/L-iXYhWQR_k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
					},
					{
						id: 'cFBAtA413yU',
						thumbnail: '/img/cFBAtA413yU.jpg',
						title: 'Англия — Хорватия — 1:0. Евро-2020. Обзор матча, гол и лучшие моменты',
						embed: '<iframe width="560" height="315" src="https://www.youtube.com/embed/cFBAtA413yU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
					},
				],
			}
		},
		methods: {
			openVideo(video) {
				events.$emit('openVideo', video);
			},
		}
	}
</script>

<style scoped>

</style>