import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import axios from 'axios';
import VueCountdownTimer from 'vuejs-countdown-timer';
Vue.use(VueCountdownTimer);

Vue.config.productionTip = false;

Vue.prototype.axios = window.axios = axios;

if (location.host.search('localhost') !== -1 || location.host.search('nlt-sm.ru') !== -1) {
	axios.defaults.baseURL = 'http://tele2.roscontent.ru';
	Vue.prototype.$hostname = 'http://tele2.roscontent.ru';
} else {
	axios.defaults.baseURL = '';
	Vue.prototype.$hostname = '';
	Vue.config.local = false;
}

window.events = new Vue();

window.getTimeZoneNum = function () {
	var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
	return parseInt((offset < 0 ? "+" : "-") + ("" + Math.floor(o / 60)).slice(-2));
};

window.title = ' - Soccer-Club: Футбольные новости, результаты матчей';

new Vue({
	render: h => h(App),
	router
}).$mount('#app')
