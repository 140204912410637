<template>
	<div class="match_left">
		<div class="match_menu">
			<div class="match_button" @click="menuActive = true">
				<span></span>
				<i data-v-9b8449ee="" class="ico icon-side-arrow"></i>
			</div>
			
			<div class="match_menu-inner" :class="{ active: menuActive }">
				<div class="header_menu-top match_menu-top">
					<div class="header_menu-close" @click="menuActive = false"></div>
				</div>
				<router-link :to="{ name: 'league', params: {id: league.id, sport: sport ? sport : $route.params.sport} }" class="match_menu-item" v-for="league in leagues" :key="league.id">
					<div @click="fireEvent(league)">
						<span class="match_menu-ico" v-if="league.country_code">
							<span class="flag-icon" :class="['flag-icon-' + getFlagName(league.country_code)]"></span>
						</span>
						<span class="league-name">{{ league.name }}</span>
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['data', 'sport'],
		
		data() {
			return {
				items: [],
				menuActive: false,
				page: 1,
				id: Number(this.$route.params.id),
			}
		},
		
		created() {
			this.initTitle();
		},
		
		computed: {
			leagues() {
				return this.leaguesFiltered;
			},
			leaguesFiltered() {
				let ids = [this.id, 1005, 1007, 1204, 1229, 1399, 1269, 1457, 1221, 1428, 4529];
				
				ids = ids.reverse();
				let arr = this.data.res;
				
				ids.forEach((id) => {
					arr = arr.sort((a, b) => {
						if (a.id == id) {
							return -1;
						}
						if (b.id == id) {
							return 1;
						}
					});
				});
				
				return arr;
			},
			leaguesFilteredById() {
				let ids = [this.id];
				let arr = this.data.res;
				
				ids.forEach((id) => {
					arr = arr.sort((a, b) => {
						if (a.id == id) {
							return -1;
						}
						if (b.id == id) {
							return 1;
						}
					});
				});
				
				return arr;
			}
		},
		methods: {
			initTitle() {
				setTimeout(() => {
					var text = $('.match_menu-item.router-link-active').html();
					var textSimple = $('.match_menu-item.router-link-active').text();
					
					if (text && text.length) {
						$('.match_button span').html('').append(text);
						
						this.$emit('init', textSimple)
					} else {
						$('.match_button span').html('Лиги');
					}
				}, 300);
			},
			getFlagName(flagName) {
				let exclude = {
					EN: 'gb-eng'
				};
				
				return exclude[flagName] ? exclude[flagName].toLowerCase() : flagName.toLowerCase();
			},
			fireEvent(league) {
				dataLayer.push({
					'event': 'addEvents_makeActions',
					'event_id': 'd-v333-e11',
					'event_cat': 'football',
					'event_name': 'clickElement',
					'event_param': 'Матч-центр / ' + league.name,
					'auth_zone': window.auth.authZone
				});
			}
		},
	}
</script>