<template>
	<div>
		<soccer v-if="sport === 'soccer'"></soccer>
		<hockey v-if="sport === 'hockey'"></hockey>
		<basketball v-if="sport === 'bsktbl'"></basketball>
	</div>
</template>

<script>
	import Soccer from "./Sports/Soccer/League";
	import Hockey from "../components/Sports/Hockey/Pages/LeaguePage";
	import Basketball from "../components/Sports/Basketball/Pages/LeaguePage";

	export default {
		components: {
			Soccer,
			Hockey,
			Basketball
		},
		data() {
			return {
				sport: this.$route.params.sport
			}
		},
	}
</script>