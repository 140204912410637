<template>
	<div class="live-statistic_item">
		<div v-for="(value, statistic) in statistics" :key="statistic">
			<div class="live-statistic_row">
				<div class="live-statistic_text">
					<div>{{ getObj(home, statistic) }}</div>
				</div>
				<div class="live-statistic_text">
					{{ value }}
				</div>
				<div class="live-statistic_text">
					<div>{{ getObj(away, statistic) }}</div>
				</div>
			</div>
			
			<div class="live-statistic_row-2">
				<div class="live-statistic_col">
					<div class="live-statistic_line">
						<div class="live-statistic_line-inner" 
							 :class="getClassname(getObj(home, statistic), getObj(away, statistic))" 
							 :style="'width:' + getWidth(getObj(home, statistic), getObj(away, statistic)).home  + '%'"></div>
					</div>
				</div>
				<div class="live-statistic_col">
					<div class="live-statistic_line">
						<div class="live-statistic_line-inner __gray" 
							 :class="getClassname(getObj(away, statistic), getObj(home, statistic))"
							 :style="'width:' + getWidth(getObj(home, statistic), getObj(away, statistic)).away  + '%'"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['data'],
		
		data() {
			return {
				
			}
		},
		
		computed: {
			home() {
				let item = JSON.parse(JSON.stringify(this.data.localteam));
				
				if (! item.offsides.total) {
					item.offsides.total = 0;
				}
				
				if (! item.passes.pct) {
					let value = parseInt(item.passes.accurate / item.passes.total * 100);
					
					if (!isNaN(value)) {
						item.passes.pct = value + '%';
					}
				}
				
				return item;
			},
			away() {
				let item = JSON.parse(JSON.stringify(this.data.visitorteam));
				
				if (! item.offsides.total) {
					item.offsides.total = 0;
				}

				let value = parseInt(item.passes.accurate / item.passes.total * 100);

				if (!isNaN(value)) {
					item.passes.pct = value + '%';
				}
				
				return item;
			},
			statistics() {
				return {
					'possestiontime.total': 'Владение мячом',
					'shots.ongoal' : 'Удары в створ',
					'shots.offgoal' : 'Удары мимо',
					'corners.total' : 'Угловые',
					'offsides.total' : 'Оффсайды',
					'fouls.total' : 'Фолы',
					'yellowcards.total' : 'Желтые карточки',
					'redcards.total' : 'Красные карточки',
					'passes.total' : 'Всего передач',
					'passes.pct' : 'Точность передач',
				}
			}
		},
		
		methods: {
			getPercentage(home, away) {
				var total = home + away;
				
				return {
					home: parseInt(home / total * 100) || 0,
					away: parseInt(away / total * 100) || 0
				}
			},
			getClassname(value1, value2) {
				return {
					'__green' : parseInt(value1) > parseInt(value2),
					'__gray' : parseInt(value1) <= parseInt(value2)
				};
			},
			getObj(obj, path, defaultValue = undefined) {
				const travel = regexp =>
					String.prototype.split
					.call(path, regexp)
					.filter(Boolean)
					.reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
				const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
				return result === undefined || result === obj ? defaultValue : result;
			},
			
			getWidth(home, away) {
				return this.getPercentage(parseInt(home), parseInt(away));
			}
		},
	}
</script>