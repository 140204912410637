<template>
	<!-- eslint-disable -->
	
	<div>
		<div class="match_item-header active">
			<span v-text="getName(group.group_prefix, group.group_suffix)"></span>
			<i class="ico icon-side-arrow"></i>
		</div>
		<div class="match_item-data active">
			<div class="match-data">
				<router-link v-for="match in events"
							 class="match-data_item" 
							 :to="{ name: 'match-hockey', params: {id: match.stats_id, league: league, sport: 'hockey'} }">
					<link-result :match="match" :show-date="true"></link-result>
				</router-link>
			</div>
			
			<div class="button-block">
				<button v-if="group.events.length > events.length" class="button" @click="page++">Показать еще</button>
			</div>
			<br v-if="group.events.length > events.length">
		</div>
	</div>
</template>

<script>
	import LinkResult from "./LinkResult";
	
	export default {
		components: {
			LinkResult
		},
		
		props: ['group', 'match'],
		
		created() {
			
		},

		data() {
			return {
				page: 1,
				league: this.$route.params.id
			}
		},
		
		computed: {
			events() {
				return this.group.events.slice(0, this.page * 10);
			}
		},
		
		methods: {
			getName(prefix, suffix) {
				let data = {
					'Regular': 'Регулярный чемпионат',
					'NHL - Semi-finals': 'НХЛ - Полуфиналы',
					'NHL - Final': 'НХЛ - Финал',
					'NHL - Quarter-finals': 'НХЛ - Четвертьфиналы',
				};
				
				prefix = data[prefix] ? data[prefix] : prefix ? prefix : '';
				suffix = data[suffix] ? data[suffix] : suffix ? suffix : '';
				
				if (!isNaN(Number(prefix)) && !suffix) {
					prefix = 'Тур ' + prefix;
				}
				
				return prefix + ' ' + suffix;
			}
		}
	}
</script>

<style scoped>

</style>