<template>
	<div class="res">
		<div class="wrapper">
			<div class="res_row">
				<div class="res_left">
					<div class="res_head">Матч-центр</div>
					
					<leagues @update="update" v-if="leagues" :data="leagues" @init="initTitle"></leagues>
				</div>
				
				<div class="res_right">
					<tab :headings="{results: 'Результаты', schedule: 'Расписание', standings: 'Таблица', scorers: 'Бомбардиры'}" active-key="results">
						<template v-slot:results>
							<links :show-date="true" v-if="results" :data="results" type="finished"></links>
							<div v-else-if="resultsLoading" class="info-text"></div>
							<div v-else-if="!resultsLoading" class="info">Нет данных</div>
						</template>
						<template v-slot:schedule>
							<links v-if="schedule" :data="schedule" :show-date="true" type="schedule"></links>
							<div v-else class="info">Нет данных</div>
						</template>
						<template v-slot:standings>
							<div class="__pk">
								<tab v-if="standings" :headings="{common: 'Общая', home: 'Дома', guest: 'Гостевая', form: 'Форма'}" active-key="common">
									<template v-slot:common>
										<standings v-if="standings" :data="standings"></standings>
									</template>
									
									<template v-slot:home>
										<standings v-if="standings" :data="standings" type="home"></standings>
									</template>
									
									<template v-slot:guest>
										<standings v-if="standings" :data="standings" type="away"></standings>
									</template>
									
									<template v-slot:form>
										<div class="live-match_form">
											<standings v-if="standings" :data="standings" :show-form="true"></standings>
										</div>
									</template>
								</tab>
								<div v-else class="info">Нет данных</div>
							</div>
						</template>
						<template v-slot:scorers>
							<scorers v-if="scorers" :data="scorers"></scorers>
							<div v-else class="info">Нет данных</div>
						</template>
					</tab>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';

	import Leagues from "@/components/Sports/Common/Leagues";
	import Tab from "@/components/Tab/Tab";
	import Links from "@/components/Sports/Soccer/Components/Events/Links";
	import Standings from "@/components/Sports/Soccer/Components/Events/Standings";
	import Scorers from "@/components/Sports/Soccer/Components/Events/Scorers";

	export default {
		components: {
			Leagues,
			Tab,
			Links,
			Standings,
			Scorers
		},
		data() {
			return {
				id: this.$route.params.id,
				leagues: null,
				standings: null,
				scorers: null,
				results: null,
				resultsLoading: true,
				schedule: null
			}
		},
		created() {
			this.initLeagues();

			this.initStandings();

			this.initScorers();

			this.initResults();

			this.initSchedule();
		},
		methods: {
			initLeagues() {
				this.axios.get('/goalserve/soccer/league')
				.then(({data}) => {
					this.leagues = data;
				});
			},
			initStandings() {
				this.axios.get('/goalserve/soccer/league/standings?league_id=' + this.id)
				.then(({data}) => {
					this.standings = data.res ? data.res : null;
				});
			},
			initScorers() {
				this.axios.get('/goalserve/soccer/league/topscorers?league_id=' + this.id)
				.then(({data}) => {
					this.scorers = data.res;
				});
			},
			initResults() {
				this.axios.get('/goalserve/soccer/league/soccerfixtures?status=finished&league_id=' + this.id)
				.then(({data}) => {
					this.results = data.res.sort((a, b) => {
						let eventADate = a.events[0].date.split('.');
						let eventBDate = b.events[0].date.split('.');

						eventADate = eventADate[2] + '-' + eventADate[1] + '-'+ eventADate[0];
						eventBDate = eventBDate[2] + '-' + eventBDate[1] + '-'+ eventBDate[0];

						if (moment(eventBDate).isAfter(eventADate)) {
							return 1;
						}
						return -1;
					});

					this.resultsLoading = false;
				})
				.catch(() => {
					this.resultsLoading = false;
				});
			},
			initSchedule() {
				this.axios.get('/goalserve/soccer/league/soccerfixtures?status=not_finished&league_id=' + this.id)
				.then(({data}) => {
					let groups = data.res.filter((group) => {
						group.events = group.events.filter((match) => {
							return match.status !== 'Postp.';
						});
						return group.events.length;
					});

					this.schedule = groups.sort((a, b) => {
						let eventADate = a.events[0].date.split('.');
						let eventBDate = b.events[0].date.split('.');

						eventADate = eventADate[2] + '-' + eventADate[1] + '-'+ eventADate[0];
						eventBDate = eventBDate[2] + '-' + eventBDate[1] + '-'+ eventBDate[0];

						if (moment(eventADate).isAfter(eventBDate)) {
							return 1;
						}
						return -1;
					});
				});
			},
			update(league) {
				this.$router.push({
					name: 'league',
					params: {
						id: league
					}
				});
			},
			initTitle(currentTitle) {
				document.title = currentTitle + window.title;
			}
		}
	}
</script>