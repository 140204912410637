<template>
	<div class="news">
		<div class="wrapper">
			<div class="news_content __item">
				<h1 class="news_heading">
					{{ news.title }}
				</h1>
				<div class="news_date">
					{{ getTime(news.pub_date) }}
				</div>
				<div class="news_content" v-html="news.content"></div>
				
<!--				<div class="main_news-tags">
					<router-link :to="{name: 'news', query: {tag: tag}}" v-for="tag in news.tags" :key="tag">{{ tag  }}</router-link>
				</div>-->
				
				<br><br>
				<div id="hypercomments_widget"></div>
				<br><br>
				
				<router-link class="button" :to="{name: 'news'}">Вернуться</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	
	export default {
		data() {
			return {
				news: {}
			}
		},
		
		created() {
			this.axios.get('/api/news/' + this.$route.params.id)
				.then(({data}) => {
					this.news = data;

					document.title = this.news.title + window.title;
				});
		},

		mounted() {
			window._hcwp = window._hcwp || [];
			_hcwp.push({widget:"Stream", widget_id: 108743});
			(function() {
				window.HC_LOAD_INIT = true;
				var lang = (navigator.language || navigator.systemLanguage || navigator.userLanguage || "en").substr(0, 2).toLowerCase();
				var hcc = document.createElement("script"); hcc.type = "text/javascript"; hcc.async = true;
				hcc.src = ("https:" == document.location.protocol ? "https" : "http")+"://w.hypercomments.com/widget/hc/108743/"+lang+"/widget.js";
				var s = document.getElementsByTagName("script")[0];
				s.parentNode.insertBefore(hcc, s.nextSibling);
			})();
		},
		
		methods: {
			getTime(date) {
				return moment(date).locale('ru').format('D MMMM Y, HH:mm')
			}
		}
	}
</script>

<style scoped>

</style>