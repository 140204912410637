<template>
	<div class="timeline">
		<div class="timeline_head" v-if="time5.length">Буллиты</div>
		<timeline-result-base :incidents="time5" v-if="time5.length"></timeline-result-base>
		<div class="timeline_head" v-if="time4.length">Овертайм</div>
		<timeline-result-base :incidents="time4" v-if="time4.length"></timeline-result-base>
		<div class="timeline_head" v-if="time3.length">3-й период</div>
		<timeline-result-base :incidents="time3" v-if="time3.length"></timeline-result-base>
		<div class="timeline_head" v-if="time2.length">2-й период</div>
		<timeline-result-base :incidents="time2" v-if="time2.length"></timeline-result-base>
		<div class="timeline_head" v-if="time1.length">1-й период</div>
		<timeline-result-base :incidents="time1" v-if="time1.length"></timeline-result-base>
	</div>
</template>

<script>
	import moment from 'moment';
	
	import TimelineResultBase from "./TimelineResultBase";
	
	export default {
		components: {
			TimelineResultBase
		},
		
		props: ['penalties', 'scoring'],
		
		created() {
			
			
		},
		computed: {
			time1() {
				return this.getData(this.scoring.firstperiod, this.penalties.firstperiod);
			},
			time2() {
				return this.getData(this.scoring.secondperiod, this.penalties.secondperiod);
			},
			time3() {
				return this.getData(this.scoring.thirdperiod, this.penalties.thirdperiod);
			},
			time4() {
				return this.getData(this.scoring.overtime, this.penalties.overtime);
			},
			time5() {
				return this.getData(this.scoring.shootout, this.penalties.shootout);
			},
		},
		methods: {
			getData(scoring, penalties) {
				scoring = scoring ? this.setType(this.makeArray(scoring.event), 'goal') : [];
				penalties = penalties ? this.setType(this.makeArray(penalties.penalty), 'penalty') : [];
				
				return this.sortByTime(scoring.concat(penalties));
			},
			makeArray(obj) {
				if (!Array.isArray(obj)) {
					obj = [obj];
				}
				return obj;
			},
			sortByTime(arr) {
				return arr.sort((a, b) => {
					var beginningTime = moment(a.min, 'hh:mm');
					var endTime = moment(b.min, 'hh:mm');
					
					return beginningTime.isBefore(endTime);
				});
			},
			setType(arr, type) {
				arr.forEach((item) => {
					item.type = type;
				});
				return arr;
			}
		}
	}
</script>

<style scoped>

</style>