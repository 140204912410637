<template>
	<!-- eslint-disable -->
	
	<div class="match_items">
		<div class="match_item">
			<div class="match_item-content" v-for="group in matches">
				<div>
					<div class="match_item-header active">
						<span v-if="group.league_name" v-text="group.league_name"></span>
						<span v-else>{{ getTitle(group.group_prefix, group.group_suffix) }}</span>
						<i class="ico icon-side-arrow"></i>
					</div>
					<div class="match_item-data active">
						<div class="match-data">
							<router-link v-for="match in group.events"
										 class="match-data_item"
										 :to="getLink(match)">
								<link-soccer :show-date="showDate" :match="match"></link-soccer>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment'
	import LinkSoccer from "./Link";

	export default {
		components: {
			LinkSoccer
		},

		props: ['data', 'showDate', 'hidePrefix', 'dayDiff', 'type'],
		
		computed: {
			matches() {
				return this.data;
			},
		},

		methods: {
			getTitle(prefix, suffix) {
				let data = {
					'Tour': 'Тур',
					'Week': 'Тур',
					'Group Stage': 'Групповая стадия',
					'Play-offs': 'Финал',
					'R59013': 'Финал',
					'R59012': 'Финал',
					'16th Finals': '1/16 финала',
					'8th Finals': '1/8 финала',
					'Quarter-finals': 'Четвертьфиналы',
					'Semi-finals': 'Полуфиналы',
					'Final': 'Финал',
					'3rd Qualifying Round': 'Полуфиналы',
					'2nd Qualifying Round': 'Четвертьфиналы',
					'1st Qualifying Round': '1/8 финала',
					'Preliminary Round': 'Предварительный раунд',
					'Promotion Play-offs - Final': 'Плей-офф, финал',
					'Qualifying': '(квалификация)',
					'Knock Out': '(квалификация)',
					'Матчи': 'Матчи',
				};

				let pref = '';

				if (!this.hidePrefix) {
					pref = data[prefix] ? data[prefix]: prefix ? prefix: '';
				}
				suffix = data[suffix] ? data[suffix] : suffix ? suffix : '';

				return pref + ' ' + suffix;
			},
			getLink(match) {
				let query = {};

				if (this.dayDiff || this.dayDiff == 0) {
					query.day_diff = this.dayDiff;
				}

				if (this.dayDiff === undefined && this.type === 'finished') {
					let today = moment(moment().format('DD-MM-YYYY'), "DD-MM-YYYY");
					let dateofvisit = moment(match.date, 'DD-MM-YYYY');
					let diff = dateofvisit.diff(today, 'days');

					if (diff >= -7 && diff <= 7) {
						query.day_diff = diff;
					}
				}

				return {
					name: 'event',
					params: {
						id: match.static_id,
						league: match.league_id,
						sport: 'soccer'
					},
					query: query
				}
			}
		}
	}
</script>

<style scoped>

</style>