<template>
	<div>
		<div class="content-block" v-if="!loading && player">
			<div class="wrapper">
				<div class="player-card">
					<h2 class="player-card_title">{{ player.name }}</h2>
					<div class="player-card_main">
						<div class="player-card_image">
							<img :src="'data:image/png;base64, ' + player.image" alt="">
						</div>
						<div class="player-card_content">
							<h3>Досье</h3>
							
							<div class="player-card_row">
								<div class="player-card_col">
									<table class="player-card_table">
										<tbody>
											<tr>
												<td>Имя</td>
												<td>
													<strong>{{ player.name }}</strong>
												</td>
											</tr>
											<tr>
												<td>Дата рождения</td>
												<td>
													<strong>{{ player.bday }}</strong>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div class="player-card_col">
									<table class="player-card_table">
										<tbody>
											<tr>
												<td>Страна</td>
												<td>
													<strong>{{ player.country }}</strong>
												</td>
											</tr>
											<tr>
												<td>Ранк</td>
												<td>
													<strong>{{ player.rank }}</strong>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<br>
				
				<div class="heading">Результаты</div>
				
				<links-results :data="player.results.category"></links-results>
			</div>
		</div>
		
		<div v-else class="info-text"></div>
		
		<div class="content-block" v-if="!loading && !player">
			<div class="wrapper">
				<div class="info __pt0">Нет данных</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Tab from "../../../Tab/Tab";
	import LinksResults from "../Components/Events/LinksResults";
	
	export default {
		components: {
			Tab,
			LinksResults
		},
		
		data() {
			return {
				id: this.$route.params.id,
				player: null,
				loading: true
			}
		},
		
		created() {
			this.axios.get('/goalserve/tennis/scores/profile?id=' + this.id)
				.then(({data}) => {
					this.player = data.res;
					
					this.loading = false;
				});
		}
	}
</script>

<style scoped>

</style>