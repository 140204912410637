<template>
	<div>
		<div class="res-tab">
			<div class="res-tab_head">
				<ul class="res-tab_head-list __col-2" :class="classNames">
					<li :class="{active: name === active}" v-for="(heading, name) in headings" :key="'heading-' + name" v-show="heading !== false">
						<a @click.prevent="changeTab(name)" href="">{{ heading }}</a>
					</li>
					<slot name="custom-heading"></slot>
					
					<li class="__hide" v-if="showHidden">
						<a href="" @click.prevent="hiddenBody = !hiddenBody" :class="{active: hiddenBody}">
							<span v-if="!hiddenBody">Скрыть табло</span>
							<span v-else>Показать табло</span>
						</a>
					</li>
				</ul>
			</div>
			<div class="res-tab_body" :class="{__hidden: hiddenBody}">
				<div :class="{active: name === active}" class="res-tab_block" v-for="(heading, name) in headings" :key="'content' + name">
					<slot v-if="name === active" :name="name"></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['headings', 'activeKey', 'base', 'classNames', 'showHidden'],

		data() {
			return {
				active: this.activeKey,
				hiddenBody: false
			}
		},

		methods: {
			changeTab(name) {
				this.active = name;
			}
		},
		
		watch: {
			activeKey(newValue) {
				this.active = newValue
			}
		}
	}
</script>

<style scoped>

</style>