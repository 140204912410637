<template>
	<div>
		<lineup-field v-if="match.teams" :data="match.teams"></lineup-field>
		
		<tab :headings="{players: players.length ? 'Стартовые составы' : false, substitutes: substitutes.length ? 'Запасные' : false}" active-key="players">
			<template v-slot:players>
				<lineup-base v-if="players.length" :data="players"></lineup-base>
				<div v-else class="info">Нет данных</div>
			</template>
			<template v-slot:substitutes>
				<lineup-base v-if="substitutes.length" :data="substitutes"></lineup-base>
				<div v-else class="info">Нет данных</div>
			</template>
		</tab>
	</div>
</template>

<script>
	import LineupBase from "./LineupBase";
	import LineupField from "./LineupField";
	import Tab from "../../../../Tab/Tab";

	export default {
		components: {
			Tab,
			LineupBase,
			LineupField
		},

		props: ['match'],

		computed: {
			players() {
				if (!this.match.teams) {
					return {
						length: 0
					}
				}

				return {
					home: this.match.teams.localteam.player,
					away: this.match.teams.visitorteam.player,
					length: Math.max(this.match.teams.localteam.player.length, this.match.teams.visitorteam.player.length)
				}
			},
			substitutes() {
				if (!this.match.substitutes || !this.match.substitutes.localteam || !this.match.substitutes.visitorteam) {
					return {
						length: 0
					}
				}

				return {
					home: this.match.substitutes.localteam ? this.match.substitutes.localteam.player : [],
					away: this.match.substitutes.visitorteam ? this.match.substitutes.visitorteam.player : [],
					length: Math.max(this.match.substitutes.localteam.player.length, this.match.substitutes.visitorteam.player.length)
				}
			},
		},

		created() {

		}
	}
</script>

<style scoped>

</style>