<template>
	<div class="res">
		<div class="wrapper">
			<div class="res_row">
				<div class="res_left __mobile-order-2">
					<main-news :show-image="true" title="Новости. Евро-2020" :news="news"></main-news>
					
					<div v-if="news && !allNewsLoaded" class="button-block" style="margin-top: -50px;">
						<a href="" @click.prevent="loadNews" class="button">Показать еще</a>
					</div>
				</div>
				
				<div class="res_right __mobile-order-1">
					<div class="entity-header">
						<div class="entity-header_row">
							<div class="entity-header_logo">
								<img src="/img/euro-logo.png" alt="">
							</div>
							
							<div class="entity-header_content">
								<div class="entity-header_title">ЧЕ-2020 - финальный раунд</div>
								
								<div class="entity-header_items">
									<div class="entity-header_item">
										<span>Даты проведения:</span>
										<strong>11.06.2021—11.07.2021</strong>
									</div>
									<div class="entity-header_item">
										<span>Категория:</span>
										<strong>Сборные</strong>
									</div>
									<div class="entity-header_item">
										<span>Участники:</span>
										<strong>24</strong>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					<tab :headings="{standings: 'Таблица', schedule: 'Расписание', results: 'Результаты', scorers: 'Бомбардиры', coaches: 'Тренеры', stadium: 'Стадионы', referees: 'Судьи'}" active-key="standings">
						<template v-slot:results>
							<links :show-date="true" v-if="results" :data="results" type="finished"></links>
							<div v-else-if="resultsLoading" class="info-text"></div>
							<div v-else-if="!resultsLoading" class="info">Нет данных</div>
						</template>
						<template v-slot:schedule>
							<links v-if="schedule" :data="schedule" :show-date="true" type="schedule"></links>
							<div v-else class="info">Нет данных</div>
						</template>
						<template v-slot:standings>
							<div class="__pk">
								<standings :hide-description="true" v-if="standings" :data="standings"></standings>
								<div v-else-if="standingsLoading" class="info-text"></div>
								<div v-else class="info">Нет данных</div>
							</div>
						</template>
						<template v-slot:scorers>
							<scorers v-if="scorers" :data="scorers"></scorers>
							<div v-else class="info">Нет данных</div>
						</template>
						<template v-slot:coaches>
							<coaches></coaches>
						</template>
						<template v-slot:stadium>
							<stadium></stadium>
						</template>
						<template v-slot:referees>
							<referees></referees>
						</template>
						
						<template v-slot:videos>
							<div>
								<euro2020-videos></euro2020-videos>
							</div>
						</template>
					</tab>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';

	import Leagues from "@/components/Sports/Common/Leagues";
	import Tab from "@/components/Tab/Tab";
	import Links from "@/components/Sports/Soccer/Components/Events/Links";
	import Standings from "@/components/Sports/Soccer/Components/Events/Standings";
	import Scorers from "@/components/Sports/Soccer/Components/Events/Scorers";
	import MainNews from "@/components/Home/MainNews";
	import Coaches from "@/components/Sports/Soccer/Components/Euro2020/Coaches";
	import Stadium from "@/components/Sports/Soccer/Components/Euro2020/Stadium";
	import Referees from "@/components/Sports/Soccer/Components/Euro2020/Referees";
	import Euro2020Videos from "../components/Videos/Euro2020";

	export default {
		components: {
			Leagues,
			Tab,
			Links,
			Standings,
			Scorers,
			MainNews,
			Coaches,
			Stadium,
			Referees,
			Euro2020Videos
		},
		data() {
			return {
				id: this.$route.params.id,
				leagues: null,
				standings: null,
				scorers: null,
				results: null,
				resultsLoading: true,
				standingsLoading: true,
				schedule: null,
				news: null,
				newsPage: 1,
				allNewsLoaded: false
			}
		},
		created() {
			this.initStandings();

			this.initScorers();

			this.initResults();

			this.initSchedule();
			
			this.initNews();
		},
		methods: {
			initStandings() {
				this.axios.get('/goalserve/soccer/league/standings?league_id=1001')
				.then(({data}) => {
					this.standings = data.res ? data.res : null;
					
					this.standingsLoading = false;
				});
			},
			initScorers() {
				this.axios.get('/goalserve/soccer/league/topscorers?league_id=1001')
				.then(({data}) => {
					this.scorers = data.res;
				});
			},
			initResults() {
				this.axios.get('/goalserve/soccer/league/soccerfixtures?status=finished&league_id=1001')
				.then(({data}) => {
					this.results = data.res.sort((a, b) => {
						let eventADate = a.events[0].date.split('.');
						let eventBDate = b.events[0].date.split('.');

						eventADate = eventADate[2] + '-' + eventADate[1] + '-'+ eventADate[0];
						eventBDate = eventBDate[2] + '-' + eventBDate[1] + '-'+ eventBDate[0];

						if (moment(eventBDate).isAfter(eventADate)) {
							return 1;
						}
						return -1;
					});

					this.resultsLoading = false;
				})
				.catch(() => {
					this.resultsLoading = false;
				});
			},
			initSchedule() {
				this.axios.get('/goalserve/soccer/league/soccerfixtures?status=not_finished&league_id=1001')
				.then(({data}) => {
					let groups = data.res.filter((group) => {
						group.events = group.events.filter((match) => {
							return match.status !== 'Postp.';
						});
						return group.events.length;
					});

					this.schedule = groups.sort((a, b) => {
						let eventADate = a.events[0].date.split('.');
						let eventBDate = b.events[0].date.split('.');

						eventADate = eventADate[2] + '-' + eventADate[1] + '-'+ eventADate[0];
						eventBDate = eventBDate[2] + '-' + eventBDate[1] + '-'+ eventBDate[0];

						if (moment(eventADate).isAfter(eventBDate)) {
							return 1;
						}
						return -1;
					});
				});
			},
			update(league) {
				this.$router.push({
					name: 'league',
					params: {
						id: league
					}
				});
			},
			initTitle(currentTitle) {
				document.title = currentTitle + window.title;
			},
			initNews() {
				this.axios.get('/api/articles', {params: {sport: 'soccer', limit: 10, page: 1, tag: 'Евро-2020'}})
					.then(({data}) => {
						this.news = data.data;
					});
			},
			loadNews() {
				this.newsPage++;
				
				this.axios.get('/api/articles', {params: {sport: 'soccer', limit: 10, page: this.newsPage, tag: 'Евро-2020'}})
					.then(({data}) => {
						this.news = this.news.concat(data.data);
						
						this.allNewsLoaded = data.data.length < 10;
					});
			}
		}
	}
</script>