<template>
	<div class="main_news __mb" v-if="news">
		<div class="heading">{{ title ? title : 'Футбольные новости' }}</div>
		
		<div class="main_news-item" v-for="newsItem in news" :key="newsItem.id">
			<router-link :to="{name: 'news-item', params: {id: newsItem.id}}" v-if="showImage" class="main_news-image">
				<img :src="newsItem.image_ref" alt="">
			</router-link>
			
			<div class="main_news-date">{{ getTime(newsItem.pub_date) }}</div>
			
			<router-link :to="{name: 'news-item', params: {id: newsItem.id}}">
				{{ newsItem.title }}
			</router-link>
		</div>
	</div>
</template>

<script>
	import moment from "moment";

	export default {
		props: ['news', 'title', 'showImage'],
		
		methods: {
			getTime(date) {
				if (moment(date).format('D MM') === moment(new Date()).format('D MM')) {
					return moment(date).locale('ru').format('Сегодня, HH:mm')
				}
				return moment(date).locale('ru').format('D MMMM Y, HH:mm')
			},
		}
	}
</script>

<style scoped>

</style>