<template>
	<div class="res">
		<div class="wrapper">
			<div class="res_row">
				<div class="res_left">
					<leagues v-if="leagues" :sport="sport" :data="leagues"></leagues>
					<div v-else class="info-text"></div>
				</div>
				
				<div class="res_right">
					<div class="tab">
						<match-header></match-header>
						
						<list :data="matchesData" :loading="loading"></list>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Leagues from "../../Common/Leagues";
	import MatchHeader from "../../Common/MatchHeader";
	import List from "../Components/Events/List";

	export default {
		components: {
			Leagues,
			MatchHeader,
			List
		},

		data() {
			return {
				sport: this.$route.params.sport,
				leagues: null,
				matchesData: null,
				activeDay: {},
				loading: false
			}
		},

		created() {
			this.initLeagues();

			this.loading = true;
			this.update();

			events.$on('activeDay', this.initDay);
		},

		methods: {
			initData() {
				let day = this.$route.query.day_diff || 0;
				let url = '/goalserve/' + this.sport + '/league/events';

				if (day !== 0) {
					url += '?day_diff=' + day;
				}

				this.axios.get(url)
				.then(({data}) => {
					this.matchesData = data.res;

					this.loading = false;
				});
			},
			initLeagues() {
				this.axios.get('/goalserve/' + this.sport + '/leagues')
				.then(({data}) => {
					this.leagues = data;
				});
			},
			initDay(day) {
				this.activeDay = day;
			},
			update() {
				if (this.sport !== this.$route.params.sport) return;

				this.initData();

				setTimeout(() => {
					this.update();
				}, 1000 * 60);
			}
		}
	}
</script>

<style scoped>

</style>