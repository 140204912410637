<template>
	<div>
		<div class="heading">Таблица чемпионата</div>
		
		<div class="live-match __mb __standings">
			<select @change="changeLeague" class="live-match_select" v-model="currentLeague" v-if="leagues">
				<option :value="league.id" v-for="league in leagues" :key="league.id">{{ league.name }}</option>
			</select>
			
			<tab :headings="{common: 'Общая', home: 'Дома', guest: 'Гостевая', form: 'Форма'}" active-key="common">
				<template v-slot:common>
					<div class="live-match_form">
						<div v-if="loading" class="info-text"></div>
						<standings v-else-if="standings" :data="standings" :hide-heading="true"></standings>
					</div>
				</template>
				
				<template v-slot:home>
					<div class="live-match_form">
						<div v-if="loading" class="info-text"></div>
						<standings v-else-if="standings" :data="standings" :hide-heading="true" type="home"></standings>
					</div>
				</template>
				
				<template v-slot:guest>
					<div class="live-match_form">
						<div v-if="loading" class="info-text"></div>
						<standings v-else-if="standings" :data="standings" :hide-heading="true" type="away"></standings>
					</div>
				</template>
				
				<template v-slot:form>
					<div class="live-match_form __simple">
						<div v-if="loading" class="info-text"></div>
						<standings v-else-if="standings" :data="standings" :hide-heading="true" :show-form="true"></standings>
					</div>
				</template>
			</tab>
		</div>
	</div>
</template>

<script>
	import Tab from "../Tab/Tab";
	import Standings from "../Sports/Soccer/Components/Events/Standings";
	
	export default {
		props: ['standings', 'leagues', 'league'],
		
		components: {
			Tab,
			Standings
		},
		
		data() {
			return {
				currentLeague: this.league ? this.league : 1204,
				loading: true,
			}
		},
		
		created() {
			this.$emit('update', this.currentLeague, this.afterLoading)
		},

		methods: {
			changeLeague() {
				this.loading = true;
				
				this.$emit('update', this.currentLeague, this.afterLoading)
			},
			
			afterLoading() {
				this.loading = false;
			}
		}
	}
</script>

<style scoped>

</style>