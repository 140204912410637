<template>
	<div class="match">
		<div class="wrapper" v-if="match">
			<div class="match_row">
				<div class="match_right">
					<div class="tab">
						<div class="live-command">
							<div class="live-command_info">
								{{ getDateTime(match.date, match.time) }}
							</div>
							
							<div class="live-command_row">
								<div  class="live-command_image">
									<span :class="{__bold: match.localteam.winner === 'True'}">{{ match.localteam.name }}</span>
								</div>
								
								<div class="live-command_content">
									<div class="live-command_res">
										<div>{{ match.status }}</div>
									</div>
								</div>
								
								<div class="live-command_image">
									<span :class="{__bold: match.awayteam.winner === 'True'}">{{ match.awayteam.name }}</span>
								</div>
							</div>
						</div>
						
						<div class="match-block" v-if="windowWidth > 860">
							<div class="match-block_row">
								<div class="match-block_left">
									<statistic :data="match.stats"></statistic>
								</div>
								<div class="match-block_right">
									<div class="timeline_text">
										<div>
											<span>Победитель:</span>
											<strong>{{ winner.name }}</strong>
										</div>
										<div v-if="match.win_result">
											<span>Раунд:</span>
											<strong>{{ match.win_result.won_by.round }}</strong>
										</div>
										<div v-if="match.win_result">
											<span>Тип победы:</span>
											<strong>{{ getType(match.win_result.won_by.type) }}</strong>
											<span v-if="match.win_result.won_by.ko.type">
											({{ getType(match.win_result.won_by.ko.type) }})
										</span>
											<span v-else-if="match.win_result.won_by.sub.type">
											({{ getType(match.win_result.won_by.sub.type) }})
										</span>
										</div>
										<div v-if="match.win_result">
											<span>Время победы:</span>
											<strong>{{ match.win_result.won_by.minute }}</strong>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						<tab v-else :headings="{timeline: 'Обзор', stats: 'Статистика'}" active-key="timeline">
							<template v-slot:timeline>
								<div class="timeline_text">
									<div>
										<span>Победитель:</span> 
										<strong>{{ winner.name }}</strong>
									</div>
									<div v-if="match.win_result">
										<span>Раунд:</span>
										<strong>{{ match.win_result.won_by.round }}</strong>
									</div>
									<div v-if="match.win_result">
										<span>Тип победы:</span> 
										<strong>{{ getType(match.win_result.won_by.type) }}</strong>
										<span v-if="match.win_result.won_by.ko.type">
											({{ getType(match.win_result.won_by.ko.type) }})
										</span>
										<span v-else-if="match.win_result.won_by.sub.type">
											({{ getType(match.win_result.won_by.sub.type) }})
										</span>
									</div>
									<div v-if="match.win_result">
										<span>Время победы:</span>
										<strong>{{ match.win_result.won_by.minute }}</strong>
									</div>
								</div>
							</template>
							<template v-slot:stats>
								<statistic :data="match.stats"></statistic>
							</template>
						</tab>
					</div>
				</div>
			</div>
		</div>
		
		<div v-else-if="loading" class="info-text"></div>
		
		<div class="wrapper" v-else>
			<div class="info __pt0">Информация обновляется</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import Tab from "../../../Tab/Tab";
	import Statistic from "../Components/Statistic";
	import $ from "jquery";
	
	export default {
		components: {
			Statistic,
			Tab
		},
		
		data() {
			return {
				id: this.$route.params.id,
				match: null,
				loading: true,
				windowWidth: $(window).width(),
			}
		},
		
		created() {
			this.axios.get('/goalserve/mma/live?id=' + this.id)
				.then(({data}) => {
					this.match = data.res ? data.res.match[0] : null;
					this.loading  = false;
				});

			$(window).on('resize', () => {
				this.windowWidth = $(window).width()
			});
		},
		
		computed: {
			winner() {
				if (this.match.localteam.winner === 'True') {
					return this.match.localteam;
				} else if (this.match.awayteam.winner === 'True') {
					return this.match.awayteam;
				}
				return {}
			}
		},
		
		methods: {
			getDateTime(date, time) {
				let method = getTimeZoneNum() >= 0 ? 'add' : 'subtract';
				
				date = date.split('.');
				date = date[2] + '-' + date[1] + '-'+ date[0];
				
				return moment(date + 'T' + time)[method](getTimeZoneNum(), 'hours').format('DD.MM.Y HH:mm');
			},
			getType(type) {
				var obj = {
					'S Dec': 'Судейское решение',
					'Points': 'Судейское решение',
					'SUB': 'Добровольная сдача',
					'KO': 'Нокаут',
					'ТКО': 'Нокаут',
					'Guillotine Choke': 'Удушение Гильотиной',
					'Arm Triangle': 'Треугольник',
					'Rear Naked Choke': 'Удушение сзади',
					'Kimura': 'Кимура',
					'Punches': 'Удар в голову',
				};
				
				return obj[type] ? obj[type] : type;
			}
		}
	}
</script>