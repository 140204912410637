<template>
	<div>
		<div class="heading">Матчи дня</div>
		
		<div v-if="matchesData || matchesDataTomorrow || matchesDataYesterday">
			<tab :headings="titles" :active-key="matchTabKey" :class-names="['__col-3']" :show-hidden="showHidden">
				<template v-slot:yesterday>
					<div class="live-match">
						<list :hide-actions="true"  v-if="matchesDataYesterday" :data="matchesDataYesterday" :day-diff="-1"></list>
					</div>
				</template>
				<template v-slot:today>
					<div class="live-match">
						<list :hide-actions="true" v-if="matchesData" :data="matchesData" :day-diff="0"></list>
					</div>
				</template>
				<template v-slot:tomorrow>
					<div class="live-match">
						<list :hide-actions="true" v-if="matchesDataTomorrow" :data="matchesDataTomorrow" :day-diff="1"></list>
					</div>
				</template>
				<template v-slot:live>
					<div class="live-match">
						<list :hide-actions="true" v-if="matchesDataLive" :data="matchesDataLive" :day-diff="0"></list>
					</div>
				</template>
			</tab>
			
			<div class="button-block">
				<router-link :to="{name: 'live', params: {sport: 'soccer'}}" class="button">Показать все матчи</router-link>
			</div>
		</div>
		<div v-else class="info">Нет данных <br><br></div>
	</div>
</template>

<script>
	import Tab from "../Tab/Tab";
	import List from "../Events/List";
	
	export default {
		props: ['matchesData', 'matchesDataTomorrow', 'matchesDataYesterday', 'matchTabKey', 'showHidden', 'hideActions'],
		
		components: {
			Tab,
			List
		},
		
		data() {
			return {
				loading: true,
				titles: {},
				matchesDataLive: null
			}
		},
		
		created() {
			var arr = JSON.parse(JSON.stringify(this.matchesData));

			this.matchesDataLive = arr.filter((league) => {
				league.events = league.events.filter((match) => {
					return match.timer || match.status === 'HT';
				});
				return league.events.length;
			});
			
			this.titles.yesterday = 'Вчера (' + this.getCount(this.matchesDataYesterday) + ')';
			this.titles.today = 'Сегодня (' + this.getCount(this.matchesData) + ')';
			this.titles.tomorrow = 'Завтра (' + this.getCount(this.matchesDataTomorrow) + ')';
			this.titles.live = 'LIVE (' + this.getCount(this.matchesDataLive) + ')';
		},

		methods: {
			getCount(matches) {
				if (!matches) return 0;
				
				let count = 0;
				
				matches.forEach((data) => {
					count += data.events.length;
				});
				
				return count;
			}
		},
	}
</script>

<style scoped>

</style>