<template>
	<div class="news-slider-container">
		<slick class="news-slider" :options="newsSliderOptions" v-if="articles">
			<div v-for="article in articles" :key="article.id">
				<router-link :to="{name: 'article', params: {id: article.id}}" class="news-slider_item">
					<img :src="article.image_ref" alt="">
					<div class="news-slider_title">
						<p>{{ article.title }}</p>
					</div>
				</router-link>
			</div>
		</slick>
	</div>
</template>

<script>
	import Slick from 'vue-slick';
	
	export default {
		props: ['articles'],
		
		components: {
			Slick
		},
		
		data() {
			return {
				newsSliderOptions: {
					slidesToShow: 3,
					slidesToScroll: 1,
					autoplay: true,
					autoplaySpeed: 5000,
					pauseOnHover: false,
					arrows: false,
					dots: true,
					infinite: true,

					responsive: [
						{
							breakpoint: 767,
							settings: {
								slidesToShow: 1
							}
						}
					]
				}
			}
		}
	}
</script>

<style scoped>

</style>