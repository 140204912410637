<template>
	<div>
		<div class="rank_anno">
			<div class="rank-annotation ykmeqvo" style="margin:15px 0;">
				<div class="rank-annotation__group wmpx">
					<strong>Уважаемые пользователи!</strong>
					<br><br>
					
					В данном разделе вы можете найти актуальную и свежую информацию о трансферах наиболее популярных лиг и игроков.
					<br><br>
					
					Футбольные трансферы — период, специально отведенный для покупки и продажи игроков
					<br><br>
					Трансферы могут осуществляться в период летнего или зимнего межсезонья. Во всех европейских странах летнее трансферное окно открыто с 1 июля до 31 августа, а зимнее – с 1 до 31 января.
				</div>
			</div>
		</div>
		
		<div v-if="competitions" class="competitions_title">Чемпионат</div>
		
		<div v-if="competitions" class="competitions">
			<div class="competitions_item" v-for="competition in competitions.defaultCompetitions" :key="competition.id" :class="{active: competition.id === competitionsId}">
				<router-link :to="{name: 'competition', params: {id: competition.id}}">
					<img :src="competition.image" alt="">
					<span>{{ competition.title }}</span>
				</router-link>
			</div>
		</div>
		<div class="info-text" v-else></div>
		
		<div v-if="clubs" class="clubs_title">Команда</div>
		
		<div v-if="clubs" class="clubs">
			<div class="clubs_item" v-for="club in clubs.clubs" :key="club.id" :class="{active: club.id === clubId}">
				<router-link :to="{name: 'transfers', params: {competition: competitionsId, club: club.id}}">
					<div class="clubs_image">
						<img :src="club.image" alt="">
					</div>
					<span>{{ club.name }}</span>
				</router-link>
			</div>
		</div>
		<div class="info-text" v-else-if="clubLoading"></div>
		
		<div v-if="transfers && clubs && competitions" class="transfers">
			<div class="transfers_title">Пришли</div>
			
			<table class="transfers_table">
				<thead>
					<tr>
						<th>Пришли</th>
						<th>Возраст</th>
						<th>Нац.</th>
						<th>Амплуа</th>
						<th>Уходит из</th>
						<th>Пришел в</th>
						<th>Сумма компенсации</th>
					</tr>
				</thead>
				
				<tbody>
					<tr v-for="(item, key) in transfers.currentSeason.transferArrivals" :key="key">
						<td>
							<div class="transfers_table-row">
								<div class="transfers_table-col">Игрок</div>
								<div class="transfers_table-col">
									<div class="transfers_player">
										<img :src="item.playerImage" alt="">
										<span>{{ item.playerName }}</span>
									</div>
								</div>
							</div>
						</td>
						<td>
							<div class="transfers_table-row">
								<div class="transfers_table-col">Возраст</div>
								<div class="transfers_table-col">
									{{ item.age }}
								</div>
							</div>
						</td>
						<td>
							<div class="transfers_table-row">
								<div class="transfers_table-col">Нац.</div>
								<div class="transfers_table-col">
									<img class="transfers_flag" :src="item.countryImage" alt="">
								</div>
							</div>
						</td>
						<td>
							<div class="transfers_table-row">
								<div class="transfers_table-col">Амплуа</div>
								<div class="transfers_table-col">
									{{ getAmplua(item.position) }}
								</div>
							</div>
						</td>
						<td>
							<div class="transfers_table-row">
								<div class="transfers_table-col">Уходит из</div>
								<div class="transfers_table-col">
									<div class="transfers_club">
										<div class="transfers_arrow __red"></div>
										<img width="20" :src="item.clubImage" alt="">
										<span v-text="item.clubName"></span>
									</div>
								</div>
							</div>
						</td>
						<td>
							<div class="transfers_table-row">
								<div class="transfers_table-col">Пришел в</div>
								<div class="transfers_table-col">
									<div class="transfers_club">
										<div class="transfers_arrow __green"></div>
										<img width="20" :src="command.image" alt="">
										<span v-text="command.name"></span>
									</div>
								</div>
							</div>
						</td>
						<td>
							<div class="transfers_table-row">
								<div class="transfers_table-col">Сумма компенсации</div>
								<div class="transfers_table-col">
									<span v-if="item.transferFee === '-'">Окончание аренды {{ item.date }}</span>
									<span v-else-if="item.transferFee === 'ablöse- frei'">Свободный агент</span>
									<span v-else>{{ item.transferFeeUnformatted ? item.transferFeeUnformatted.toLocaleString(undefined, { minimumFractionDigits: 0 }) : ''}} {{ item.transferFeeCurrency}}</span>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			
			<br><br>
			
			<div class="transfers_title">Ушли</div>
			
			<table class="transfers_table">
				<thead>
					<tr>
						<th>Ушли</th>
						<th>Возраст</th>
						<th>Нац.</th>
						<th>Амплуа</th>
						<th>Уходит из</th>
						<th>Переходит в</th>
						<th>Сумма компенсации</th>
					</tr>
				</thead>
				
				<tbody>
					<tr v-for="(item, key) in transfers.currentSeason.transferDepartures" :key="key">
						<td>
							<div class="transfers_table-row">
								<div class="transfers_table-col">Игрок</div>
								<div class="transfers_table-col">
									<div class="transfers_player">
										<img :src="item.playerImage" alt="">
										<span>{{ item.playerName }}</span>
									</div>
								</div>
							</div>
						</td>
						<td>
							<div class="transfers_table-row">
								<div class="transfers_table-col">Возраст</div>
								<div class="transfers_table-col">
									{{ item.age }}
								</div>
							</div>
						</td>
						<td>
							<div class="transfers_table-row">
								<div class="transfers_table-col">Нац.</div>
								<div class="transfers_table-col">
									<img class="transfers_flag" :src="item.countryImage" alt="">
								</div>
							</div>
						</td>
						<td>
							<div class="transfers_table-row">
								<div class="transfers_table-col">Амплуа</div>
								<div class="transfers_table-col">
									{{ getAmplua(item.position) }}
								</div>
							</div>
						</td>
						<td>
							<div class="transfers_table-row">
								<div class="transfers_table-col">Уходит из</div>
								<div class="transfers_table-col">
									<div class="transfers_club">
										<div class="transfers_arrow __red"></div>
										<img width="20" :src="command.image" alt="">
										<span v-text="command.name"></span>
									</div>
								</div>
							</div>
						</td>
						<td>
							<div class="transfers_table-row">
								<div class="transfers_table-col">Переходит в</div>
								<div class="transfers_table-col">
									<div class="transfers_club">
										<div class="transfers_arrow __green"></div>
										<img width="20" :src="item.clubImage" alt="">
										<span v-text="item.clubName"></span>
									</div>
								</div>
							</div>
						</td>
						<td>
							<div class="transfers_table-row">
								<div class="transfers_table-col">Сумма компенсации</div>
								<div class="transfers_table-col">
									<span v-if="item.transferFee === '-'">Окончание аренды {{ item.date }}</span>
									<span v-else-if="item.transferFee === 'ablöse- frei'">Свободный агент</span>
									<span v-else>{{ item.transferFeeUnformatted ? item.transferFeeUnformatted.toLocaleString(undefined, { minimumFractionDigits: 0 }) : ''}} {{ item.transferFeeCurrency}}</span>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="info-text" v-else-if="transfersLoading"></div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				competitionsId: this.$route.params.id || null,
				clubId: this.$route.params.club || null,
				competitions: null,
				clubs: null,
				transfers: null,
				loading: true,
				clubLoading: false,
				transfersLoading: false,
				command: null
			}
		},
		
		created() {
			if (this.getCache('competitions')) {
				this.competitions = JSON.parse(this.getCache('competitions'));
				
				this.initClubs();
				
				return;
			}
			
			this.axios.get('/goalserve/trapi/competitions/list-default')
				.then(({data}) => {
					this.competitions = data;
					
					this.setCache('competitions', JSON.stringify(this.competitions))
					
					this.initClubs();
				})
		},
		
		mounted() {
			document.title = 'Трансферы' + window.title;
		},

		methods: {
			initClubs() {
				if (!this.competitionsId) return;

				this.clubLoading = true;

				if (this.getCache('clubs.' + this.competitionsId)) {
					this.clubs = JSON.parse(this.getCache('clubs.' + this.competitionsId));

					this.initCommand();

					this.clubLoading = false;

					this.initTransfers();
					
					return;
				}

				this.axios.get('/goalserve/trapi/clubs/list-by-competition?id=' + this.competitionsId)
					.then(({data}) => {
						this.clubs = data;

						this.setCache('clubs.' + this.competitionsId, JSON.stringify(this.clubs))
	
						this.initCommand();
	
						this.clubLoading = false;
	
						this.initTransfers();
					});
			},
			initTransfers() {
				//return;
				if (!this.clubId) return;
				
				this.transfersLoading = true;

				this.axios.get('/goalserve/trapi/transfers/list-by-club?id=' + this.clubId + '&seasonID=2020')
					.then(({data}) => {
						this.transfers = data;
	
						this.transfersLoading = false;
					})
			},
			initCommand() {
				this.clubs.clubs.forEach((club) => {
					if (club.id == this.clubId) {
						this.command = club;
					}
				})
			},
			
			getCache(type) {
				return localStorage.getItem(type);
			},
			setCache(type, value) {
				localStorage.setItem(type, value)
			},
			getAmplua(name) {
				let data = {
					'Abwehr' : 'Защитник',
					'Sturm' : 'Нападающий',
					'Mittelfeld' : 'Полузащитник',
					'Torwart' : 'Вратарь'
				};
				
				return data[name];
			}
		}
	}
</script>

<style scoped>

</style>