<template>
	<div>
		<div class="rank">
			<div class="wrapper">
				<div class="rank_content">
					<div class="rank_image">
						<img src="/img/uefa.png" alt="">
					</div>
					<div class="rank_data">
						<h3>Рейтинг УЕФА</h3>
						<p>2020/2021</p>
					</div>
				</div>
				
				<tab :headings="{rank: 'Рейтинг стран', coef: 'Коэффициенты стран', rankClub: 'Рейтинг клубов', coefClub: 'Коэффициенты клубов'}" active-key="rank">
					<template v-slot:rank>
						<div class="rank_anno">
							<div class="rank-annotation" style="margin:15px 0;">
								<div class="rank-annotation__group">
									<p><strong>Клубный рейтинг коэффициентов для страны считается на основании результатов всех клубов страны в Лиге чемпионов УЕФА и Лиге Европы УЕФА за последние пять сезонов. Рейтинг определяет количество мест для каждой страны в будущих клубных соревнованиях УЕФА.</strong></p>
									<br>
									<p><strong>Рейтинг в отдельном сезоне считается на основании результатов всех клубов страны в текущем розыгрыше Лиги чемпионов УЕФА и Лиги Европы УЕФА.</strong></p>
									<br>
									<p><strong>Рейтинг обновляется после каждого раунда клубных соревнований УЕФА.</strong></p>
								</div>
								<div class="rank-annotation__group">
									Суммарный рейтинг страны определяется как сумма коэффициентов страны за последние 5 сезонов.
									В графе 'Команды' указано количество клубов - стартовавших и оставшихся на текущий момент в еврокубках.
									Если от страны выбыли все клубы - указано только количество стартовавших.
								</div>
								
								<div class="rank-annotation__group">
									<span class="table-bullet zexjmuyr _green"></span>
									Страны, которые сохранили всех своих представителей в текущем сезоне
								</div>
								
								<div class="rank-annotation__group">
									<span class="mwkudf _yellow table-bullet"></span>
									Частичное представительство страны
								</div>
								
								<div class="rank-annotation__group">
									<span style="display: inline-block;color: #9299a0;font-weight: bold;font-size: 9px;line-height: 9px;vertical-align: middle;margin: 0 4px;" class="cgika">ЛЧ<br>ЛЕ</span>
									Итоговое место в этом рейтинге определяет представительство страны в еврокубках спустя 1 сезон
								</div>
							</div>
						</div>
						
						<div class="rank_table" v-html="rank" v-if="rank"></div>
					</template>
					<template v-slot:coef>
						<div class="rank_anno">
							<div class="rank-annotation ykmeqvo" style="margin:15px 0;">
								<div class="rank-annotation__group wmpx">
									В таблице приводится детальный подсчет коэффициента стран, который равен сумме очков, набранных всеми клубами страны, поделенной на количество клубов от этой страны. Сумма коэффициентов за последние 5 лет определяет рейтинг страны.
								</div>
								
								<div class="nmxs rank-annotation__group">
									За победу на основных этапах начисляется 2 очка в рейтинг клуба, за ничью — 1 очко, за поражение — 0 очков. Предварительные этапы оцениваются следующим образом: победа — 1 очко, ничья — 0,5 очка, поражение — 0 очков.
								</div>
								
								<div class="wtjuk rank-annotation__group">
									Бонусные очки начисляются за выход в групповой этап Лиги чемпионов (с 2009 года - 4 очка, ранее - 3 очка), за выход в первый раунд плей-офф (1/8 финала) Лиги чемпионов (с 2009 года - 5 очков, ранее - 1 очко), за выход в стадии 1/4 финала, 1/2 финала и финал Лиги чемпионов и Лиги Европы (по 1 очку).
								</div>
								
								<div class="wcrdy rank-annotation__group">
									Для текущего сезона указано, в каком турнире клуб выступает на данный момент.
								</div>
							</div>
						</div>
						<div class="rank_table" v-html="coef" v-if="coef"></div>
					</template>
					<template v-slot:rankClub>
						<div class="rank_anno">
							<div class="ywbdo rank-annotation" style="margin:15px 0;">
								<div class="tcdu rank-annotation__group">
									<p><strong>Клубные коэффициенты считаются на основании результатов клубов в Лиге чемпионов УЕФА и Лиге Европы УЕФА за последние пять сезонов. Рейтинг определяет посев клубов при жеребьевке в соревнованиях под эгидой УЕФА.</strong></p>
									<br>
									<p><strong>Клубные коэффициенты в отдельном сезоне считаются на основании результатов клубов в текущем розыгрыше Лиги чемпионов УЕФА и Лиги Европы УЕФА.</strong></p>
									<br>
									<p><strong>Таблица обновляется после каждого раунда клубных турниров УЕФА.</strong></p>
								</div>
								<div class="tcdu rank-annotation__group">
									Суммарный рейтинг клубов определяется как максимум из суммы коэффициентов клуба и 20% от коэффициента страны за последние 5 сезонов. Рейтинг клуба используется для посева во время жеребьевки еврокубков в следующем сезоне.
								</div>
								
								<div class="hgowgx rank-annotation__group">
									Синей полосой выделен минимально возможный рейтинг для любого клуба указанной страны.
								</div>
								
								<div class="rank-annotation__group yels">
									<span class="nvoefk table-bullet _green"></span>
									Клуб продолжает выступления в еврокубках в текущем сезоне.
								</div>
								
								<div class="rank-annotation__group zlzbzekv">
									<span class="_yellow table-bullet cpsa"></span>
									Клуб прекратил выступление на еврокубках, но его рейтинг может увеличиться за счет увеличения рейтинга страны другими клубами.
								</div>
							</div>
						</div>
						
						<div class="rank_table" v-html="rankClub" v-if="rankClub"></div>
					</template>
					<template v-slot:coefClub>
						<div class="rank_anno">
							<div class="rank-annotation xlzyhpy" style="margin:15px 0;">
								<div class="fhrs rank-annotation__group">
									В таблице приводится детальный подсчет коэффициента клубов, который равен сумме очков, набранных в основных раундах еврокубков. До 2017 года в коэффициенте учитывалось 20% от коэффициента страны (до 2009 года - 33%, до 2004 года - 50%). Очки, набранные клубом в предварительных раундах и учитываемые в коэффициентах стран, здесь в расчёт не принимаются. Но с сезона 2009/10, в зависимости от достигнутого раунда, клубы премируются определёнными квалификационными бонусами, идущими в зачёт коэффициента клуба.
								</div>
								
								<div class="rank-annotation__group gyyv">
									Серой полосой выделен минимально возможный коэффициент для любого клуба указанной страны в заданном сезоне.
								</div>
								
								<div class="ksnye rank-annotation__group">
									<span class="table-bullet yjhe _green"></span>
									Клуб продолжает выступления в еврокубках в текущем сезоне.
								</div>
								
								<div class="rank-annotation__group mdkeq">
									<span class="_yellow table-bullet blpe"></span>
									Клуб прекратил выступление на еврокубках, но его коэффициент может увеличиться за счет увеличения коэффициента страны другими клубами.
								</div>
								
								<div class="rank-annotation__group jjqvnvg">
									Желтым цветом выделен турнир, в котором клуб выступает в настоящий момент. По ссылке в графе 'Очки' соответствующего турнира можно перейти в профиль выступления клуба в данном турнире.
								</div>
							</div>
						</div>
						
						<div class="rank_table" v-html="coefClub" v-if="coefClub"></div>
					</template>
				</tab>
			</div>
		</div>
	</div>
</template>

<script>
	import Tab from "../components/Tab/Tab";
	
	export default {
		components: {
			Tab
		},
		
		data() {
			return {
				rank: null,
				coef: null,
				rankClub: null,
				coefClub: null,
			}
		},
		
		mounted() {
			document.title = 'Рейтинг УЕФА' + window.title;
		},

		created() {
			this.axios({url: '/public/rank.txt'})
				.then(({data}) => {
					this.rank = data;
				});
			this.axios({url: '/public/coef.txt'})
				.then(({data}) => {
					this.coef = data;
				});
			this.axios({url: '/public/rank-club.txt'})
				.then(({data}) => {
					this.rankClub = data;
				});
			this.axios({url: '/public/coef-club.txt'})
				.then(({data}) => {
					this.coefClub = data;
				});
		}
	}
</script>

<style scoped>

</style>