<template>
	<div>
		<soccer v-if="sport === 'soccer'"></soccer>
		<hockey v-if="sport === 'hockey'"></hockey>
		<basketball v-if="sport === 'bsktbl'"></basketball>
	</div>
</template>

<script>
	import Soccer from "./Sports/Soccer/Match";
	import Hockey from "../components/Sports/Hockey/Pages/MatchPage";
	import Basketball from "../components/Sports/Basketball/Pages/MatchPage";

	export default {
		components: {
			Soccer,
			Hockey,
			Basketball
		},
		data() {
			return {
				sport: this.$route.params.sport
			}
		}
	}
</script>

<style scoped>

</style>