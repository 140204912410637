<template>
	<div>
		<div class="rank_anno">
			<div class="rank-annotation ykmeqvo" style="margin:15px 0;">
				<div class="rank-annotation__group wmpx">
					<strong>Уважаемые пользователи!</strong>
					<br><br>
					
					В данном разделе мы собрали для вас лучшие обзоры и хайлайты футбольных матчей ведущих чемпионатов мира. А также к просмотру для вас доступен и другой интересный видео контент. Хорошего просмотра.
				</div>
			</div>
		</div>
		
		<div class="videos">
			<tab :headings="{soccer: 'Футбол', sport: 'Спорт'}" active-key="soccer">
				<template v-slot:soccer>
					<div>
						<sport-recs-videos :ids="'UnllLtsRHADj243wEe55Qw'"></sport-recs-videos>
					</div>
				</template>
				<template v-slot:sport>
					<div>
						<sport-recs-videos :ids="sportIds"></sport-recs-videos>
					</div>
				</template>
			</tab>
		</div>
	</div>
</template>

<script>
	import Tab from "../components/Tab/Tab";
	import SportRecsVideos from "../components/Videos/SportRecsVideos";
	import Euro2020Videos from "../components/Videos/Euro2020";

	export default {
		components: {
			Tab,
			SportRecsVideos,
			Euro2020Videos
		},
		data() {
			return {
				sportIds: [
					'2ZWIw1_NaCAB1zbscHUvwg',
					'l8QTv94JEwe7h1_8MUxD8w',
					'TtEyAYQB28XPsLm3ojke6Q',
					'CSczGWku-yQAK9WBWZry1A',
					'ESyTKnI9nkb8e2EtmDuWSw',
					'6Aot7tssFzobT6kBw7SRNw',
				]
			}
		},

		mounted() {
			document.title = 'Видео' + window.title;
		}
	}
</script>

<style scoped>

</style>