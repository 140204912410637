<template>
	<div>
		<soccer v-if="sport === 'soccer'"></soccer>
		<tennis v-if="sport === 'tennis'"></tennis>
		<hockey v-if="sport === 'hockey'"></hockey>
		<basketball v-if="sport === 'bsktbl'"></basketball>
		<mma v-if="sport === 'mma'"></mma>
		<box v-if="sport === 'box'"></box>
	</div>
</template>

<script>
	import Soccer from "./Sports/Soccer/Soccer";
	import Tennis from "../components/Sports/Tennis/Pages/LiveSportPage";
	import Hockey from "../components/Sports/Hockey/Pages/LiveSportPage";
	import Basketball from "../components/Sports/Basketball/Pages/LiveSportPage";
	import Mma from "../components/Sports/Mma/Pages/LiveSportPage";
	import Box from "../components/Sports/Box/Pages/LiveSportPage";

	export default {
		components: {
			Soccer,
			Tennis,
			Hockey,
			Basketball,
			Mma,
			Box
		},
		data() {
			return {
				sport: this.$route.params.sport
			}
		}
	}
</script>

<style scoped>

</style>