<template>
	<div>
		<soccer v-if="sport === 'soccer'"></soccer>
		<basketball v-if="sport === 'bsktbl'"></basketball>
	</div>
</template>

<script>
	import Soccer from "./Sports/Soccer/Team";
	import Basketball from "../components/Sports/Basketball/Pages/TeamPage";
	
	export default {
		components: {
			Soccer,
			Basketball
		},
		
		data() {
			return {
				sport: this.$route.params.sport
			}
		},
		
	}
</script>