<template>
	<!-- eslint-disable -->
	
	<div class="res">
		<div class="wrapper">
			<div class="res_row">
				<div class="res_left">
					<leagues v-if="leagues" sport="soccer" :data="leagues"></leagues>
				</div>
				
				<div class="res_right" v-if="team">
					<div class="team">
						<div class="team_main">
							<div class="team_image">
								<img :src="'data:image/png;base64, ' + team.image" alt="">
							</div>
							<div class="team_name">{{ team.name }}</div>
						</div>
						
						<tab :headings="{about: 'О команде', results: 'Результаты', players: 'Состав', schedule: 'Календарь'}" active-key="about">
							<template v-slot:about>
								<div class="command-card_about">
									<table class="command-card_table">
										<tbody>
											<tr>
												<td>Страна</td>
												<td>
													<strong>{{ team.country }}</strong>
												</td>
											</tr>
											<tr>
												<td>Год основания</td>
												<td>
													<strong>{{ team.founded }}</strong>
												</td>
											</tr>
											<tr>
												<td>Место в лиге</td>
												<td>
													<strong>{{ team.leagues.league_rank }}</strong>
												</td>
											</tr>
											<tr>
												<td>Стадион</td>
												<td>
													<strong>{{ team.venue_city["#cdata-section"] }} - {{ team.venue_address["#cdata-section"] }}</strong>
													<br><br>
													<img :src="'data:image/png;base64, ' + team.venue_image" alt="">
												</td>
											</tr>
											<tr>
												<td>Вмещаемость стадиона</td>
												<td>
													<strong>{{ team.venue_capacity }}</strong>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</template>
							
							<template v-slot:players>
								<players :team="team" :players="players"></players>
							</template>
							
							<template v-slot:results>
								<links :show-date="true" v-if="results.length && !resultLoading" :sport="sport" :data="res"></links>
								<div class="info-text" v-else-if="resultLoading"></div>
								<div v-else class="info">Нет данных</div>
							</template>
							
							<template v-slot:schedule>
								<links :show-date="true" v-if="schedules.length && !scheduleLoading" :sport="sport" :data="schedule"></links>
								<div class="info-text" v-else-if="scheduleLoading"></div>
								<div v-else class="info">Нет данных</div>
							</template>
						</tab>
					</div>
				</div>
				
				<div class="info-text" v-if="loading"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import _ from 'underscore';

	import Leagues from "@/components/Sports/Common/Leagues";
	import Tab from "@/components/Tab/Tab";
	import Players from "@/components/Sports/Soccer/Components/Team/Players";
	import Links from "@/components/Sports/Soccer/Components/Events/Links";
	import moment from "moment";

	export default {
		components: {
			Leagues,
			Tab,
			Players,
			Links,
		},

		data() {
			return {
				id: this.$route.params.id,
				leagues: null,
				team: null,
				sport: this.$route.params.sport,
				results: [],
				schedules: [],
				loading: true,
				resultLoading: true,
				leaguesLength: 0,
				resultCount: 0,
				scheduleCount: 0,
				scheduleLoading: true,
			}
		},

		computed: {
			players() {
				if (!this.team.squad.player) {
					return null
				}
				return _.groupBy(this.team.squad.player, (item) => {
					return item.position;
				});
			},
			transfers() {
				return this.team.transfers || null;
			},

			res() {
				let data = [];

				if (this.results.length) {
					data = JSON.parse(JSON.stringify(this.results)).reverse();

					data.forEach((item, index) => {
						if (item && item.length) {
							item = item.reverse();
						}
					});
				}

				let newData = [
					{
						group_suffix: 'Результаты',
						events: []
					}
				];

				data.forEach((result, index) => {
					if (result) {
						result.forEach((item, index) => {
							if (item) {
								item.events.forEach((event) => {
									newData[0].events.push(event);
								});
							}
						});
					}
				});

				newData[0].events = newData[0].events.sort((a, b) => {
					let eventADate = a.date.split('.');
					let eventBDate = b.date.split('.');

					eventADate = eventADate[2] + '-' + eventADate[1] + '-'+ eventADate[0];
					eventBDate = eventBDate[2] + '-' + eventBDate[1] + '-'+ eventBDate[0];

					if (moment(eventBDate).isAfter(eventADate)) {
						return 1;
					}
					return -1;
				});

				return newData;
			},
			schedule() {
				let data = [];

				if (this.schedules.length) {
					data = JSON.parse(JSON.stringify(this.schedules));
				}

				let newData = [
					{
						group_suffix: 'Календарь',
						events: []
					}
				];

				data.forEach((result, index) => {
					if (result) {
						result.forEach((item, index) => {
							if (item) {
								item.events.forEach((event) => {
									newData[0].events.push(event);
								});
							}
						});
					}
				});

				newData[0].events = newData[0].events.filter((match) => {
					return match.status !== 'Postp.';
				});

				newData[0].events = newData[0].events.sort((a, b) => {
					let eventADate = a.date.split('.');
					let eventBDate = b.date.split('.');

					eventADate = eventADate[2] + '-' + eventADate[1] + '-'+ eventADate[0];
					eventBDate = eventBDate[2] + '-' + eventBDate[1] + '-'+ eventBDate[0];

					if (moment(eventADate).isAfter(eventBDate)) {
						return 1;
					}
					return -1;
				});

				return newData;
			}
		},

		created() {
			this.initLeagues();
			this.initTeam();
		},

		methods: {
			initLeagues() {
				this.axios.get('/goalserve/soccer/league')
				.then(({data}) => {
					this.leagues = data;
				});
			},
			initTeam() {
				this.axios.get('/goalserve/soccer/team_info/' + this.id)
				.then(({data}) => {
					this.team = data.res;
					this.loading = false;
					let leagues = this.team.leagues.league_id;

					document.title = this.team.name + window.title;

					if (leagues) {
						if (!Array.isArray(leagues)) {
							leagues = [leagues];
						}

						this.leaguesLength = leagues.length;

						leagues.forEach((league) => {
							this.initResults(league);
							this.initSchedule(league);
						});
					}
				})
				.catch(() => {
					this.loading = false;
				});
			},
			initResults(league) {
				this.axios.get('/goalserve/soccer/league/soccerfixtures?status=finished&team_id=' + this.id + '&league_id=' + league)
				.then(({data}) => {
					this.resultCount++;

					if (this.leaguesLength === this.resultCount) {
						this.resultLoading = false;
					}

					if (data.res) {
						this.results.push(data.res);
					}
				});
			},
			initSchedule(league) {
				this.axios.get('/goalserve/soccer/league/soccerfixtures?status=not_finished&team_id=' + this.id + '&league_id=' + league)
				.then(({data}) => {
					this.scheduleCount++;

					if (this.leaguesLength === this.scheduleCount) {
						this.scheduleLoading = false;
					}

					if (data.res) {
						this.schedules.push(data.res);
					}
				});
			},
		}
	}
</script>