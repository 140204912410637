<template>
	<div>
		<player-tennis v-if="sport === 'tennis'"></player-tennis>
		<player-soccer v-else></player-soccer>
	</div>
</template>

<script>
	import PlayerSoccer from "./Sports/Soccer/Player";
	import PlayerTennis from "../components/Sports/Tennis/Pages/PlayerPage";
	
	export default {
		components: {
			PlayerSoccer,
			PlayerTennis
		},
		data() {
			return {
				sport: this.$route.params.sport
			}
		}
	}
</script>

<style scoped>

</style>