<template>
	<div>
		<div class="heading">Форма</div>
		
		<div class="match-form">
			<div class="match-form_content">
				<div v-if="matchEvent" class="match-form_headings">
					<div class="match-form_logo __left">
						<img :src="$hostname + '/goalserve/soccer/team_image/' + matchEvent.home_id" alt="">
						<span>{{ matchEvent.home_name }}</span>
					</div>
					<div class="match-form_logo __right">
						<span>{{ matchEvent.away_name }}</span>
						<img :src="$hostname + '/goalserve/soccer/team_image/' + matchEvent.away_id" alt="">
					</div>
				</div>
				<div v-else-if="match" class="match-form_headings">
					<div class="match-form_logo __left">
						<img :src="$hostname + '/goalserve/soccer/team_image/' + home.id" alt="">
						<span>{{ home.name }}</span>
					</div>
					<div class="match-form_logo __right">
						<span>{{ away.name }}</span>
						<img :src="$hostname + '/goalserve/soccer/team_image/' + away.id" alt="">
					</div>
				</div>
				
				<div class="match-form_row">
					<div class="match-form_col">
						<div class="match-form_item">
							<div class="match-form_data" v-for="(result, index) in lastResults.team1" :key="index + '-team1'" :class="win(result.team1_score, result.team2_score, result).classes">
								{{ win(result.team1_score, result.team2_score, result).text }}
							</div>
						</div>
					</div>
					<div class="match-form_col">
						<div class="match-form_item">
							<div class="match-form_data" v-for="(result, index) in lastResults.team2" :key="index + '-team2'" :class="win(result.team1_score, result.team2_score, result).classes">
								{{ win(result.team1_score, result.team2_score, result).text }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['results', 'match', 'matchEvent'],

		computed: {
			home() {
				if (!this.match) {
					return null;
				}
				return this.match.localteam;
			},
			away() {
				if (!this.match) {
					return null;
				}
				return this.match.visitorteam;
			},
			lastResults() {
				let res = JSON.parse(JSON.stringify(this.results));

				let data = {
					team1: [],
					team2: []
				};

				for (let i = 0; i <= 4; i++) {
					res.last5_home.team1.match[i].home = true;
					data.team1.push(res.last5_home.team1.match[i]);

					res.last5_away.team1.match[i].away = true;
					data.team1.push(res.last5_away.team1.match[i]);


					res.last5_home.team2.match[i].home = true;
					data.team2.push(res.last5_home.team2.match[i]);

					res.last5_away.team2.match[i].away = true;
					data.team2.push(res.last5_away.team2.match[i]);
				}

				return this.sortResults(data);
			},
		},
		methods: {
			sortResults(data) {
				return {
					team1: data.team1.sort((a, b) => {
						return new Date(this.getDate(b.date)) - new Date(this.getDate(a.date));
					}).slice(0, 5),
					team2: data.team2.sort((a, b) => {
						return new Date(this.getDate(b.date)) - new Date(this.getDate(a.date));
					}).slice(0, 5),
				}
			},
			getDate(date) {
				date = date.split('.');
				return date[2] + '-' + date[1] + '-' + date[0];
			},
			win(team1, team2, result) {
				if (result.away) {
					let tmp = team1;

					team1 = team2;
					team2 = tmp;
				}

				team1 = parseInt(team1);
				team2 = parseInt(team2);

				if (team1 > team2) {
					return {
						text: 'В',
						classes: ['__win']
					};
				} else if (team1 === team2) {
					return {
						text: 'Н',
						classes: ['__draw']
					};
				}

				return {
					text: 'П',
					classes: ['__fail']
				};
			},
		}
	}
</script>

<style scoped>

</style>