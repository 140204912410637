<template>
	<div class="match-data_row">
		<div class="match-data_col-1 __red" v-if="match.timer">
			{{ match.timer }}'
		</div>
		<div class="match-data_col-1" v-else-if="showDate">{{ match.date }}</div>
		<div class="match-data_col-1 __red" v-else-if="match.status === 'HT'">Перерыв</div>
		<div class="match-data_col-1" v-else-if="match.status === 'Not Started'" v-text="getTime(match.date, match.time)"></div>
		<div class="match-data_col-1" v-else>{{ getStatus(match.status, match.date, match.time) }}</div>
		
		<div class="match-data_col-2" :class="{__bold: bold(match.home_score, match.away_score, match)}">
			<img :src="$hostname + '/goalserve/soccer/team_image/' + match.home_id" alt="">
			<span v-text="match.home_name"></span>
		</div>
		<div class="match-data_col-3" :class="{__red: match.timer, __bold: bold(match.home_score, match.away_score, match)}">
			{{ match.home_score !== '?' ? match.home_score: '' }}
		</div>
		<div class="match-data_col-4">-</div>
		<div class="match-data_col-5" :class="{__red: match.timer, __bold: bold(match.away_score, match.home_score, match)}">
			{{ match.away_score !== '?' ? match.away_score: '' }}
		</div>
		<div class="match-data_col-6" :class="{__bold: bold(match.away_score, match.home_score, match)}">
			<span v-text="match.away_name"></span>
			<img :src="$hostname + '/goalserve/soccer/team_image/' + match.away_id" alt="">
		</div>
	</div>
</template>

<script>
	import moment from 'moment'
	
	export default {
		props: ['match', 'showDate'],
		
		methods: {
			bold(team1, team2, match) {
				if (isNaN(parseInt(team1)) || isNaN(parseInt(team2)) || match.timer)
					return false;
				
				return parseInt(team1) > parseInt(team2);
			},
			getStatus(status, date, time) {
				if (this.activeDay) {
					date = this.activeDay.date;
				}
				
				let data = {
					'FT': 'Завершен',
					'Pen.': 'После с.п.',
					'AET': 'После д.в.',
					'Postp.': 'Перенесен',
					'TBA': 'Будет позднее',
				};
				
				return data[status] ? data[status] : this.getTime(date, time);
			},
			getTime(date, time) {
				let method = getTimeZoneNum() >= 0 ? 'add' : 'subtract';
				
				date = date.split('.');
				date = date[2] + '-' + date[1] + '-'+ date[0];
				
				return moment(date + 'T' + time)[method](getTimeZoneNum(), 'hours').format('HH:mm');
			}
		}
	}
</script>

<style scoped>

</style>