<template>
	<div class="res">
		<div class="wrapper">
			<div class="res_row">
				<div class="res_left">
					<rank-list></rank-list>
					
					<br>
				
					<leagues v-if="leagues" :data="leagues"></leagues>
				</div>
				
				<div class="res_right">
					<div class="tab">
						<match-header></match-header>
						
						<div class="rank-list">
							<div class="rank-list_row __head">
								<div class="rank-list_col __rank">#</div>
								<div class="rank-list_col __player">Имя</div>
								<div class="rank-list_col __country">Страна</div>
								<div class="rank-list_col __points">Очки</div>
							</div>
							<div v-if="ranks">
								<div class="rank-list_row" v-for="rankItem in ranks" :key="'rank-' + rankItem.id">
									<div class="rank-list_col __rank">
										<span>{{ rankItem.rank }}</span>
										<span class="rank-list_up" v-if="rankItem.movement === 'up'"></span>
										<span class="rank-list_down" v-if="rankItem.movement === 'down'"></span>
									</div>
									<div class="rank-list_col __player">{{ rankItem.name }}</div>
									<div class="rank-list_col __country">{{ rankItem.country }}</div>
									<div class="rank-list_col __points">{{ rankItem.points }}</div>
								</div>
							</div>
							<div class="info-text" v-else></div>
						</div>
						
						<div class="button-block">
							<button v-if="rankList && rankList.length > ranks.length" class="button" @click="page++">Показать еще</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import RankList from "../Components/Events/RankList";
	import MatchHeader from "../../Common/MatchHeader";
	import Leagues from "../Components/Events/Leagues";

	export default {
		components: {
			RankList,
			MatchHeader,
			Leagues
		},

		data() {
			return {
				sport: this.$route.params.sport,
				rankList: null,
				type: this.$route.params.type,
				page: 1,
				leagues: null
			}
		},

		created() {
			this.initRankList();
			this.initLeagues();
		},
		
		computed: {
			ranks() {
				if (!this.rankList) {
					return null;
				}
				return this.rankList.slice(0, this.page * 10);
			}
		},

		methods: {
			initLeagues() {
				this.axios.get('/goalserve/tennis/league')
					.then(({data}) => {
						this.leagues = data.res;
					});
			},
			initRankList() {
				this.axios.get('goalserve/tennis/scores/' + this.type)
					.then(({data}) => {
						this.rankList = data.res;
					});
			}
		}
	}
</script>

<style scoped>

</style>