<template>
	<div>
		<div class="res">
			<div class="wrapper">
				<div class="res_row">
					<div class="res_left">
						<rank-list></rank-list>
						
						<br>
						
						<leagues v-if="leagues" :data="leagues"></leagues>
					</div>
					
					<div class="res_right">
						<match-header></match-header>
						
						<list :data="matchesData" :loading="loading"></list>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment'


	import MatchHeader from '@/components/Sports/Common/MatchHeader';
	import RankList from "@/components/Sports/Tennis/Components/Events/RankList";
	import List from "@/components/Sports/Tennis/Components/Events/List";
	import Leagues from "@/components/Sports/Tennis/Components/Events/Leagues";

	export default {
		components: {
			RankList,
			MatchHeader,
			List,
			Leagues
		},

		data() {
			return {
				sport: this.$route.params.sport,
				matchesData: null,
				activeDay: {},
				loading: false,
				leagues: null
			}
		},

		created() {
			this.initLeagues();

			this.loading = true;
			this.update();

			events.$on('activeDay', this.initDay);
		},

		methods: {
			initLeagues() {
				this.axios.get('/goalserve/tennis/league')
				.then(({data}) => {
					this.leagues = data.res;
				});
			},
			initData() {
				let day = this.$route.query.day_diff || 0;
				let url = '/goalserve/' + this.sport + '/league/events';

				if (day !== 0) {
					url += '?day_diff=' + day;
				}

				this.axios.get(url)
				.then(({data}) => {
					this.matchesData = data.res;

					this.loading = false;
				});
			},
			initDay(day) {
				this.activeDay = day;
			},
			update() {
				if (this.sport !== this.$route.params.sport) return;

				this.initData();

				setTimeout(() => {
					this.update();
				}, 1000 * 60);
			}
		}
	}
</script>

<style scoped>

</style>