<template>
	<div>
		<div class="videos_row" v-if="videos.length">
			<div class="videos_col" v-for="video in videos" :key="video.id" @click="openVideo(video)">
				<div class="videos_item">
					<div class="videos_image">
						<img :src="getThumbnail(video.video.thumbnails)" alt="">
						<div class="videos_icon">
							<svg style="display: block; width: 60px; height: 60px;" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 60 60"><defs></defs><g filter="url(#filter0_d)"><path fill="#fff" fill-rule="evenodd" d="M30 48c11.046 0 20-8.954 20-20S41.046 8 30 8s-20 8.954-20 20 8.954 20 20 20z" clip-rule="evenodd"></path></g><path fill="#222" fill-rule="evenodd" d="M24 36V21l15 7.5L24 36z" clip-rule="evenodd"></path><defs><filter id="filter0_d" width="60" height="60" x="0" y="0" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix><feOffset dy="2"></feOffset><feGaussianBlur stdDeviation="5"></feGaussianBlur><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"></feColorMatrix><feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend><feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend></filter></defs></svg>
						</div>
						<div class="videos_duration">{{ getMinutes(video.video.duration) }}</div>
					</div>
					
					<div class="videos_title" v-html="video.title"></div>
					
					<div class="videos_placeholder" v-if="video.sport">
						<span>{{ video.rightholder.name }} • {{ video.sport.title }}</span>
					</div>
					<div class="videos_time">{{ getTime(video.updatedAt) }}</div>
				</div>
			</div>
		</div>
		<div v-if="loading && !loaded" class="info-text"></div>
	</div>
	
</template>

<script>
	import moment from "moment";
	import $ from "jquery";

	export default {
		props: ['ids'],
		
		data() {
			return {
				videos: [],
				page: 1,
				loading: true,
				loaded: false
			}
		},

		created() {
			this.getVideos();

			document.addEventListener('scroll', () => {
				if (this.loading || this.loaded) return;

				let scrollEnd = (window.innerHeight + window.scrollY) >= $('.videos_row').offset().top + $('.videos_row').innerHeight();

				if (scrollEnd) {
					this.loading = true;

					this.page++;

					this.getVideos();
				}
			})
		},
		
		methods: {
			getThumbnail(images) {
				if (!images) return;

				let image = images.filter((image) => {
					return image.format === 'HIGH';
				});

				if (image.length) return image[0].url;

				return images[0].url;
			},
			getTime(date) {
				return moment(date).locale('ru').format('D MMMM Y, HH:mm');
			},
			getMinutes(duration) {
				return parseInt(duration / 60) + ':' + Math.round(duration % 60);
			},

			openVideo(video) {
				events.$emit('openVideo', video.embed);
			},
			getVideos() {
				let params = {
					rowsPerPage: 12,
					rightholderIds: this.ids,
					page: this.page
				}

				this.axios.get('/goalserve/sportrecsapi/contents', {params: params})
					.then(({data}) => {
						if (data.data.contents.nodes.length < 12) {
							this.loaded = true;
						}
	
						this.videos = this.videos.concat(data.data.contents.nodes);
	
						this.loading = false;
					});
			},
		}
	}
</script>

<style scoped>

</style>