<template>
	<div>
		<div class="heading">Бомбардиры</div>
		
		<div class="live-match-2">
			<select @change="changeLeague" class="live-match_select" v-model="currentLeague" v-if="leagues">
				<option :value="league.id" v-for="league in leagues" :key="league.id">{{ league.name }}</option>
			</select>
			
			<div class="info-text" v-if="loading"></div>
			<scorers v-else-if="scorers" :data="scorers"></scorers>
			<div v-else>Нет данных</div>
		</div>
	</div>
</template>

<script>
	import Tab from "../Tab/Tab";
	import Scorers from "../Sports/Soccer/Components/Events/Scorers";

	export default {
		props: ['scorers', 'leagues'],

		components: {
			Tab,
			Scorers
		},

		data() {
			return {
				currentLeague: 1204,
				loading: true
			}
		},

		created() {
			this.$emit('update', this.currentLeague, this.afterLoading)
		},

		methods: {
			changeLeague() {
				this.loading = true;

				this.$emit('update', this.currentLeague, this.afterLoading)
			},

			afterLoading() {
				this.loading = false;
			}
		}
	}
</script>

<style scoped>

</style>