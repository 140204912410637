<template>
	<div class="res">
		<div class="wrapper">
			<div class="res_row">
				<div class="res_left">
					<categories :data="categories"></categories>
				</div>
				<div class="res_right">
					<div class="tab">
						<match-header></match-header>
						
						<tab :headings="{active : 'Последние'}" active-key="active" :base="true">
							<template v-slot:active>
								<list v-if="matches" :data="matches"></list>
								<div v-else-if="matchesLoading" class="info-text"></div>
								<div v-else class="info">Нет данных</div>
							</template>
						</tab>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import MatchHeader from '../../Common/MatchHeader';
	import Categories from "../Components/Categories";
	import Tab from "../../../Tab/Tab";
	import List from "../Components/List";

	export default {
		components: {
			MatchHeader,
			Categories,
			Tab,
			List
		},

		data() {
			return {
				categories: null,
				matches: null,
				matchesLoading: true
			}
		},

		created() {
			this.axios.get('/goalserve/box')
				.then(({data}) => {
					this.matches = data.res;
					this.matchesLoading = false;
				})
				.catch(() => {
					this.matchesLoading = false;
				})
			this.axios.get('/goalserve/box/weights')
				.then(({data}) => {
					this.categories = data.res;
				});
		},
	}
</script>

<style scoped>

</style>