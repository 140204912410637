<template>
	<div class="main-news">
		<div class="wrapper">
			<div>
				<div class="heading-2" v-if="tag">
					<span>{{ tag }}</span>
					<router-link :to="{name: 'news'}" class="heading-2_del"></router-link>
				</div>
				<tab v-if="!tag" :headings="{soccer: 'Новости футбола', sport: 'Новости спорта'}" active-key="soccer">
					<template v-slot:soccer>
						<articles :id="1" sport="soccer"></articles>
					</template>
					<template v-slot:sport>
						<articles :id="2" exclude="1" sport="soccer"></articles>
					</template>
				</tab>
				<div v-else>
					<articles :id="2"></articles>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
	import $ from 'jquery';
	import Articles from "../components/News/Articles";
	import Tab from "../components/Tab/Tab";
	
	export default {
		data() {
			return {
				tag: this.$route.query.tag || null,
			}
		},
		
		created() {
			
		},

		components: {
			Tab,
			Articles
		},
		mounted() {
			document.title = 'Новости' + window.title;
		}
	}
</script>