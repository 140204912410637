<template>
	<div>
		<div class="heading">Не сыграют</div>
		
		<div class="injuries">
			<div class="injuries_row">
				<div class="injuries_col">
					<div class="injuries_logo">
						<img :src="$hostname + '/goalserve/soccer/team_image/' + matchEvent.home_id" alt="">
						<span>{{ matchEvent.home_name }}</span>
					</div>
					<div class="injuries_item" v-for="(player, key) in data.localteam.sidelined.to_miss.player" :key="key">
						<div class="injuries_text">{{ player.name }}</div>
						<div class="injuries_text-2">({{ player.status }})</div>
					</div>
				</div>
				<div class="injuries_col">
					<div class="injuries_logo">
						<span>{{ matchEvent.away_name }}</span>
						<img :src="$hostname + '/goalserve/soccer/team_image/' + matchEvent.away_id" alt="">
					</div>
					<div class="injuries_item" v-for="(player, key) in data.visitorteam.sidelined.to_miss.player" :key="key">
						<div class="injuries_text-2">({{ player.status }})</div>
						<div class="injuries_text">{{ player.name }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['data', 'matchEvent']
	}
</script>

<style scoped>

</style>