<template>
	<div>
		<div class="rank_anno">
			<div class="rank-annotation ykmeqvo" style="margin:15px 0;">
				<div class="rank-annotation__group wmpx">
					<strong>Уважаемые пользователи!</strong> <br><br>
					
					В данном разделе вы можете приобрести билеты на любой интересующий вас футбольный матч через удобный виджет от наших партнеров.
					<br><br>
					Так же, специально для вас - мы подготовили комфортное расписание всех ТВ трансляций по наиболее популярным футбольным лигам и матчам, в рамках которого вы сможете получить подробную информацию как о времени начала матча, так и о телеканалах на которых вы сможете посмотреть ТВ трансляцию матча.
				</div>
			</div>
		</div>
		
		<div class="tickets">
			<div>
				<div class="heading">Билеты</div>
				
				<div id="partnersUbsBlock" style="width:100%;height:600px;overflow: auto;overflow-x: hidden;-webkit-overflow-scrolling:touch;border: none;position:relative;"><iframe name="UbsIframe" id="UbsIframe" src="http://ubspartners.ru/widgets/?marker=bxogvud9xH" width="100%" height="100%" style="border: none;position:absolute;margin:0;padding:0;"></iframe>
				</div>
			</div>
			
			
			<div>
				<div class="heading">ТВ</div>
				
				<iframe id="ftv_iframe" name="ftv_iframe" src="https://widgets.futbolenlatv.com/?color=f90&culture=ru-RU" width="500" height="700" frameborder="0" scrolling="auto" style="margin-bottom: 10px"></iframe>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		mounted() {
			document.title = 'Билеты и ТВ' + window.title;
		}
	}
</script>

<style scoped>

</style>