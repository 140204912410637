<template>
	<div>
		<div class="last-result">
			<div class="last-result_header">
				<div class="last-result_command">
					<img :src="$hostname + '/goalserve/soccer/team_image/' + home.id" alt="">
					<span>{{ home.name }}</span>
				</div>
				
				<div class="last-result_command __last">
					<span>{{ away.name }}</span>
					<img :src="$hostname + '/goalserve/soccer/team_image/' + away.id" alt="">
				</div>
			</div>
			
			<div class="last-result_content">
				<div class="last-result_col-mid">
					<div>
						<div class="last-result_row __right"  v-for="(result, index) in lastResults.team1" :key="index + '-team1'">
							<div class="last-result_info __right">
								<div class="last-result_text-1">
									<div>
										<span>{{ result.team1 }}</span>
										<br>
										<strong>{{ result.team1_score }}</strong>
									</div>
									<i>-</i>
									<div>
										<strong>{{ result.team2_score }}</strong>
										<br>
										<span>{{ result.team2 }}</span>
									</div>
								</div>
								<div class="last-result_text-2">
									<span>{{ result.league }} -</span> {{ result.date }}</div>
							</div>
							
							<div class="last-result_score" :class="win(result.team1_score, result.team2_score, result).classes">
								{{ win(result.team1_score, result.team2_score, result).text }}
							</div>
						</div>
					</div>
				</div>
				<div class="last-result_col-mid">
					<div>
						<div class="last-result_row"  v-for="(result, index) in lastResults.team2" :key="index + '-team2'">
							<div class="last-result_score" :class="win(result.team1_score, result.team2_score, result).classes">
								{{ win(result.team1_score, result.team2_score, result).text }}
							</div>
							<div class="last-result_info __left">
								<div class="last-result_text-1">
									<div>
										<span>{{ result.team1 }}</span>
										<br>
										<strong>{{ result.team1_score }}</strong>
									</div>
									<i>-</i>
									<div>
										<strong>{{ result.team2_score }}</strong>
										<br>
										<span>{{ result.team2 }}</span>
									</div>
								</div>
								<div class="last-result_text-2">
									<span>{{ result.league }} -</span> {{ result.date }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['match', 'results'],
		
		data() {
			return {
				res: this.results
			}
		},
		computed: {
			home() {
				return this.match.home;
			},
			away() {
				return this.match.away;
			},
			lastResults() {
				let res = JSON.parse(JSON.stringify(this.results));
				
				let data = {
					team1: [],
					team2: []
				};
				
				for (let i = 0; i <= 4; i++) {
					res.last5_home.team1.match[i].home = true;
					data.team1.push(res.last5_home.team1.match[i]);
					
					res.last5_away.team1.match[i].away = true;
					data.team1.push(res.last5_away.team1.match[i]);
					
					
					res.last5_home.team2.match[i].home = true;
					data.team2.push(res.last5_home.team2.match[i]);
					
					res.last5_away.team2.match[i].away = true;
					data.team2.push(res.last5_away.team2.match[i]);
				}
				
				return data;
			}
		},
		
		methods: {
			win(team1, team2, result) {
				if (result.away) {
					let tmp = team1;
					
					team1 = team2;
					team2 = tmp;
				}
				
				team1 = parseInt(team1);
				team2 = parseInt(team2);
				
				if (team1 > team2) {
					return {
						text: 'В',
						classes: ['__win']
					};
				} else if (team1 === team2) {
					return {
						text: 'Н',
						classes: ['__draw']
					};
				}
				
				return {
					text: 'П',
					classes: ['__fail']
				};
			}
		}
	}
</script>