<template>
	<div>
		<div class="match-info">
			<div class="heading">Информация о матче</div>
			
			<div class="match-info_table">
				<div class="match-info_table">
					<div class="match-info_row">
						<div class="match-info_col">
							<div class="match-info_item">
								<div class="match-info_ico">
									<img src="/img/calendar2.svg" width="48" alt="">
									<span>Начало матча</span>
								</div>
								<div class="match-info_text">{{ getDateTime(match.date, match.time) }}</div>
							</div>
						</div>
						<div class="match-info_col" v-if="competition">
							<div class="match-info_item">
								<div class="match-info_ico">
									<img src="/img/competition.svg" width="48" alt="">
									<span>Соревнование</span>
								</div>
								<div class="match-info_text">{{ competition }}</div>
							</div>
						</div>
						<div class="match-info_col">
							<div class="match-info_item">
								<div class="match-info_ico">
									<img src="/img/stadium.svg" width="48" alt="">
									<span>Стадион</span>
								</div>
								<div class="match-info_text">{{ match.matchinfo.stadium.name}}</div>
							</div>
						</div>
						<div class="match-info_col">
							<div class="match-info_item">
								<div class="match-info_ico">
									<img src="/img/auction.svg" width="48" alt="">
									<span>Судья</span>
								</div>
								<div class="match-info_text">{{ match.matchinfo.referee.name }}</div>
							</div>
						</div>
						<div class="match-info_col" v-if="match.coaches">
							<div class="match-info_item">
								<div class="match-info_ico">
									<img src="/img/coach.svg" width="48" alt="">
									<span>Тренеры</span>
								</div>
								<div class="match-info_text">
									<span>
										<img :src="$hostname + '/goalserve/soccer/team_image/' + match.localteam.id" alt="">
										{{ match.coaches.localteam.coach.name }}
									</span>
									<span>
										{{ match.coaches.visitorteam.coach.name }}
										<img :src="$hostname + '/goalserve/soccer/team_image/' + match.visitorteam.id" alt="">
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from "moment";

	export default {
		props: ['match', 'competition'],
		
		methods: {
			getDateTime(date, time, format = true) {
				let method = getTimeZoneNum() >= 0 ? 'add' : 'subtract';

				date = date.split('.');
				date = date[2] + '-' + date[1] + '-'+ date[0];

				let datetime = moment(date + 'T' + time)[method](getTimeZoneNum(), 'hours');

				return format ? datetime.locale('ru').format('DD MMMM Y, HH:mm') : datetime;
			},
		}
	}
</script>

<style scoped>

</style>