<template>
	<div class="last-result __btn">
		<div class="last-result_header" v-if="!onlyTeam">
			<div class="last-result_command">
				<img :src="$hostname + '/goalserve/soccer/team_image/' + home.id" alt="">
				<span>{{ home.name }}</span>
			</div>
			
			<div class="last-result_command __last">
				<span>{{ away.name }}</span>
				<img :src="$hostname + '/goalserve/soccer/team_image/' + away.id" alt="">
			</div>
		</div>
		
		<div class="last-result_header" v-else-if="onlyTeam === 'home'">
			<div class="last-result_command __center">
				<img :src="$hostname + '/goalserve/soccer/team_image/' + home.id" alt="">
				<span>{{ home.name }} - последние результаты дома</span>
			</div>
		</div>
		
		<div class="last-result_header" v-else-if="onlyTeam === 'away'">
			<div class="last-result_command __center">
				<img :src="$hostname + '/goalserve/soccer/team_image/' + away.id" alt="">
				<span>{{ away.name }} - последние результаты в гостях</span>
			</div>
		</div>
		
		<div class="last-result_content">
			<div class="last-result_col-mid" :class="{__center: onlyTeam}" v-if="onlyTeam !== 'away'">
				<div>
					<div class="last-result_row" :class="{__right: !onlyTeam, __left: onlyTeam}"  v-for="(result, index) in lastResults.team1" :key="index + '-team1'">
						<div class="last-result_info" :class="{__right: !onlyTeam}">
							<div class="last-result_text-1">
								<div>
									<span>{{ result.team1 }}</span>
									<br>
									<strong>{{ result.team1_score }}</strong>
								</div>
								<i>-</i>
								<div>
									<strong>{{ result.team2_score }}</strong>
									<br>
									<span>{{ result.team2 }}</span>
								</div>
							</div>
							<div class="last-result_text-2">
								<span>{{ result.league }} -</span> {{ result.date }}</div>
						</div>
						
						<div class="last-result_score" :class="win(result.team1_score, result.team2_score, result).classes">
							{{ win(result.team1_score, result.team2_score, result).text }}
						</div>
					</div>
				</div>
			</div>
			<div class="last-result_col-mid" :class="{__center: onlyTeam}" v-if="onlyTeam !== 'home'">
				<div>
					<div class="last-result_row" :class="{__center: onlyTeam, __left: onlyTeam}" v-for="(result, index) in lastResults.team2" :key="index + '-team2'">
						<div class="last-result_score" :class="win(result.team1_score, result.team2_score, result).classes">
							{{ win(result.team1_score, result.team2_score, result).text }}
						</div>
						<div class="last-result_info __left">
							<div class="last-result_text-1">
								<div>
									<span>{{ result.team1 }}</span>
									<br>
									<strong>{{ result.team1_score }}</strong>
								</div>
								<i>-</i>
								<div>
									<strong>{{ result.team2_score }}</strong>
									<br>
									<span>{{ result.team2 }}</span>
								</div>
							</div>
							<div class="last-result_text-2">
								<span>{{ result.league }} -</span> {{ result.date }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['home', 'away', 'lastResults', 'title', 'onlyTeam'],
		
		methods: {
			win(team1, team2, result) {
				if (result.away) {
					let tmp = team1;

					team1 = team2;
					team2 = tmp;
				}

				team1 = parseInt(team1);
				team2 = parseInt(team2);

				if (team1 > team2) {
					return {
						text: 'В',
						classes: ['__win']
					};
				} else if (team1 === team2) {
					return {
						text: 'Н',
						classes: ['__draw']
					};
				}

				return {
					text: 'П',
					classes: ['__fail']
				};
			},
		}
	}
</script>

<style scoped>

</style>