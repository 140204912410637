<template>
	<div class="match">
		<div class="wrapper" v-if="match">
			<div class="match_row">
				<div class="match_right">
					<div class="tab">
						<div class="live-command">
							<div class="live-command_info">
								<div>{{ getDateTime(match.date, match.time) }}</div>
								<div class="__bold">
									<div class="live-command_res">
										<div v-if="match.status === 'Finished'">Завершен</div>
										<div v-else-if="match.status === 'Not Started'">Матч не начался</div>
										<div v-else-if="match.status === 'Cancl.'">Отменен</div>
										<div v-else-if="match.status === 'Postp.'">Отложен</div>
										<div v-else-if="match.status">{{ getStatus(match.status) }}</div>
									</div>
								</div>
							</div>
							
							<div class="live-command_row">
								<router-link :to="{name: 'team-hockey', params: {id: match.localteam.id, sport: sport, league: league}}" class="live-command_image">
									<img :src="$hostname + '/goalserve/hockey/team_image/' + match.localteam.id" alt="">
									<span>{{ match.localteam.name }}</span>
								</router-link>
								
								<div class="live-command_content">
									<div class="live-command_score">
										{{ match.localteam.totalscore }} -&nbsp; {{ match.awayteam.totalscore }}
									</div>
								</div>
								
								<router-link :to="{name: 'team-hockey', params: {id: match.awayteam.id, league: league}}" class="live-command_image">
									<img :src="$hostname + '/goalserve/hockey/team_image/' + match.awayteam.id" alt="">
									<span>{{ match.awayteam.name }}</span>
								</router-link>
							</div>
						</div>
						
						<tab v-if="matchScore" :headings="{timeline: 'Обзор'}" active-key="timeline">
							<template v-slot:timeline>
								<timeline-result :penalties="matchScore.penalties" :scoring="matchScore.scoring"></timeline-result>
							</template>
						</tab>
					</div>
				</div>
			</div>
		</div>
		
		<div v-else-if="loading" class="info-text"></div>
		
		<div class="wrapper" v-else>
			<div class="info __pt0">Информация обновляется</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment'
	import Tab from "../../../Tab/Tab";
	import TimelineResult from "../Components/Match/TimelineResult";
	
	export default {
		components: {
			Tab,
			TimelineResult
		},
		
		data() {
			return {
				match: null,
				id: this.$route.params.id,
				league: this.$route.params.league,
				loading: true,
				matchScore: null,
				sport: 'hockey'
			}
		},
		
		created() {
			this.axios.get('/goalserve/hockey/league/fixtures?league_id=' + this.league + '&static_id=' + this.id)
				.then(({data}) => {
					this.match = data.res ? data.res[0] ? data.res[0].events[0] : data.res.match[0] : null;
					this.loading = false;
					
					if (this.match) {
						this.axios.get('/goalserve/hockey/league/scores?league_id=' + this.league + '&date=' + this.match.date + '&id=' + this.id)
							.then(({data}) => {
								this.matchScore = data.res ? data.res.match[0] : null;
							});
					}
				});
		},
		
		methods: {
			getDateTime(date, time, format = true) {
				let method = getTimeZoneNum() >= 0 ? 'add' : 'subtract';

				date = date.split('.');
				date = date[2] + '-' + date[1] + '-'+ date[0];

				let datetime = moment(date + 'T' + time)[method](getTimeZoneNum(), 'hours');

				return format ? datetime.locale('ru').format('DD MMMM Y, dddd HH:mm') : datetime;
			},
			getStatus(status) {
				let data = {
					'1st Period': '1-й период',
					'2nd Period': '2-й период',
					'3rd Period': '3-й период',
					'Finished': 'Завершен',
					'Break Time': 'Перерыв',
					'Cancelled': 'Отменен',
					'After Penalties': 'После буллитов',
					'After Over Time': 'После ОТ',
					'Postponed': 'Перенесен',
					'Not Started': 'Не нач.',
					'Awarded': 'Тех. Поражение'
				};
				
				return data[status] ? data[status] : status;
			}
		}
	}
</script>