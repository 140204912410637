<template>
	<div>
		<div class="res">
			<div class="wrapper">
				<div class="res_row">
					<div class="res_left">
						<leagues @update="update" v-if="leagues" :data="leagues"></leagues>
					</div>
					
					<div class="res_right">
						<match-header></match-header>
						
						<list v-if="matchesData" :data="matchesData"></list>
						<div v-else-if="loading" class="info-text"></div>
						<div v-else class="info">Нет данных <br><br></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment'

	import Slick from 'vue-slick';
	import List from "../../../components/Events/List";
	import Leagues from "../../../components/Sport/Leagues";
	import MatchHeader from '../../../components/Sports/Common/MatchHeader';

	export default {
		components: {
			Slick,
			List,
			Leagues,
			MatchHeader
		},

		created() {
			this.initData();
			this.initLeagues();
			this.initInfo();
		},

		mounted() {
			document.title = 'Матч-центр' + window.title;
		},

		data() {
			return {
				loading: true,
				matchesData: null,
				leagues: null,
				articles: null,
				news: null,
				videos: null,
				newsSliderOptions: {
					slidesToShow: 3,
					slidesToScroll: 1,
					autoplay: true,
					autoplaySpeed: 5000,
					arrows: false,
					dots: true,
					infinite: true,

					responsive: [
						{
							breakpoint: 767,
							settings: {
								slidesToShow: 1
							}
						}
					]
				}
			}
		},

		methods: {
			initData() {
				let day = this.$route.query.day_diff || 0;
				let url = '/goalserve/soccer/league/events';

				if (day !== 0) {
					url += '?day_diff=' + day;
				}

				this.axios.get(url)
				.then(({data}) => {
					this.matchesData = this.sortData(data.res);
					this.loading = false;
				})
				.catch(()=> {
					this.loading = false;
				});

				setTimeout(() => {
					this.initData();
				}, 1000 * 60);
			},
			update(league) {
				this.$router.push({
					name: 'league',
					params: {
						id: league
					}
				})
			},
			sortData(data) {
				if (!data) {
					return null;
				}

				let leagues = [1001, 1039, 1531, 1603, 1005, 1007, 1204, 1229, 1399, 1269, 1457, 1221, 1428, 4529];

				leagues.reverse().forEach((id) => {
					data = data.sort((a, b) => {
						if (a.league_id == id) {
							return -1;
						}
						if (b.league_id == id) {
							return 1;
						}
					});
				});

				return data;
			},
			initLeagues() {
				this.axios.get('/goalserve/soccer/league')
				.then(({data}) => {
					this.leagues = data;
				});
			},
			initInfo() {
				this.axios.get('/api/articles', {params: {sport: 'soccer', limit: 9, page: 1}})
				.then(({data}) => {
					this.articles = data.data;
				});
				this.axios.get('/api/news', {params: {sport: 'soccer', limit: 10, page: 1}})
				.then(({data}) => {
					this.news = data.data;
				});
			},
			getTime(date) {
				return moment(date).locale('ru').format('D MMMM Y, HH:mm');
			},
		}
	}
</script>

<style scoped>

</style>