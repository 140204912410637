<template>
	<div>
		<div class="res" v-if="windowWidth > 860">
			<div class="res_row">
				<div class="res_left">
					<articles :articles="articles2" :classes="['__col-1']"></articles>
					
					<div class="__pk">
						<standings :standings="standings" @update="initStandings" :leagues="leagues"></standings>
					</div>
					
					<scorers :scorers="scorers" :leagues="leaguesScorers" @update="initScorers"></scorers>
				</div>
				
				<div class="res_right">
					<matches v-if="allLoaded >= 3" :match-tab-key="matchTabKey" :matches-data="matchesData" :matches-data-tomorrow="matchesDataTomorrow" :matches-data-yesterday="matchesDataYesterday" :show-hidden="true"></matches>
					<div v-else class="info-text" :hide-actions="true"></div>
					
					<br><br>
					
					<videos></videos>

					<div class="info-block">
						<div class="info-block_col">
							<main-news :news="news"></main-news>
						</div>
						<div class="info-block_col">
							<sport-news :news="allNews" v-if="allNews"></sport-news>
							<div class="info-text" v-else></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="res" v-else>
			<matches v-if="allLoaded >= 3" :match-tab-key="matchTabKey" :matches-data="matchesData" :matches-data-tomorrow="matchesDataTomorrow" :matches-data-yesterday="matchesDataYesterday"></matches>
			<div v-else class="info-text"></div>
			<br><br>
			<articles :articles="articles2" :classes="['__col-1']"></articles>
			
			<videos></videos>
			
			<br><br>
			
			<div class="__pk">
				<standings :standings="standings" @update="initStandings" :leagues="leagues"></standings>
			</div>
			
			<scorers :scorers="scorers" :leagues="leaguesScorers" @update="initScorers"></scorers>
			
			<br><br>
			
			<main-news :news="news"></main-news>
			
			<br>
			
			<sport-news :news="allNews" v-if="allNews"></sport-news>
		</div>
	</div>
</template>

<script>
	import $ from 'jquery'
	
	import NewsSlider from "../components/Home/NewsSlider";
	import MainNews from "../components/Home/MainNews";
	import Standings from "../components/Home/Standings";
	import Scorers from "../components/Home/Scorers";
	import Matches from "../components/Home/Matches";
	import Articles from "../components/Home/Articles";
	import Videos from "../components/Home/Videos";
	import SportNews from "../components/Home/SportNews";
	import RssNews from "../components/Home/RssNews";
	
	export default {
		components: {
			MainNews,
			NewsSlider,
			Standings,
			Scorers,
			Matches,
			Articles,
			Videos,
			SportNews,
			RssNews
		},

		data() {
			return {
				matchesData: null,
				matchesDataYesterday: null,
				matchesDataTomorrow: null,
				leagues: null,
				leaguesScorers: null,
				scorers: null,
				articles: null,
				articles2: null,
				allNews: null,
				news: null,
				standings: null,
				matchTabKey: 'today',
				allLoaded: 0,
				rssNews: null,
				windowWidth: $(window).width()
			}
		},
		
		created() {
			this.initData();
			this.initLeagues();
			this.initInfo();
			
			this.initRssNews();
			
			$(window).on('resize', () => {
				this.windowWidth = $(window).width()
			});


			this.axios.get('https://sportrecs.com/api/v2/contents?apiKey=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MDViNWU3ZDc4NGM1YzAwMmQ0OGRhYTkiLCJpYXQiOjE2MTc0Nzg3NDh9.l8YuLwnl-kW97qWoUCCkmimg5-mlcuyf7ii7qA4cDpc');
		},


		mounted() {
			document.title = window.title.replace(' - ', '');
		},

		methods: {
			initData() {
				let url = '/goalserve/soccer/league/events';

				let leagues = [1001, 1039, 1535, 1005, 1007, 1204, 1229, 1399, 1269, 1457, 1221, 1428, 1363, 1051];

				this.axios.get(url)
					.then(({data}) => {
						this.allLoaded++;
						
						let matches = data.res.filter((league) => {
							this.league1535Filter(league);
							
							return leagues.includes(parseInt(league.league_id)) && league.events.length
						});
						
						this.matchesData = this.sortData(matches);
						this.loading = false;
						
						if (!this.matchesData.length) {
							this.matchTabKey = 'tomorrow';
						}
					})
					.catch(()=> {
						this.loading = false;
					});

				this.axios.get(url + '?day_diff=-1')
					.then(({data}) => {
						this.allLoaded++;
						
						let matches = data.res.filter((league) => {
							this.league1535Filter(league);
							
							return leagues.includes(parseInt(league.league_id)) && league.events.length
						});

						this.matchesDataYesterday = this.sortData(matches);
						this.loading = false;

						if (!this.matchesDataYesterday && !this.matchesData.length) {
							this.matchTabKey = 'yesterday';
						}
					});

				this.axios.get(url + '?day_diff=1')
					.then(({data}) => {
						this.allLoaded++;
						
						let matches = data.res.filter((league) => {
							this.league1535Filter(league);
							
							return leagues.includes(parseInt(league.league_id)) && league.events.length;
						});
	
						this.matchesDataTomorrow = this.sortData(matches);
						this.loading = false;
					});
			},
			sortData(data) {
				if (!data) {
					return null;
				}

				let leagues = [1039, 1457, 1531, 1603, 1005, 1007, 1204, 1229, 1399, 1269, 1221, 1428, 4529, 1051];

				leagues.reverse().forEach((id) => {
					data = data.sort((a, b) => {
						if (a.league_id == id) {
							return -1;
						}
						if (b.league_id == id) {
							return 1;
						}
					});
				});

				return data;
			},
			initLeagues() {
				let leagues = [1457, 1204, 1229, 1399, 1269, 1221, 1428, 1051];
				let leaguesScorers = [1457, 1204, 1229, 1399, 1269, 1221, 1428];
				
				this.axios.get('/goalserve/soccer/league')
					.then(({data}) => {
						this.leagues = data.res.filter(league => leagues.includes(league.id));
						this.leaguesScorers = data.res.filter(league => leaguesScorers.includes(league.id));
					});
			},
			initInfo() {
				this.axios.get('/api/articles', {params: {sport: 'soccer', limit: 6, page: 1}})
					.then(({data}) => {
						this.articles2 = data.data;
					});
				this.axios.get('/api/news', {params: {sport: 'soccer', limit: 16, page: 1}})
					.then(({data}) => {
						this.news = data.data;
					});
				this.axios.get('/api/news', {params: {limit: 16, page: 1, sport: 'soccer', exclude: 1}})
					.then(({data}) => {
						this.allNews = data.data;
					});
			},
			initStandings(id, callback) {
				this.axios.get('/goalserve/soccer/league/standings?league_id=' + id)
					.then(({data}) => {
						this.standings = data.res ? data.res : null;

						callback();
					});
			},
			initScorers(id, callback) {
				this.axios.get('/goalserve/soccer/league/topscorers?league_id=' + id)
					.then(({data}) => {
						this.scorers = data.res;

						callback();
					});
			},
			initRssNews() {
				let cnt = 0;
				let rssNews = {};
				
				this.axios.get('/api/rss/sport')
					.then(({data}) => {
						rssNews.sport = data;
						cnt++;
						
						if (cnt === 4) {
							this.rssNews = rssNews;
						}
					});
				this.axios.get('/api/rss/sports')
					.then(({data}) => {
						rssNews.sports = data;
						cnt++;

						if (cnt === 4) {
							this.rssNews = rssNews;
						}
					});
				this.axios.get('/api/rss/rbc')
					.then(({data}) => {
						rssNews.rbc = data;
						cnt++;

						if (cnt === 4) {
							this.rssNews = rssNews;
						}
					});
				this.axios.get('/api/rss/express')
					.then(({data}) => {
						rssNews.express = data;
						cnt++;

						if (cnt === 4) {
							this.rssNews = rssNews;
						}
					});
			},
			league1535Filter(league) {
				if (league.league_id == 1535) {
					league.events = league.events.filter((item) => {
						return item.home_name === 'Россия' || item.away_name === 'Россия';
					});
				}
			}
		}
	}
</script>

<style scoped>

</style>