<template>
	<div>
		<div class="match-text" v-if="match">
			<div class="match-text_item" v-if="isFinished">
				<div class="match-text_info">
					<div class="match-text_info-time">90'</div>
					<div class="timeline_whistle"></div>
				</div>
				<div class="match-text_data">
					{{ getRandomMessage('finish') }}
				</div>
			</div>
			<div v-for="(incident, index) in summary" :key="index">
				<div v-if="incident.player && incident.player.search('(pen.)') !== -1">
					<div class="match-text_item">
						<div class="match-text_info">
							<div class="match-text_info-time" v-if="incident.minute">{{ incident.minute }}'</div>
							<div class="timeline_ball"></div>
						</div>
						
						<div class="match-text_data __goal">{{ getRandomMessage('penalty', incident) }}</div>
					</div>
					<div class="match-text_item">
						<div class="match-text_info">
							<div class="match-text_info-time" v-if="incident.minute">{{ incident.minute }}'</div>
							<div class="timeline_warning"></div>
						</div>
						
						<div class="match-text_data">{{ getRandomMessage('beforePenalty') }}</div>
					</div>
				</div>
				<div v-else-if="incident.type === 'goals' || incident.type === 'goal'" class="match-text_item">
					<div class="match-text_info">
						<div class="match-text_info-time" v-if="incident.minute">{{ incident.minute }}'</div>
						<div class="timeline_ball"></div>
					</div>
					
					<div class="match-text_data __goal" v-if="incident.player.search('(o.g.)') !== -1">{{ getRandomMessage('autogoal', incident) }}</div>
					<div class="match-text_data __goal" v-else>{{ getRandomMessage('goal', incident) }}</div>
				</div>
				<div v-else-if="incident.substitution || incident.type === 'subst'" class="match-text_item">
					<div class="match-text_info">
						<div class="match-text_info-time" v-if="incident.minute">{{ incident.minute }}'</div>
						<div class="timeline_arrow __subst"></div>
					</div>
					
					<div class="match-text_data">{{ getRandomMessage('replace', incident) }}</div>
				</div>
				<div v-else-if="incident.type === 'redcards' || incident.type === 'redcard'" class="match-text_item">
					<div class="match-text_info">
						<div class="match-text_info-time" v-if="incident.minute">{{ incident.minute }}'</div>
						<div class="timeline_cart __red"></div>
					</div>
					
					<div class="match-text_data">{{ getRandomMessage('red', incident) }}</div>
				</div>
				<div v-else-if="incident.type === 'yellowcards' || incident.type === 'yellowcard'" class="match-text_item">
					<div class="match-text_info">
						<div class="match-text_info-time" v-if="incident.minute">{{ incident.minute }}'</div>
						<div class="timeline_cart __yellow"></div>
					</div>
					
					<div class="match-text_data">{{ getRandomMessage('yellow', incident) }}</div>
				</div>
				<div v-else-if="incident.type === 'corner'" class="match-text_item">
					<div class="match-text_info">
						<div class="match-text_info-time" v-if="incident.minute">{{ incident.minute }}'</div>
						<div class="timeline_corner"></div>
					</div>
					
					<div class="match-text_data">{{ getRandomMessage('corner', incident) }}</div>
				</div>
			</div>
			<div class="match-text_item" v-if="isStarted">
				<div class="match-text_info">
					<div class="match-text_info-time">1'</div>
					<div class="timeline_whistle"></div>
				</div>
				
				<div class="match-text_data">{{ getRandomMessage('start') }}</div>
			</div>
			<div class="match-text_item">
				<div class="match-text_info">
					<div class="timeline_shirt"></div>
				</div>
				
				<div class="match-text_data">C составами и расстановками команд Вы можете ознакомиться на вкладке Составы.</div>
			</div>
			<div class="match-text_item">
				<div class="match-text_info">
					<div class="timeline_shirt"></div>
				</div>
				
				<div class="match-text_data">Матч совсем скоро начнется.</div>
			</div>
			<div class="match-text_item">
				<div class="match-text_info">
					<div class="timeline_shirt"></div>
				</div>
				
				<div class="match-text_data">Добрый вечер, уважаемые болельщики! Добро пожаловать на нашу трансляцию.</div>
			</div>
		</div>
		<div v-else class="info-text"></div>
	</div>
</template>

<script>
	import _ from "underscore";

	export default {
		props: ['match', 'matchEvent'],
		
		data() {
			return {
				id: this.$route.params.id,
				randomNumbers: [],
				messages: {
					start: [
						'Первый тайм начинается.',
						'Сегодняшний матч только что начался.',
						'Первая половина встречи началась.',
						'Игра только что началась.'
					],
					corner: [
						'[team] не создает опасного момента после углового. Оборона сыграла удачно и отбила мяч.',
						'Мяч вынесли из штрафной, после подачи углового',
						'Хороший подкат защитника и мяч вышел на угловой.',
						'Угловой, подает [team], но вратарь сыграл на перехвате.',
						'Угловой, мяч не летит в штрафную. Игроки решили разыграть.',
						'Игрок исполняет подачу с углового. Но никто из одноклубников не откликнулся на нее.',
						'Вратарь выбил мяч в борьбе после углового.',
						'Угловой, сейчас будет подача в штрафную.',
						'Угловой. Исполняет [team]. Один из защитников выбил мяч подальше от ворот',
						'Вратарь уверенно сыграл на выходе после того, как был исполнен навес с углового.',
						'Угловой! [team] готова разыграть его.',
						'[team] быстро разыгрывает угловой.',
						'[team] исполняет подачу с углового. Но никто из одноклубников не откликнулся на нее.',
						'Команда не создает опасного момента после углового. Оборона сыграла удачно и отбила мяч.',
						'Подача в штрафную с углового, но оборона справилась.',
						'Оборона отбила мяч подальше после углового.',
						'Защита выбивает мяч подальше от ворот после исполнения углового',
						'Опасно закручен мяч с углового, но никто из партнеров не смог сыграть удачно на втором этаже.',
						'Быстро разыгрын угловой.',
						'Команда разыгрывает угловой, не делая навес в штрафную сразу.',
						'Игрок отыгрался с партнером при подаче углового.',
						'Угловой подан. Но здорово сыграл на выходе вратарь.',
						'Исполнен угловой. Но оборона соперника уверенно отводит угрозу от ворот.',
					],
					goal: [
						'ГОЛ! [name] наносит отличный удар головой после не менее отличного навеса. Ни одного шанса для вратаря спасти команду.',
						'ГОЛ! [name] оказался в нужном месте в нужное время и, получив мяч на подборе в штрафной, пробил низом в самый угол ворот.',
						'Мяч в сетке! [name] наносит голевой удар в правый угол ворот.',
						'ГОЛ! [name] забивает точным ударом с угла штрафной в левый угол ворот.',
						'[name] уверенно и точно пробил пенальти. В левый угол, без шансов для голкипера.',
						'ГОЛ! [name] нанес удар, получился рикошет от кого-то в штрафной и мяч в сетке, вратарь был бессилен.',
						'ГОЛ! [name] классно завершает на втором этаже ударом под перекладину, вратарь оказался переигран. Отличный удар получился, браво!',
						'Это ГОЛ! После классного паса [name] с близкого расстояния вколачивает мяч в сетку!',
						'ГОЛ! [name] шикарно открылся в штрафной и нанес прицельный удар над вратарем в левый нижний угол.',
						'ГОЛ! [name] пробил из пределов штрафной и переиграл голкипера ударом в нижний угол ворот.',
						'Гол после стандарта мы с вами увидели. Угловой был разыгран, подсуетился в штрафной [name] и пробил голкипера точным ударом в правый угол ворот.',
						'ГОЛ! Прекрасная передача с фланга на [name] в штрафную, и тот, сохраняя хладнокровие пробил по центру ворот.',
						'[name] празднует великолепный гол! Он смог освободиться от опеки при подаче штрафного и точным ударом головой вколотил мяч в правый нижний угол ворот.',
						'ГОЛ! [name] меняет счет в сегодняшнем матче! Он принял мяч в штрафной и тут же пробил в левую "девятку" ворот.',
						'ГОЛ! После углового и отличной скидки от партнера [name] головой вколотил мяч в сетку.',
						'[name] перепрыгнул всю оборону и из пределов вратарской нанес точный удар головой в нижний угол ворот. У голкипера не было шансов среагировать.',
						'ГОЛ! [name] пробил в касание из штрафной в нижний левый угол ворот.',
						'ГОЛ! Отличный пас в штрафную, мяч получает [name] и на 100% использует свой момент нанося точный удар в левый угол ворот.',
						'Защитник остановил первую попытку, первый удар, но мяч отскочил, и [name] мощно вколотил его в левый угол ворот, рикошетом от штанги.',
						'Гол! Отлиная короткая передача на ход на [name], который пробил точно по центру ворот. Невероятный гол!',
						'[name] принимает мяч в штрафной и наносит идеальный удар по центру ворот.',
						'Фантастика! Люблю такие моменты! [name] отлично пробил с лёта издали и мяч влетел в левый угол ворот.'
					],
					autogoal: [
						'[name] пока главный неудачник матча, так как в свои ворота он только что забил гол.',
						'АВТОГОЛ! [name] переправил мяч в собственные ворота. Не повезло.',
						'Автогол. [name] пытался выбить мяч из штрафной, но случайно отправил его в правый угол собственных. Вратарь ничего не мог поделать.',
					],
					beforePenalty: [
						'Игрок должен был знать, что так вести себя на поле нельзя. Это слишком грубо, и судья свистит, сигнализируя, что было нарушение. Это одиннадцатиметровый. Шанс забить для команды',
						'Судя по свистку, который дал арбитр. Команда вознаграждена одиннадцатиметровым. Один из игроков будет бить. Посмотрим, что будет.',
						'Это одиннадцатиметровый! Шанс забить для команды!',
						'Арбитр все видел и дал свисток. Переговорив с помощником, арбитр назначил пенальти. Хороший шанс забить.',
						'Арбитр свистит фол и принял решение. Это пенальти!',
						'Нечестно сыграл игрок. Он придерживал своего оппонента. Одна сторона радуется, другая возмущается. Арбитр только что назначил пенальти.',
						'Какой грубый фол. Судья остановил игру и дал понять, что это неприемлемый уровень грубости. Это одиннадцатиметровый. Шанс забить для команды.',
						'Судья показывает на точку! Одиннадцатиметровый!'
					],
					penalty: [
						'ГОЛ! Голкипер среагировал на удар с пенальти в исполнении [name], но не смог отбить мяч, который залетел в правый нижний угол.',
						'ГОЛ! [name] выиграл битву характеров и уверенно переиграл вратаря, мощно пробив пенальти в левую часть ворот.',
						'ГОЛ! [name] отправил мяч под левую штангу мимо вытянутой руки вратаря.',
						'ГОЛ! [name] выиграл битву характеров и уверенно переиграл вратаря, мощно пробив пенальти в правый нижний угол.',
						'Гол! [name] отправил мяч по центру мимо вытянутой руки Альваро Фернандес.',
						'Гол! Вратарь среагировал на удар с пенальти в исполнении [name], но не смог отбить мяч, который залетел в правый нижний угол.',
						'Гол! [name] сделал разбег и сделал паузу, посмотрев на действия вратаря, а затем вколотил мяч с пенальти в левый нижний угол.'
					],
					replace: [
						'Замена будет сейчас произведена. [on] покинет поле, а вместо него выйдет [off].',
						'Время для замены. [on] покидает поле. [off] должен усилить игру.',
						'[on] выходит на поле. [off] уйдет, совсем медленно он передвигается по полю в последние минуты.',
						'А вот и замена. [on] сейчас уйдет с поля. Тренер дает последние наставления своему игроку, это [off] выходит на поле.',
						'[on] заменен, а на его место вступил [off].',
						'Время для замены. [on] заменен. [off] освежит игру.',
						'Вот и замена. [on] покинет игру, а свеженький [off] заступит вместо него.',
						'Время для замены. [on] выходит на поле, а [off] его покидает.',
						'[on] присоединяется к команде на поле, выйдя на замену. [off] заменен.',
						'Тренер сделал перестановку. [on] заменен, а не его место вступил [off].',
						'Тренер решил усилить игру заменой. [on] ушел с поля, а [off] будет усилять игру.',
						'Была сделана замена. [on] уходит, а выходит [off].',
						'Судья дает время для замены. [on] уходит, а выходит [off]. Посмотрим, что он привнесет в игру.',
						'[on] уйдет с поля, закончена для него игра. Его сменит [off].',
						'Замена в составе. [on] появится в составе. А [off] покинет игру.',
						'Вот и замена. [on] выйдет на поле, а кого же он заменит? Так, [off] уходит с поля!',
					],
					yellow: [
						'[name] заработал горчичник.',
						'[name] вымотал все нервы судье, и он берется наказать игрока.',
						'[name] получил предупреждение за очень грубую игру. Пусть радуется, что не красная.',
						'Заслуженный горчичник. Подкат был жесткий, и судья наказал заслуженно, [name] с предупреждением теперь.',
						'[name] получает карточку, судья матча наказал его желтой.',
						'Желтая! [name] наказан за свой фол.',
						'[name] награжден желтой карточкой за жесткую борьбу. Он, кажется, не согласен, но судья не принимает жалоб.',
						'Судья показывает желтую карточку, это [name] наказан за свое нарушение.',
						'Судья достает желтую карточку, [name] ее получил за грубую игру.',
						'[name] получил горчичник после атаки на соперника. Валентин Писарро был прав в своем решении.',
						'Судья показывает желтую карточку, это [name] наказан за свой грубый фол.',
						'[name] получил желтую карточку за свое нарушение. Выглядит он злым на рефери.',
						'[name] нарушил правила на желтую карточку.',
						'[name] получает желтую карточку. Не прав он был.',
					],
					red: [
						'[name] должен был быть осторожнее. Он сфолил грубо и получил вторую желтую карточку, значит - удален с поля.',
						'[name], кажется, не понял в тот раз, что получил желтую. Сейчас он грубо сыграл и рефери показал ему еще одну. Удаление игрока.',
						'КРАСНАЯ КАРТОЧКА! [name] грубо нарушил правила, и судья сегодняшнего матча не колеблясь отправляет его в раздевалку.',
						'Ох, боже ты мой... [name] играл сейчас не в футбол, а в какой-то боевой вид спорта. Красную карточку видит он перед собой. Подвел команду.',
						'В руках у рефери карточка. Красного цвета. [name] будет вынужден уйти с поля, так как она направлена на него.',
						'[name] сфолил и получил вторую желтую карточку, он покинет поле.',
						'[name] удален! Не выдержали нервы и заработал он свою вторую желтую. Ланс зарабатывает право на штрафной с хорошей точки.',
						'Это фол, который совершил [name]. Удивительно, но судья приходит и достает красную карточку. Это был очень сурово, игрок жалуется, но нет смысла, конечно. Решение было принято.',
					],
					finish: [
						'Игра закончена, арбитр дал свисток к концу матча.',
						'Вот и все на сегодня. Игра окончена.',
						'Арбитр поглядел на часы и решил закончить матч.'
					]
				},
			}
		},
		
		created() {
			
		},

		computed: {
			summary() {
				return this.getSummary().reverse();
			},
			isStarted() {
				if (this.matchEvent) {
					return isNaN(parseInt(this.matchEvent.status));
				}

				return true;
			},
			isFinished() {
				if (this.matchEvent) {
					return this.matchEvent.status === 'FT' || this.matchEvent.status === 'Finished';
				}
				
				return true;
			}
		},
		
		methods: {
			getRandomMessage(type, incident = null) {
				let localData = this.getLocalData();

				if (localData[this.id] && localData[this.id][this.getTypeName(type, incident)]) {
					if (incident) {
						return localData[this.id][this.getTypeName(type, incident)];
					} else {
						return localData[this.id][type];
					}
				} else if (!localData[this.id]) {
					localData[this.id] = {};
				}
				
				let index = this.getRandomIndex(this.messages[type]);
				
				let message = this.getMessage(incident, this.messages[type][index])
				
				if (incident) {
					localData[this.id][this.getTypeName(type, incident)] = message;
				} else {
					localData[this.id][type] = message;
				}
				
				localStorage.setItem('messages', JSON.stringify(localData));
				
				return message;
			},
			getRandomIndex(data) {
				return Math.floor(Math.random() * data.length);
			},
			getSummary() {
				let summary;
				
				if (this.matchEvent) {
					summary = this.matchEvent.game_events;
				} else {
					summary = this.match.summary.concat(this.initSubstitutions());
				}
				
				summary = summary.concat(this.initCorner(summary));

				summary = _.sortBy(summary, function (item) {
					return Number(item.minute);
				});
				
				return summary;
			},
			initSubstitutions() {
				let arr = [];

				let data = null;

				if (this.match.substitutions.localteam) {
					data = this.match.substitutions.localteam.substitution;

					if (!Array.isArray(this.match.substitutions.localteam.substitution)) {
						data = [data];
					}

					data.forEach((item) => {
						item.team = 'localteam';
						item.substitution = true;
						item.type = 'subst';
						arr.push(item);
					});
				}

				if (this.match.substitutions.visitorteam) {
					data = this.match.substitutions.visitorteam.substitution;

					if (!Array.isArray(this.match.substitutions.visitorteam.substitution)) {
						data = [data];
					}

					data.forEach((item) => {
						item.team = 'visitorteam';
						item.substitution = true;
						item.type = 'subst';
						arr.push(item);
					});
				}

				return arr;
			},
			getLocalData() {
				let data = JSON.parse(localStorage.getItem('messages'));
				
				if (!data) {
					data = {};
					localStorage.setItem('messages', JSON.stringify(data))
				}
				
				return data;
			},
			getMessage(incident, message) {
				let matchData = {
					name: '',
					on: '',
					off: '',
					team: ''
				};
				
				if (incident) {
					if (incident.substitution) {
						matchData.on = incident.on;
						matchData.off = incident.off;
					} else if (incident.type === 'subst') {
						matchData.on = incident.player;
						matchData.off = incident.assist;
					} else if (incident.type === 'corner') {
						matchData.team = incident.team;
					} else {
						if (incident.name) {
							matchData.name = incident.name;
						} else if (incident.player) {
							matchData.name = incident.player.replace('(pen.)', '').replace('(o.g.)', '');
						}
					}
				}
				
				let replace = {
					'[name]': matchData.name,
					'[off]': matchData.off,
					'[on]': matchData.on,
					'[team]': matchData.team,
				};
				
				for (let replacement in replace) {
					message = message.replace(replacement, replace[replacement]);
				}

				return message;
			},
			getTypeName(type, incident) {
				if (incident) {
					let id = incident.eventid ? incident.eventid : incident.id;
					
					if (incident.on_id) {
						id = incident.on_id + '-' + incident.off_id;
					}
					
					return type + '-' + id;
				}
				return type;
			},
			initCorner(summary) {
				this.randomNumbers = [0];
				
				let minutes = {
					min: 2,
					max: 5
				};
				
				if (this.matchEvent && this.matchEvent.timer) {
					minutes.max = this.matchEvent.timer;
				} else if (this.match && this.match.timer) {
					minutes.max = this.match.timer;
				}
				
				summary.forEach((item) => {
					if (Number(item.minute) > minutes.max) {
						minutes.max = Number(item.minute);
					}
				});
				
				let arr = [];

				let data = JSON.parse(localStorage.getItem('corners'));
				if (!data) {
					data = {};
					localStorage.setItem('corners', JSON.stringify(data))
				}
				
				if (!data[this.id]) {
					data[this.id]  = {};
				}
				
				if (this.match && this.match.stats) {
					for (let i = 0; i < this.match.stats.localteam.corners.total; i++) {
						let minute = this.randomInteger(minutes.min, minutes.max);
						
						if (data[this.id]['corner-local-' + i]) {
							minute = data[this.id]['corner-local-' + i];
						} else {
							data[this.id]['corner-local-' + i] = minute;
						}
						
						arr.push({
							team: this.match.localteam.name,
							minute: minute,
							id: 'corner-local-' + i,
							type: 'corner',
						});
					}
					
					for (let i = 0; i < this.match.stats.visitorteam.corners.total; i++) {
						let minute = this.randomInteger(minutes.min, minutes.max);

						if (data[this.id]['corner-visitor-' + i]) {
							minute = data[this.id]['corner-visitor-' + i];
						} else {
							data[this.id]['corner-visitor-' + i] = minute;
						}
						
						arr.push({
							team: this.match.visitorteam.name,
							minute: minute,
							id: 'corner-visitor-' + i,
							type: 'corner'
						});
					}

					localStorage.setItem('corners', JSON.stringify(data));
				}
				
				return arr;
			},
			randomInteger(min, max) {
				let rand = min - 0.5 + Math.random() * (max - min + 1);
				
				rand = Math.round(rand);
				
				if (this.randomNumbers.length > 50) {
					return rand;
				}
				
				let closest = this.closest(this.randomNumbers, rand);

				if (this.randomNumbers.includes(rand) || (Math.abs(closest - rand) < 2)) {
					return this.randomInteger(min, max);
				} else {
					this.randomNumbers.push(rand);
				}
				
				return rand;
			},
			closest(arr, goal) {
				return arr.reduce(function(prev, curr) {
					return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.match-text_item {
		border-bottom: 1px solid #ccc;
		display: flex;
		align-items: center;
	}
	.match-text_info {
		width: 80px;
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.match-text_data {
		font-size: 14px;
		border-left: 1px solid #ccc;
		padding: 8px 0 8px 15px;
		
		&.__goal {
			color: #c00;
			font-weight: 500;
		}
	}
	.match-text_info-time {
		font-size: 14px;
		margin-right: 10px;
		width: 25px;
		flex-shrink: 0;
		text-align: center;
	}
	.timeline_ball, .timeline_arrow {
		width: 20px;
		height: 20px;
		margin: 0;
	}
	.timeline_cart {
		width: 20px;
		height: 25px;
		margin: 0;
	}
	.timeline_whistle {
		background: #fff url('/img/whistle.png') no-repeat;
		width: 20px;
		height: 15px;
		background-size: contain;
	}
	.timeline_shirt {
		background: #fff url('/img/dress-blue.gif') no-repeat;
		width: 25px;
		height: 22px;
		background-size: contain;
	}
	.timeline_corner {
		background: #fff url('/img/corner.svg') no-repeat;
		background-size: contain;
		width: 20px;
		height: 20px;
	}
	.timeline_warning {
		width: 20px;
		height: 20px;
		margin: 0;
	}
</style>