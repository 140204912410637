<template>
	<div class="match">
		<div class="wrapper" v-if="match">
			<div class="match_row">
				<div class="match_right">
					<div class="tab">
						<div class="live-command">
							<div class="live-command_info">
								<div>{{ getDateTime(match.date, match.time) }}</div>
								
								<div class="__bold">
									<div class="live-command_res">
										<div v-if="match.status === 'Finished'">Завершен</div>
										<div v-else-if="match.status === 'Not Started'">Матч не начался</div>
										<div v-else-if="match.status === 'Cancl.'">Отменен</div>
										<div v-else-if="match.status === 'Postp.'">Отложен</div>
									</div>
								</div>
							</div>
							
							<div class="live-command_row">
								<router-link :to="{name: 'player-base', params: {id: player1.id, sport: 'tennis'}}" class="live-command_image">
									<img :src="$hostname + '/goalserve/tennis/player/image?id=' + player1.id" alt="">
									<span>{{ player1.name }}</span>
								</router-link>
								
								<div class="live-command_content">
									<div class="live-command_score">
										{{ player1.totalscore }} -&nbsp; {{ player2.totalscore }}
									</div>
								</div>
								
								<router-link :to="{name: 'player-base', params: {id: player2.id, sport: 'tennis'}}" class="live-command_image">
									<img :src="$hostname + '/goalserve/tennis/player/image?id=' + player2.id" alt="">
									<span>{{ player2.name }}</span>
								</router-link>
							</div>
						</div>
						
						<div v-if="windowWidth > 860" class="match-block">
							<div class="match-block_row">
								<div class="match-block_left">
									<div v-if="match" class="match-block_item">
										<div class="heading">Обзор</div>
										
										<timeline v-if="match" :match="match"></timeline>
									</div>
									
									<div v-if="h2h" class="match-block_item">
										<div class="heading">Последние матчи</div>
										
										<last-results v-if="h2h" :match="match" :results="h2h"></last-results>
									</div>
								</div>
								<div class="match-block_right">
									<div v-if="statistic" class="match-block_item">
										<div class="heading">Статистика</div>
										
										<statistic v-if="statistic" :data="statistic"></statistic>
									</div>
									<div v-if="h2h" class="match-block_item">
										<div class="heading">Личные встречи</div>
										
										<total-results v-if="h2h" :h2h="h2h" :match="match"></total-results>
									</div>
								</div>
							</div>
						</div>
						
						<tab v-else :headings="{timeline: 'Обзор',  h2h: 'Последние матчи'}" active-key="timeline">
							<template v-slot:timeline>
								<timeline v-if="match" :match="match"></timeline>
							</template>
							<template v-slot:stat>
								<statistic v-if="statistic" :data="statistic"></statistic>
								<div v-else class="info">Нет данных</div>
							</template>
							<template v-slot:h2h>
								<total-results v-if="h2h" :h2h="h2h" :match="match"></total-results>
								
								<br>
								<br>
								
								<last-results v-if="h2h" :match="match" :results="h2h"></last-results>
							</template>
						</tab>
					</div>
				</div>
			</div>
		</div>
		
		<div v-else-if="loading" class="info-text"></div>
		
		<div class="wrapper" v-else>
			<div class="info __pt0">Информация обновляется</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment'

	import Tab from "@/components/Tab/Tab";
	import Statistic from "@/components/Sports/Tennis/Components/Match/Statistic";
	import Timeline from "@/components/Sports/Tennis/Components/Match/Timeline";
	import LastResults from "@/components/Sports/Tennis/Components/Match/LastResults";
	import TotalResults from "@/components/Sports/Tennis/Components/Match/TotalResults";
	import $ from "jquery";

	export default {
		components: {
			Statistic,
			Tab,
			Timeline,
			LastResults,
			TotalResults
		},

		data() {
			return {
				match: null,
				h2h: null,
				loading: true,
				statistic: null,
				windowWidth: $(window).width(),
			}
		},

		created() {
			let id = this.$route.params.id;

			this.axios.get('/goalserve/tennis/league/events?id=' + id)
			.then(({data}) => {
				this.match = data.res ? data.res[0].events[0] : null;
				this.loading = false;

				if (!this.match) return;

				this.axios.get('/goalserve/tennis/scores/h2h?player1=' + this.match.player[0].id + '&player2=' + this.match.player[1].id)
				.then(({data}) => {
					this.h2h = data.res;
				});
			});

			this.axios.get('/goalserve/tennis/league/gamestat')
			.then(({data}) => {
				this.statistic = data.res;
			});

			$(window).on('resize', () => {
				this.windowWidth = $(window).width()
			});
		},

		computed: {
			player1() {
				if (!this.match) {
					return null;
				}
				return this.match.player[0];
			},
			player2() {
				if (!this.match) {
					return null;
				}
				return this.match.player[1];
			}
		},
		methods: {
			getDateTime(date, time, format = true) {
				let method = getTimeZoneNum() >= 0 ? 'add' : 'subtract';

				date = date.split('.');
				date = date[2] + '-' + date[1] + '-'+ date[0];

				let datetime = moment(date + 'T' + time)[method](getTimeZoneNum(), 'hours');

				return format ? datetime.locale('ru').format('DD MMMM Y, dddd HH:mm') : datetime;
			},
		}
	}
</script>