<template>
	<div>
		<div class="banner"></div>
		
		<header class="header">
			<div class="wrapper">
				<div class="header_row">
					<router-link to="/" class="header_logo">
						<img src="/img/logo.png" alt="">
					</router-link>
					
					<div class="header_content">
						<ul class="header_nav">
							<li><router-link :to="{name: 'home'}" exact>Главная</router-link></li>
							<li><router-link style="color:rgb(0 255 255);" :to="{name: 'euro'}" exact>Евро 2020</router-link></li>
							<li><router-link :to="{name: 'live', params: {sport: 'soccer'}}">Матч-центр</router-link></li>
							<li><router-link :to="{name: 'news'}">Новости</router-link></li>
							<li><router-link :to="{name: 'videos'}">Видео</router-link></li>
							<li><router-link :to="{name: 'tickets'}">Билеты и ТВ</router-link></li>
							<li><router-link :to="{name: 'academy'}">Академия</router-link></li>
							<li><router-link :to="{name: 'rank'}" exact>Рейтинг</router-link></li>
							<li><router-link :class="{'router-link-active': $route.name in ['competition', 'transfers']}" :to="{name: 'competitions'}">Трансферы</router-link></li>
						</ul>
						
						<div class="header_profile">
							<!--<div class="header_reg">
								<a href="">Регистрация</a>
								<a href="">Вход</a>
							</div>-->
						</div>
					</div>
					
					<div class="header_button"><i></i><i></i><i></i></div>
				</div>
			</div>
		</header>
	</div>
</template>

<script>
	import $ from 'jquery'
	
	export default {
		watch: {
			'$route'() {
				$('.header_content').removeClass('active');
			}
		}
	}
</script>

<style scoped>

</style>