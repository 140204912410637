<template>
	<div class="match_items">
		<div class="match_item" v-for="standingGroup in standings" :key="standingGroup.name">
			<div class="match_item-content">
				<div class="match_item-header active" v-if="!hideHeading">
					<span>{{ standingGroup.name }}</span>
					<i class="ico icon-side-arrow"></i>
				</div>
				<div class="match_item-data active">
					<div class="live-table_content">
						<div class="live-table_row">
							<div class="live-table_col __1"></div>
							<div class="live-table_col __2">
								<div class="live-table_name">Команда</div>
							</div>
							<div class="live-table_col __3">
								<div class="live-table_name">И</div>
							</div>
							<div class="live-table_col __3 __mobile-hidden">
								<div class="live-table_name">В</div>
							</div>
							<div class="live-table_col __3 __mobile-hidden">
								<div class="live-table_name">Н</div>
							</div>
							<div class="live-table_col __3 __mobile-hidden">
								<div class="live-table_name">П</div>
							</div>
							<div class="live-table_col __3">
								<div class="live-table_name">Г</div>
							</div>
							<div class="live-table_col __3">
								<div class="live-table_name">О</div>
							</div>
							<div class="live-table_col __5" v-if="showForm">
								<div class="live-table_name">Матчи</div>
							</div>
						</div>
						<div class="live-table_data">
							<div class="live-table_row" v-for="(standing, index) in sortedRows(standingGroup.rows)" :key="'standing' + standing.id">
								<span v-if="listType === 'overall'" class="live-table_row-after" :style="'background: ' + getColor(standing)"></span>
								
								<div class="live-table_col __1">
									<div class="live-table_num" v-if="listType === 'overall'">{{ standing.pos }}</div>
									<div class="live-table_num" v-else>{{ index + 1 }}</div>
								</div>
								<div class="live-table_col __2">
									<div class="live-table_main">
										<div class="live-table_pic">
											<img :src="$hostname + '/goalserve/soccer/team_image/' + standing.id" alt="">
										</div>
										
										<router-link :to="{ name: 'team', params: {id: standing.id, sport: 'soccer'} }" class="live-table_name-main">
											{{ standing.name }}
										</router-link>
									</div>
								</div>
								<div class="live-table_col __3">
									<div class="live-table_name-2">
										{{ standing[listType].gp }}
									</div>
								</div>
								<div class="live-table_col __3 __mobile-hidden">
									<div class="live-table_name-2">
										{{ standing[listType].w }}
									</div>
								</div>
								<div class="live-table_col __3 __mobile-hidden">
									<div class="live-table_name-2">
										{{ standing[listType].d }}
									</div>
								</div>
								<div class="live-table_col __3 __mobile-hidden">
									<div class="live-table_name-2">
										{{ standing[listType].l }}
									</div>
								</div>
								<div class="live-table_col __3">
									<div class="live-table_name-2">
										{{ standing[listType].gs }}
									</div>
								</div>
								<div class="live-table_col __3">
									<div class="live-table_name-2">
										{{ checkScore(parseInt(standing[listType].w * 3) + parseInt(standing[listType].d)) }}
									</div>
								</div>
								<div class="live-table_col __5" v-if="showForm">
									<div class="live-table_dots">
										<span v-for="(letter, index) in standing.recent_form" :key="index" :style="'background-color:' + getFormColor(letter)"></span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div v-if="!hideDescription">
			<div class="live-table_descr" v-if="!showForm">
				<p><span style="background: rgb(0, 70, 130)"></span> Лига Чемпионов (групповая стадия)</p>
				<p><span style="background: rgb(63, 203, 255);"></span> Лига Чемпионов (квалификация)</p>
				<p><span style="background: rgb(255, 100, 30)"></span> Лига Европы (квалификация)</p>
				<p><span style="background: rgb(189, 0, 0)"></span> Зона вылета</p>
			</div>
			
			<div class="live-table_descr" v-else>
				<p><span style="background: rgb(58, 181, 74); border-radius: 50%"></span>Победа</p>
				<p><span style="background: rgb(255, 211, 100); border-radius: 50%"></span>Ничья</p>
				<p><span style="background: rgb(254, 121, 19); border-radius: 50%"></span> Поражение</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['data', 'hideHeading', 'type', 'showForm', 'hideDescription'],
		
		computed: {
			standings() {
				return this.data;
			},
		},
		
		data() {
			return {
				listType: 'overall'
			}
		},
		
		created() {
			this.listType =  this.type ? this.type : 'overall'
		},

		methods: {
			getColor(standing) {
				let colors = {
					'Relegation Round': '#bd0000',
					'Championship Round': '#004682',
					'8th Finals': '#004682',
					'16th Finals': '#004682',
					'UEFA Europa League': '#3fcbff',
					'Promotion - Champions League (Group Stage)': '#004682',
					'Promotion - Champions League (Qualification)': '#3fcbff',
					'Promotion - Europa League (Group Stage)': '#3fcbff',
					'Promotion - Europa Conference League (Qualification)': '#ff641e',
					'Promotion - Europa League (Qualification)': '#ff641e',
					'Relegation - Championship': '#bd0000',
					'Bundesliga (Relegation)': '#bd0000',
					'Relegation - 2. Bundesliga': '#ff4141',
					'Relegation - LaLiga2': '#bd0000',
					'Relegation - Serie B': '#bd0000',
					'Premier League (Relegation)': '#bd0000',
					'Relegation - FNL': '#bd0000',
					'Ligue 1 (Relegation)': '#ff4141',
					'Relegation - Ligue 2': '#bd0000',
					'CONMEBOL Libertadores': '#004682',
					'CONMEBOL Libertadores Qualifiers': '#3fcbff',
					'CONMEBOL Sudamericana': '#ff641e',
					'Final Series': '#004682',
					'Final Series Play-offs': '#3fcbff',
				};

				return colors[standing.description] ? colors[standing.description] : '';
			},
			getFormColor(letter) {
				let colors = {
					'W': '#3ab54a',
					'L': '#fe7913',
					'D': '#ffd364',
				};
				return colors[letter] ? colors[letter] : '';
			},
			sortedRows(rows) {
				if (this.listType === 'overall') {
					return rows;
				}
				
				rows = JSON.parse(JSON.stringify(rows));
				
				return rows.sort((a, b) => {
					let totalA = parseInt(a[this.listType].w * 3) + parseInt(a[this.listType].d);
					let totalB = parseInt(b[this.listType].w * 3) + parseInt(b[this.listType].d);

					return totalA < totalB ? 1 : -1;
				});

				return rows;
			},
			checkScore(score) {
				return isNaN(score) ? '' : score;
			}
		}
	}
</script>

<style scoped>

</style>