<template>
	<div class="match">
		<div class="wrapper" v-if="match">
			<div class="match_row">
				<div class="match_right">
					<div class="tab">
						<div class="live-command">
							<div class="live-command_info">
								<div>{{ getDateTime(match.date, match.time) }}</div>
								<div class="__bold">
									<div class="live-command_res">
										<div v-if="match.status === 'Finished'">Завершен</div>
										<div v-else-if="match.status === 'Not Started'">Матч не начался</div>
										<div v-else-if="match.status === 'Cancl.'">Отменен</div>
										<div v-else-if="match.status === 'Postp.'">Отложен</div>
										<div v-else-if="match.status">{{ getStatus(match.status) }}</div>
									</div>
								</div>
							</div>
							
							<div class="live-command_row">
								<router-link :to="{name: 'team-hockey', params: {id: match.home_id, sport: sport, league: league}}" class="live-command_image">
									<img :src="$hostname + '/goalserve/hockey/team_image/' + match.home_id" alt="">
									<span>{{ match.home_name }}</span>
								</router-link>
								
								<div class="live-command_content">
									<div class="live-command_score">
										{{ match.home_score }} -&nbsp; {{ match.away_score }}
									</div>
								</div>
								
								<router-link :to="{name: 'team', params: {id: match.away_id}}" class="live-command_image">
									<img :src="$hostname + '/goalserve/hockey/team_image/' + match.away_id" alt="">
									<span>{{ match.away_name }}</span>
								</router-link>
							</div>
						</div>
						
						<div class="match-block" v-if="windowWidth > 860">
							<div class="match-block_row">
								<div class="match-block_left">
									<div class="match-block_item">
										<div class="heading">Трансляция матча</div>
										<timeline v-if="match" :match="match" :sport="sport"></timeline>
										<div class="info __mb-20" v-if="match.status === 'Not Started'">Трансляция еще не началась</div>
									</div>
								</div>
								<div class="match-block_right">
									<div class="match-block_item" v-if="!h2hLoading">
										<div class="heading">Последние матчи</div>
										<last-results v-if="h2h" :match="match" :results="h2h"></last-results>
									</div>
									<div v-else class="info-text"></div>
									
									<div class="match-block_item" v-if="!h2hLoading">
										<div class="heading">Личные встречи</div>
										<total-results v-if="h2h" :h2h="h2h" :match="match"></total-results>
									</div>
									<div v-else class="info-text"></div>
								</div>
							</div>
						</div>
						<tab v-else :headings="{timeline: 'Обзор',  h2h: 'Последние матчи'}" active-key="timeline">
							<template v-slot:timeline>
								<timeline v-if="match" :match="match" :sport="sport"></timeline>
								<div class="info __mb-20" v-if="match.status === 'Not Started'">Трансляция еще не началась</div>
							</template>
							<template v-slot:stat>
								<statistic v-if="match.stats" :data="match.stats"></statistic>
							</template>
							<template v-slot:lineups>
								<lineups :match="match" v-if="match.lineups"></lineups>
							</template>
							<template v-slot:h2h>
								<total-results v-if="h2h" :h2h="h2h" :match="match"></total-results>
								<br><br>
								<last-results v-if="h2h" :match="match" :results="h2h"></last-results>
							</template>
						</tab>
					</div>
				</div>
			</div>
		</div>
		
		<div v-else-if="loading" class="info-text"></div>
		
		<div class="wrapper" v-else>
			<div class="info __pt0">Информация обновляется</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment'
	
	import Tab from "../../../Tab/Tab";
	import Statistic from "../Components/Match/Statistic";
	import Lineups from "../Components/Match/Lineups";
	import Timeline from "../Components/Match/Timeline";
	import LastResults from "../Components/Match/LastResults";
	import TotalResults from "../Components/Match/TotalResults";
	import $ from "jquery";
	
	export default {
		components: {
			Tab,
			Statistic,
			Lineups,
			Timeline,
			LastResults,
			TotalResults
		},
		
		data() {
			return {
				match: null,
				sport: this.$route.params.sport,
				league: this.$route.params.league,
				day_diff: this.$route.query.day_diff,
				h2h: null,
				loading: true,
				windowWidth: $(window).width(),
				h2hLoading: true
			}
		},
		
		created() {
			let id = this.$route.params.id;
			
			this.axios.get('/goalserve/hockey/league/events?static_id=' + id + '&day_diff=' + this.day_diff + '&league_id=' + this.league)
				.then(({data}) => {
					this.match = data.res ? data.res[0].events[0] : null;
					this.loading = false;
					
					this.axios.get('/goalserve/hockey/h2h?team1=' + this.match.home_id + '&team2=' + this.match.away_id)
						.then(({data}) => {
							this.h2h = data.res;
							
							this.h2hLoading = false;
						});
				});

			$(window).on('resize', () => {
				this.windowWidth = $(window).width()
			});
		},
		methods: {
			getDateTime(date, time, format = true) {
				let method = getTimeZoneNum() >= 0 ? 'add' : 'subtract';

				date = date.split('.');
				date = date[2] + '-' + date[1] + '-'+ date[0];

				let datetime = moment(date + 'T' + time)[method](getTimeZoneNum(), 'hours');

				return format ? datetime.locale('ru').format('DD MMMM Y, dddd HH:mm') : datetime;
			},
			getStatus(status) {
				let data = {
					'1st Period': '1-й период',
					'2nd Period': '2-й период',
					'3rd Period': '3-й период',
					'Finished': 'Завершен',
					'Break Time': 'Перерыв',
					'Cancelled': 'Отменен',
					'After Penalties': 'После буллитов',
					'After Over Time': 'После ОТ',
					'Postponed': 'Перенесен',
					'Not Started': 'Не нач.',
					'Awarded': 'Тех. Поражение'
				};
				
				return data[status] ? data[status] : status;
			}
		}
	}
</script>