<template>
	<div class="articles">
		<div class="articles_row" v-if="items.length">
			<div v-for="article in items" :key="article.id" class="articles_col">
				<router-link :to="{name: 'article', params: {id: article.id}}" class="articles_item">
					<img :src="article.image_ref" alt="">
					
					<div class="articles_content">
						<div>
							<div class="articles_title">
								<p>{{ article.title }}</p>
							</div>
							
							<div class="articles_text" v-html="getText(article.content)"></div>
						</div>
						
						<div class="main_news-tags">
							<router-link :to="{name: 'news', query: {tag: tag}}" v-for="tag in article.tags" :key="tag">{{ tag  }}</router-link>
						</div>
					</div>
				</router-link>
			</div>
		</div>
		
		<div v-if="loading" class="info-text" style="padding-top: 20px;"></div>
		<div v-else class="button-block" :class="['__' + this.id]">
			<a href="" class="button" @click.prevent="showMore">Показать еще</a>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';

	export default {
		props: ['exclude', 'id', 'sport'],
		
		data() {
			return {
				tag: this.$route.query.tag || null,
				items: [],
				page: 1,
				limit: 30,
				loading: false,
				className: '.main-news_col.__' + this.id
			}
		},

		created() {
			this.initNews(this.page++);

			document.addEventListener('scroll', () => {
				if (this.isOnScreen($('.button-block.__' + this.id))) {
					$('.button-block.__' + this.id + ' a').get(0).click();
				}
			})
		},

		methods: {
			isOnScreen(elem) {
				if (!$(elem).length) {
					return;
				}
				var docViewTop = $(window).scrollTop();
				var docViewBottom = docViewTop + $(window).height();

				var elemTop = $(elem).offset().top;
				var elemBottom = elemTop + $(elem).height();

				return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
			},
			initNews(page) {
				if (this.loading) return;

				this.loading = true;

				let params = {
					limit: this.limit,
					page: page,
				};

				if (this.tag) {
					params.tag = this.tag;
				}
				if (this.sport) {
					params.sport = this.sport;
				}
				if (this.exclude) {
					params.exclude = this.exclude;
				}

				this.axios.get('/api/articles', {params: params})
					.then(({data}) => {
						this.items = this.items.concat(data.data);
	
						this.loading = false;
					});
			},
			getTime(time) {
				return moment(time).locale('ru').format('D MMMM Y, HH:mm')
			},

			updateData(page) {
				this.initNews(page);
			},
			updateSport(sport) {
				this.sport = sport;

				this.updateData(1);
			},
			getText(text) {
				let nohtml = text.replace(/(<([^>]+)>)/gi, "");
				
				return nohtml.split('.')[0] + '...';
			},
			showMore() {
				this.updateData(this.page++);
			}
		}
	}
</script>