import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from "../views/Home";
import League from "../views/League";

import Match from "../views/Match";
import MatchTennis from '../components/Sports/Tennis/Pages/MatchPage';
import MatchHockeyResult from "../components/Sports/Hockey/Pages/MatchPageResult";
import MatchMma from "../components/Sports/Mma/Pages/MatchPage";

import Team from "../views/Team";
import TeamHockey from "../components/Sports/Hockey/Pages/TeamPage";
import Player from "../views/Player";

import News from '../views/News.vue'
import NewsItem from '../views/NewsItem'

import Articles from '../views/Articles'
import Article from '../views/Article'

import Videos from "../views/Videos";

import Academy from "../views/Academy";
import AcademyPage from "../views/AcademyPage";

import Rank from "../views/Rank";

import Transfers from "../views/Transfers";

import Live from "../views/Live";

import TennisRank from '../components/Sports/Tennis/Pages/TennisRankPage';
import TennisLeague from '../components/Sports/Tennis/Pages/LeaguePage';

import Tickets from "../views/Tickets";

import EU2020 from "../views/EU2020";

Vue.use(VueRouter);

const routes = [
	{path: '/live/:sport', name: 'live', component: Live},

	// Tennis rank
	{path: '/rank/tennis/:type', name: 'tennis-rank', component: TennisRank},
	{path: '/category/:sport/:type', name: 'league-tennis', component: TennisLeague},

	// League
	{path: '/league/:sport/:id', name: 'league', component: League},

	// Match
	{path: '/match/hockey/:league/:id', name: 'match-hockey', component: MatchHockeyResult},
	{path: '/event/tennis/:id', name: 'event-tennis', component: MatchTennis},
	{path: '/event/:sport/:league/:id', name: 'event', component: Match},
	{path: '/event/mma/:id', name: 'event-mma', component: MatchMma},

	// Team
	{path: '/team/hockey/:id', name: 'team-hockey', component: TeamHockey},
	{path: '/team/:sport/:id', name: 'team', component: Team},

	// Player
	{ path: '/player/:sport/:id', name: 'player-base', component: Player },
	{ path: '/player/:id', name: 'player', component: Player },

	// News
	{path: '/news', name: 'news', component: News},
	{path: '/news/:id', name: 'news-item', component: NewsItem},

	// Articles
	//{path: '/articles', name: 'articles', component: Articles},
	{path: '/articles/:id', name: 'article', component: Article},

	// Videos
	{path: '/videos', name: 'videos', component: Videos},

	// Rank
	{path: '/rank', name: 'rank', component: Rank},

	// Rank
	{path: '/competitions', name: 'competitions', component: Transfers},
	{path: '/competitions/:id', name: 'competition', component: Transfers},
	{path: '/transfers/:id/:club', name: 'transfers', component: Transfers},
	
	// Academy
	{path: '/academy', name: 'academy', component: Academy},
	{path: '/academy/:id', name: 'academy-page', component: AcademyPage},
	
	// Tickets
	{path: '/tickets', name: 'tickets', component: Tickets},
	
	// Euro
	{path: '/euro', name: 'euro', component: EU2020},

	{path: '/', name: 'home', component: Home},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

export default router