<template>
	<div id="app">
		<header-block></header-block>
		
		<div class="wrapper">
			<div class="content">
				<div class="content_main">
					<router-view :key="$route.fullPath" />
					
					<footer-block></footer-block>
				</div>
			</div>
		</div>
		
		<div class="popup-video" v-if="showVideo">
			<div class="popup-video_bg" @click="showVideo = false"></div>
			<div class="popup-video_video">
				<div class="popup-video_title"><span @click="showVideo = false" ></span>{{ video.title }}</div>
				<div class="popup-video_video-item" v-html="video.embed"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import Header from "./components/Header";
	import Footer from "./components/Footer";
	
	export default {
		name: 'App',
		
		components: {
			HeaderBlock: Header,
			FooterBlock: Footer
		},
		
		data() {
			return {
				showVideo: false,
				video: ''
			}
		},
		
		created() {
			events.$on('openVideo', (video) => {
				this.showVideo = true;
				this.video = video;
			});
		}
	}
</script>

<style lang="less">
	@import "assets/style";
	@import "assets/less/main";
</style>