<template>
	<div>
		<div v-if="results" class="total-results">
			<div class="total-results_header">
				<div class="total-results_command">
					<img :src="$hostname + '/goalserve/soccer/team_image/' + home.id" alt="">
					<span>{{ home.name }}</span>
				</div>
				<div class="total-results_command">
					<span>{{ away.name }}</span>
					<img :src="$hostname + '/goalserve/soccer/team_image/' + away.id" alt="">
				</div>
			</div>
			
			<div class="total-results_content">
				<div class="total-results_info"><span>{{ results.count }}</span> матчей сыграно</div>
				
				<div class="total-results_row">
					<div class="total-results_col">
						<div class="total-results_icon">
							<img src="/img/soccer-ball.svg" width="30" alt="">
							<div class="total-results_text">Голы</div>
						</div>
						<div class="total-results_item __1">{{ results.team1Goals }}</div>
						<div class="total-results_logo">
							<img :src="$hostname + '/goalserve/soccer/team_image/' + home.id" alt="">
						</div>
					</div>
					<div class="total-results_col __bg-2" :class="{__transform: results.team1Wins > results.team2Wins}">
						<div class="total-results_icon">
							<img src="/img/trophy.svg" width="30" alt="">
							<div class="total-results_text">Победы</div>
						</div>
						
						<div class="total-results_item __2">
							{{ results.team1Wins }}
						</div>
						<div class="total-results_logo">
							<img :src="$hostname + '/goalserve/soccer/team_image/' + home.id" alt="">
						</div>
					</div>
					<div class="total-results_col __bg">
						<div class="total-results_icon">
							<img src="/img/handshake.svg" width="30" alt="">
							<div class="total-results_text">Ничьи</div>
						</div>
						
						<div class="total-results_item __3">
							{{ results.draw}}
						</div>
					</div>
					<div class="total-results_col __bg-2" :class="{__transform: results.team2Wins > results.team1Wins}">
						<div class="total-results_icon">
							<img src="/img/trophy.svg" width="30" alt="">
							<div class="total-results_text">Победы</div>
						</div>
						
						<div class="total-results_item __4">
							{{ results.team2Wins }}
						</div>
						<div class="total-results_logo __right">
							<img :src="$hostname + '/goalserve/soccer/team_image/' + away.id" alt="">
						</div>
					</div>
					<div class="total-results_col">
						<div class="total-results_icon">
							<img src="/img/soccer-ball.svg" width="30" alt="">
							<div class="total-results_text">Голы</div>
						</div>
						
						<div class="total-results_item __5">{{ results.team2Goals }}</div>
						<div class="total-results_logo __right">
							<img :src="$hostname + '/goalserve/soccer/team_image/' + away.id" alt="">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['h2h', 'match', 'matchEvent'],
		
		computed: {
			home() {
				if (!this.match) {
					return {
						id: this.matchEvent.home_id,
						name: this.matchEvent.home_name,
					}
				}
				return this.match.localteam;
			},
			away() {
				if (!this.match) {
					return {
						id: this.matchEvent.away_id,
						name: this.matchEvent.away_name,
					}
				}
				return this.match.visitorteam;
			},
			results() {
				if (!Array.isArray(this.h2h.top50.match)) {
					return null;
				}
				
				let data = {
					count: this.h2h.top50.match.length || 0,
					team1Goals: 0,
					team2Goals: 0,
					draw: 0,
					team1Wins: 0,
					team2Wins: 0
				};


				let team1 = this.h2h.top50.match[0].team1;
				let team2 = this.h2h.top50.match[0].team2;
				
				if (this.home.name !== team1) {
					team1 = this.h2h.top50.match[0].team2;
					team2 = this.h2h.top50.match[0].team1;
				}
				
				this.h2h.top50.match.forEach((item) => {
					let team1Score = item.team1 === team1 ? item.team1_score : item.team2_score;
					let team2Score = item.team2 === team2 ? item.team2_score : item.team1_score;
					
					if (team1Score === team2Score) {
						data.draw++;
					}
					
					if (! isNaN(Number(team1Score))) {
						data.team1Goals += Number(team1Score);
					}
					if (! isNaN(Number(team2Score))) {
						data.team2Goals += Number(team2Score);
					}
					
					if (Number(team1Score) > Number(team2Score)) {
						data.team1Wins++;
					}
					if (Number(team2Score) > Number(team1Score)) {
						data.team2Wins++;
					}
				});
				
				return data;
			}
		},
	}
</script>

<style scoped>

</style>