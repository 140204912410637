<template>
	<div>
		<div class="academy">
			<div class="wrapper">
				<div class="heading">Академия</div>
				
				<div class="rank_anno">
					<div class="rank-annotation ykmeqvo" style="margin:15px 0;">
						<div class="rank-annotation__group wmpx">
							Думаете, ставки – сложно и скучно? Академия ставок поможет вам разобраться и научит получать от спорта максимальный драйв!
						</div>
						
						<h3 class="nmxs rank-annotation__group">
							Чему вы научитесь
						</h3>
						
						<div class="wtjuk rank-annotation__group">
							<ul>
								<li>
									<h4>• Первая ставка</h4>
									<p>Проведем через регистрацию и персональную идентификацию. Поймете, как делать ставки на компьютере, телефоне и через терминал в клубе букмекера.</p>
								</li>
								<li>
									<h4>• Термины и виды ставок</h4>
									<p>Сумеете отличить линию от росписи, экспресс от системы, а прематч от лайва. Рассчитаете азиатскую фору и узнаете, чем x2 отличается от форы (0).</p>
								</li>
								<li>
									<h4>• Коэффициенты и шансы, маржа и ценность ставки</h4>
									<p>Узнаете, как работает букмекер. Научитесь переводить коэффициент в шансы, находить ценные ставки и считать маржу.</p>
								</li>
								<li>
									<h4>•	Бонусы и акции</h4>
									<p>Узнаете все секреты букмекерских промоакций и конкурсов. Поймете, что такое бесплатные ставки, как их получить при регистрации и как использовать.</p>
								</li>
								<li>
									<h4>•	Анализ спорта</h4>
									<p>Освоите анализ спортивной статистики, предматчевых новостей и коэффициентов. Почерпнете опыт профессиональных прогнозистов и спортивных экспертов.</p>
								</li>
								<li>
									<h4>•	Стратегии ставок и управления банком</h4>
									<p>Обучитесь игровым стратегиями и управлению банком так, чтобы получать максимум приятных эмоций от ставок.</p>
								</li>
								<li>
									<h4>•	Ставки на футбол, теннис, хоккей и баскетбол</h4>
									<p>Узнаете, какие ставки принимают на топовые виды спорта. Получите советы о том, как ставить на все популярные турниры.</p>
								</li>
								<li>
									<h4>•	Статистика ставок</h4>
									<p>Поймете, как вести статистику ставок, считать проходимость и прибыльность. Узнаете формулы ROI и YIELD, которыми пользуются опытные игроки.</p>
								</li>
								<li>
									<h4>•	Азы высшей математики и психологии в ставках</h4>
									<p>Разберетесь, как теория вероятностей способна помочь вам играть эффективнее. Раскроете тайны психологии в ставках.</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
				
				<div class="post-cards post-cards--vertical">
					<div class="post-card post-card--vertical w-animate w-animate--started" itemscope="" itemtype="http://schema.org/BlogPosting" data-animate-style="fadeinup" style="transform: translate3d(0px, 40px, 0px); transition-delay: 0.176041s; transition-duration: 1.5s; width: 100%; max-width: none">
						<div class="post-card__thumbnail">
							<a href="/academy/0">
								<img style="height: 250px; width: 100%; object-fit: cover" src="https://cdn.shortpixel.ai/client/q_glossy,ret_img,w_870,h_400/https://academbet.ru/wp-content/uploads/2020/07/bet-in-football-870x400.png" class="attachment-reboot_small size-reboot_small wp-post-image" alt="фора в ставках на спорт" itemprop="image" loading="lazy" decoding="async">
								<span class="post-card__category" itemprop="articleSection"><span itemprop="articleSection">Академия</span></span>
							</a>
						</div>
						<div class="post-card__title" itemprop="name">
							<span itemprop="headline"><a href="/academy/0">Основы ставок на Футбол</a></span>
						</div>
						<div class="post-card__description" itemprop="articleBody">Наиболее популярным видом спорта в беттинге является футбол – ежедневно проходят десятки матчей, поэтому у игроков есть возможность широкого выбора ставки</div>
						<meta itemprop="author" content="admin">
						<meta itemscope="" itemprop="mainEntityOfPage" itemtype="https://schema.org/WebPage" itemid="https://academbet.ru/chto-takoe-fora-v-stavkah-na-sport/" content="Что такое фора в ставках на спорт">
						<meta itemprop="dateModified" content="2021-03-07">
						<meta itemprop="datePublished" content="2020-09-28T19:15:35+03:00">
					</div>
					<div class="post-card post-card--vertical w-animate w-animate--started" itemscope="" itemtype="http://schema.org/BlogPosting" data-animate-style="fadeinup" style="transform: translate3d(0px, 40px, 0px); transition-delay: 0.176041s; transition-duration: 1.5s;">
						<div class="post-card__thumbnail">
							<a href="/academy/1">
								<img width="335" height="220" src="https://academbet.ru/wp-content/uploads/2020/09/fora-v-stavkah-main-335x220.jpg" class="attachment-reboot_small size-reboot_small wp-post-image" alt="фора в ставках на спорт" itemprop="image" loading="lazy" decoding="async">
								<span class="post-card__category" itemprop="articleSection"><span itemprop="articleSection">Академия</span></span>
							</a>
						</div>
						<div class="post-card__title" itemprop="name">
							<span itemprop="headline"><a href="/academy/1">Что такое фора в ставках на спорт</a></span>
						</div>
						<div class="post-card__description" itemprop="articleBody">Фора – один из самых популярных маркетов в ставках на спорт. Букмекеры стараются давать больше вариантов пари на гандикапы</div>
						<meta itemprop="author" content="admin">
						<meta itemscope="" itemprop="mainEntityOfPage" itemtype="https://schema.org/WebPage" itemid="https://academbet.ru/chto-takoe-fora-v-stavkah-na-sport/" content="Что такое фора в ставках на спорт">
						<meta itemprop="dateModified" content="2021-03-07">
						<meta itemprop="datePublished" content="2020-09-28T19:15:35+03:00">
					</div>
					<div class="post-card post-card--vertical w-animate w-animate--started" itemscope="" itemtype="http://schema.org/BlogPosting" data-animate-style="fadeinup" style="transform: translate3d(0px, 40px, 0px); transition-delay: 0.139468s; transition-duration: 1.5s;">
						<div class="post-card__thumbnail"><a href="/academy/2">
							<img width="335" height="220" src="https://academbet.ru/wp-content/uploads/2020/05/tochniy_schet-335x220.jpg" class="attachment-reboot_small size-reboot_small wp-post-image" alt="Стратегия &quot;Точный счет&quot;" itemprop="image" loading="lazy" decoding="async">
							<span class="post-card__category" itemprop="articleSection"><span itemprop="articleSection">Академия</span></span>
						</a>
						</div>
						<div class="post-card__title" itemprop="name">
							<span itemprop="headline"><a href="/academy/2">Что такое ставки на точный счет</a></span>
						</div>
						<div class="post-card__description" itemprop="articleBody">Ставки на точный счет – один из самых рискованных видов современного беттинга. Игроки часто пытаются поставить на высокий</div>
						<meta itemprop="author" content="admin">
						<meta itemscope="" itemprop="mainEntityOfPage" itemtype="https://schema.org/WebPage" itemid="https://academbet.ru/stavki-na-tochnyj-schet/" content="Что такое ставки на точный счет">
						<meta itemprop="dateModified" content="2021-03-07">
						<meta itemprop="datePublished" content="2020-06-24T17:28:35+03:00">
					</div>
					<div class="post-card post-card--vertical w-animate w-animate--started" itemscope="" itemtype="http://schema.org/BlogPosting" data-animate-style="fadeinup" style="transform: translate3d(0px, 40px, 0px); transition-delay: 0.0952137s; transition-duration: 1.5s;">
						<div class="post-card__thumbnail"><a href="/academy/3">
							<img width="335" height="220" src="https://academbet.ru/wp-content/uploads/2020/05/vilki-335x220.jpg" class="attachment-reboot_small size-reboot_small wp-post-image" alt="Букмекерские вилки" itemprop="image" loading="lazy" decoding="async">
							<span class="post-card__category" itemprop="articleSection"><span itemprop="articleSection">Академия</span></span>
						</a></div>
						<div class="post-card__title" itemprop="name">
							<span itemprop="headline"><a href="/academy/3">Что такое букмекерские вилки? Примеры арбитражных ситуаций</a></span>
						</div>
						<div class="post-card__description" itemprop="articleBody">Единственной игровой стратегией, которую по праву можно назвать беспроигрышной, является –&nbsp;букмекерские вилки.</div>
						<meta itemprop="author" content="admin">
						<meta itemscope="" itemprop="mainEntityOfPage" itemtype="https://schema.org/WebPage" itemid="https://academbet.ru/bukmekerskie-vilki/" content="Что такое букмекерские вилки? Примеры арбитражных ситуаций">
						<meta itemprop="dateModified" content="2021-03-07">
						<meta itemprop="datePublished" content="2020-05-13T21:08:51+03:00">
					</div>
					<div class="post-card post-card--vertical w-animate w-animate--started" itemscope="" itemtype="http://schema.org/BlogPosting" data-animate-style="fadeinup" style="transform: translate3d(0px, 40px, 0px); transition-delay: 0.106453s; transition-duration: 1.5s;">
						<div class="post-card__thumbnail"><a href="/academy/4">
							<img width="335" height="220" src="https://academbet.ru/wp-content/uploads/2020/05/academbet-1-335x220.png" class="attachment-reboot_small size-reboot_small wp-post-image" alt="" itemprop="image" loading="lazy" decoding="async">
							<span class="post-card__category" itemprop="articleSection"><span itemprop="articleSection">Академия</span></span>
						</a></div>
						<div class="post-card__title" itemprop="name">
							<span itemprop="headline"><a href="/academy/4">Что такое тотал в ставках и как правильно заключать пари на результативность</a></span>
						</div>
						<div class="post-card__description" itemprop="articleBody">Игра на результативность – это одно из самых популярных направлений в беттинге. В росписи каждого противостояния в букмекерской</div>
						<meta itemprop="author" content="admin">
						<meta itemscope="" itemprop="mainEntityOfPage" itemtype="https://schema.org/WebPage" itemid="https://academbet.ru/chto-takoe-total-v-stavkah/" content="Что такое тотал в ставках и как правильно заключать пари на результативность">
						<meta itemprop="dateModified" content="2021-03-07">
						<meta itemprop="datePublished" content="2020-05-13T20:11:39+03:00">
					</div>
					<div class="post-card post-card--vertical w-animate w-animate--started" itemscope="" itemtype="http://schema.org/BlogPosting" data-animate-style="fadeinup" style="transform: translate3d(0px, 40px, 0px); transition-delay: 0.12546s; transition-duration: 1.5s;">
						<div class="post-card__thumbnail"><a href="/academy/5">
							<img width="335" height="220" src="https://academbet.ru/wp-content/uploads/2020/05/short_expresss-335x220.jpg" class="attachment-reboot_small size-reboot_small wp-post-image" alt="Стратегия коротких экспрессов" itemprop="image" loading="lazy" decoding="async">
							<span class="post-card__category" itemprop="articleSection"><span itemprop="articleSection">Академия</span></span>
						</a></div>
						<div class="post-card__title" itemprop="name">
							<span itemprop="headline"><a href="/academy/5">Что такое экспрессы в ставках на спорт? Стратегии игры и суть экспрессов</a></span>
						</div>
						<div class="post-card__description" itemprop="articleBody">Экспресс – популярный тип ставки в современном беттинге. Это отличный способ увеличить коэффициент своего пари, чем успешно</div>
						<meta itemprop="author" content="admin">
						<meta itemscope="" itemprop="mainEntityOfPage" itemtype="https://schema.org/WebPage" itemid="https://academbet.ru/ekspressy-v-stavkah-na-sport/" content="Что такое экспрессы в ставках на спорт? Стратегии игры и суть экспрессов">
						<meta itemprop="dateModified" content="2021-03-07">
						<meta itemprop="datePublished" content="2020-05-13T16:04:24+03:00">
					</div>
					<div class="post-card post-card--vertical w-animate w-animate--started" itemscope="" itemtype="http://schema.org/BlogPosting" data-animate-style="fadeinup" style="transform: translate3d(0px, 40px, 0px); transition-delay: 0.0799335s; transition-duration: 1.5s;">
						<div class="post-card__thumbnail"><a href="/academy/6">
							<img width="335" height="220" src="https://academbet.ru/wp-content/uploads/2020/05/strategiya-stavok-1h2-335x220.jpg" class="attachment-reboot_small size-reboot_small wp-post-image" alt="Стратегия ставок на двойной шанс" itemprop="image" loading="lazy" decoding="async">
							<span class="post-card__category" itemprop="articleSection"><span itemprop="articleSection">Академия</span></span>
						</a></div>
						<div class="post-card__title" itemprop="name">
							<span itemprop="headline"><a href="/academy/6">Что такое 1х2 и ставки на двойной шанс?</a></span>
						</div>
						<div class="post-card__description" itemprop="articleBody">Нередко случается так, что ставить на чистую победу команды слишком рискованно, а на нулевую фору – невыгодно из-за вероятности</div>
						<meta itemprop="author" content="admin">
						<meta itemscope="" itemprop="mainEntityOfPage" itemtype="https://schema.org/WebPage" itemid="https://academbet.ru/dvojnoj-shans-1x2/" content="Что такое 1х2 и ставки на двойной шанс?">
						<meta itemprop="dateModified" content="2021-03-07">
						<meta itemprop="datePublished" content="2020-05-13T02:36:20+03:00">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		mounted() {
			document.title = 'Академия' + window.title;
		}
	}
</script>

<style scoped>

</style>